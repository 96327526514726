import React from "react";
import { Grid } from "@mui/material";
import Personal from "../../Personal";
import Origin from "../../Origin";

const CustomClientData = ({ ...order }) => {
  const {
    DatosCliente,
    Direccion,
    observaciones,
    responsable,
    franquicia,
    sucursal,
    origen,
  } = order;

  const Direction = `${Direccion?.calle + ", "}${Direccion?.numeroExt + ", "}
  ${Direccion?.cp + ", "}${
    typeof Direccion?.ciudad != "undefined" ? Direccion?.ciudad + "," : " "
  }
  ${Direccion?.delegacion + ", "}${Direccion?.estado}`;

  return (
    <>
      <Grid container style={design.Card}>
        <Grid container xs={12} md={8} xl={8} alignItems="center">
          <h3 style={design.title}>Nombre:</h3>
          <p style={design.form}>{DatosCliente?.nombre}</p>
        </Grid>
        {DatosCliente?.telefono && (
          <Grid container xs={12} md={4} xl={4} alignItems="center">
            <h3 style={design.title}>Teléfono:</h3>
            <p style={design.form}>{DatosCliente?.telefono}</p>
          </Grid>
        )}
        {DatosCliente?.correo && (
          <Grid xs={12} md={12} xl={12} style={design.Ajust}>
            <h3 style={design.title}>Correo electrónico:</h3>
            <p style={design.form}>{DatosCliente?.correo}</p>
          </Grid>
        )}
        <Grid xs={12} md={12} xl={12} style={design.Ajust}>
          <h3 style={design.title}>Dirección:</h3>
          <p style={design.form}>{Direction}</p>
        </Grid>
        {Direccion?.referencia && (
          <Grid xs={12} md={12} xl={12} style={design.Ajust}>
            <h3 style={design.title}>Referencias:</h3>
            <p style={design.form}>{Direccion?.referencia}</p>
          </Grid>
        )}
        {observaciones && (
          <Grid xs={12} md={12} xl={12} style={design.Ajust}>
            <h3 style={design.title}>Observaciones:</h3>
            <p style={design.form}>{observaciones}</p>
          </Grid>
        )}
        {responsable && (
          <>
            <Grid xs={12} md={4} xl={4} style={{ justifyContent: "left" }}>
              <Origin
                franquicia={franquicia}
                sucursal={sucursal}
                origen={origen}
              />
            </Grid>
            <Grid xs={12} md={4} xl={4} />
            <Grid xs={12} md={4} xl={4} style={{ justifyContent: "flex-end" }}>
              <Personal
                responsables={{
                  responsable: responsable || null,
                }}
                name={true}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
export default CustomClientData;

const design = {
  title: {
    color: "#00518c",
    fontFamily: "Lexend",
    fontWeight: 400,
    fontSize: 14,
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
    fontFamily: "Lexend",
  },
  form: {
    fontWeight: "100",
    fontSize: 14,
    marginLeft: "1%",
    textTransform: "lowercase",
  },
  Ajust: {
    display: "flex",
    flexDirection: "row",
    marginTop: -15,
  },
  textFech: {
    color: "#ababab",
    textAlign: "right",
    paddingRight: 30,
  },
};
