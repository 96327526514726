import React, { useState } from "react";
import { Grid, TableCell, TableRow, Tooltip } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";
import Modal from "../../Modal";

const Item = ({
  id,
  quantity,
  descuento,
  label,
  unitprice,
  franquicia,
  sucursal,
  subtotal,
  handleChange,
  botomDisable,
}) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <TableRow key={id}>
        <TableCell style={design.row}>
          {/*  name studie & unitprice */}
          <Grid container direction="row">
            {info ? (
              <Grid item xs={2} md={2} xl={2}>
                <Info
                  style={design.IconInfo}
                  fontSize="small"
                  onClick={handleClick}
                />
              </Grid>
            ) : null}
            <Grid item xs={10} md={10} xl={10}>
              <Tooltip arrow title={label}>
                <p style={design.label}>
                  {label} <br />
                  <p style={design.unitprice}>
                    {unitprice.toLocaleString("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      currencyDisplay: "symbol",
                    })}
                  </p>
                </p>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={design.row}>
          <Grid
            container
            direction={botomDisable === true ? "column" : "row"}
            justifyContent="space-around"
            alignItems="center"
          >
            {!botomDisable && (
              <Remove
                fontSize="small"
                style={{ color: "#00518c", fontSize: 14 }}
                onClick={() => handleChange(-1)}
              />
            )}
            <p style={{ color: "#00518c", fontSize: 12 }}>{quantity}</p>
            {!botomDisable && (
              <Add
                fontSize="small"
                style={{ color: "#00518c", fontSize: 14 }}
                onClick={() => handleChange(+1)}
              />
            )}
          </Grid>
        </TableCell>
        <TableCell align="center" style={design.row}>
          {descuento}%
        </TableCell>
        <TableCell align="center" style={design.sub}>
          {subtotal.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            currencyDisplay: "symbol",
          })}
        </TableCell>
      </TableRow>
      {/* Modal con detalles */}
      <Modal.Details
        open={open}
        handleClose={handleClick}
        id={id}
        setInfo={setInfo}
        franquicia={franquicia}
        sucursal={sucursal}
      />
    </>
  );
};
export default Item;

const design = {
  label: {
    color: "rgba(0, 81, 140, 1)",
    marginLeft: 5,
    // margiRight: 15,
    // lineHeight: "90%",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // maxWidth: "85%",
  },
  unitprice: {
    textAlign: "left",
    fontWeight: "400",
    color: "#4496D2",
    fontSize: 10,
  },
  IconInfo: { color: "rgba(0, 81, 140, 1)", marginTop: 7 },
  row: {
    borderRight: "2px dashed rgba(0, 81, 140, 0.4)",
    fontFamily: "Lexend",
    color: "#093046",
    borderBottom: "none",
    fontSize: 12,
  },
  sub: {
    border: "none",
    fontFamily: "Lexend",
    color: "#093046",
    fontSize: 12,
  },
};
