import firebase from "../firebase";

/**
 * Función para obtener información de usuario a partir de su ID.
 * @param {string} uid - El ID del usuario en Firebase.
 * @param {string} franquicia - El nombre de la franquicia a la que pertenece el usuario.
 * @returns {Promise<Object>} Una promesa que resuelve la referencia del usuario.
 * @throws {Error} Cuando no se proporciona un UID válido o no se puede acceder al usuario.
 */
const get_user = async (uid = null, franquicia) => {

  if (!uid) {
    throw new Error("a valid UID is required to access.");
  }

  const userReference = firebase.db.collection("Franquicias").doc(franquicia).collection("Personal").doc(uid)

  const response = await userReference.get();

  if (!response.exists) {
    throw new Error("No fue posible iniciar sesión.");
  }

  return  response.ref ;
};
export default get_user;
