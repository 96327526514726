/**
 * Hook personalizado para obtener la dimensión especificada del navegador (altura o ancho) y mantenerla actualizada.
 * @module useDimensions
 * @param {string} dimensionType - Tipo de dimensión a obtener ("height" para altura, "width" para ancho).
 * @returns {number} Dimensión especificada del navegador (altura o ancho).
 */
import { useState, useEffect } from "react";

export const useDimensions = (dimensionType) => {
  // Estado local para almacenar la dimensión especificada del navegador
  const [dimension, setDimension] = useState(
    dimensionType === "height" ? window.innerHeight : window.innerWidth
  );

  // Efecto secundario para suscribirse al evento de cambio de tamaño de la ventana y actualizar la dimensión correspondiente
  useEffect(() => {
    const updateWindowDimension = () => {
      setDimension(
        dimensionType === "height" ? window.innerHeight : window.innerWidth
      );
    };

    // Agregar un listener de evento para el cambio de tamaño de la ventana
    window.addEventListener("resize", updateWindowDimension);

    // Retornar una función de limpieza para eliminar el listener de evento al desmontar el componente
    return () => {
      window.removeEventListener("resize", updateWindowDimension);
    };
  }, [dimensionType]); // Se ejecuta el efecto secundario cada vez que cambia la dimensionType

  // Retornar la dimensión especificada del navegador
  return dimension;
};
