import { Button, styled } from "@mui/material";

/**
 * DarkBlueButton component is a styled Material-UI Button with custom styles.
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const DarkBlueButton = styled(Button)(({ width,height })=> ({
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 25px",
    gap: "10px",
    width: width || "60%",
    fontFamily:"Lexend",
    textTransform:"none",
    fontWeight:500,
    height: height ? height : "66px",
    margin: "2%",
    fontSize:19,
    border: "5px solid #00518c",
    backgroundColor: "#00518c",
    color: "#fff",
    borderRadius: "32px",
    boxShadow: "10px 10px 37px 0px  rgba(0, 111, 191, 0.25)",
    "&:hover": {
      border: "5px solid #00518c",
      backgroundColor: "#00518c",
      color: "#fff",
      fontFamily:"Lexend",
      textTransform:"none",
      fontWeight:500,
    },
  }));