/* eslint-disable import/no-anonymous-default-export */
/**
 * Convierte un objeto tipo 'Date' a una cadena de texto con el formato 'dd/mm/yyyy'.
 * @param {Date} currentDate La fecha a ser convertida.
 * @returns {string|null} La fecha en formato 'dd/mm/yyyy' o null si currentDate es null.
 */
export default (currentDate = null) => {
  // Si no se proporciona una fecha, retorna null
  if (!currentDate) return null;

  let fecha;

  try {
    // Intenta convertir currentDate a un objeto tipo 'Date'
    fecha = currentDate.toDate();
  } catch (error) {
    // Si hay un error, asume que currentDate ya es un objeto 'Date'
    fecha = currentDate;
    console.log("error in timeStamp_toString",error);
  }

  // Extrae el día, mes y año de la fecha
  const f = fecha;
  const day = f.getDate();
  const month = f.getMonth() + 1;
  const fullyear = f.getFullYear();

  // Formatea la fecha en 'dd/mm/yyyy' y retorna
  return `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${fullyear}`;
};
