/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Avatar,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomAutocomplete from "../CustomAutocomplete";
import { CssTextField } from "../../CSS/CssTextField";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import imagesArticles from "../../Utils/imagesArticles";
import { validateToken } from "../../Apis/validateToken";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import {
  createItem,
  verifyCode,
  verifyFields,
} from "./Controllers/createItem.controller";
import {
  getDepartaments,
  getSubDepartaments,
} from "./Controllers/getDepartments.controller";

const Study = () => {
  const { user, dataUserAccess } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [branch, setBranch] = useState();
  const [store, setStore] = useState();
  const [departament, setDepartament] = useState([]);
  const [subdepartament, setSubdepartament] = useState([]);

  const getData = async () => {
    const response = await validateToken("", user.uid);
    setBranch(response.branch);
    setStore(response.store);
    // console.log(response)
    if (response) {
      getDepartaments(store, branch).then(setDepartament);
      getSubDepartaments(store, branch).then(setSubdepartament);
    }
  };

  useEffect(() => {
    getData();
  }, [user, dataUserAccess, store, branch]);

  const [spinner, setSpinner] = useState(false);
  const [form, setForm] = useState({});
  const images = imagesArticles.getImagesArticles();

  const handleSelect = (selectedImage) => {
    if (selectedImage && selectedImage.img) {
      setForm({
        ...form,
        image: selectedImage,
      });
    }
  };

  const handleCreate = async () => {
    const path = `Franquicias/${store}/Sucursales/${branch}/Products`;

    try {
      if (form.code) {
        // Verificar el código preexistente en algun articulo
        await verifyCode(form.code, path);
      }
      // Verificar los campos del formulario
      await verifyFields(form);

      // Si todos los campos son válidos, continuar con la creación del artículo
      setSpinner(true);
      await createItem(form, path, user, store);
      enqueueSnackbar("Artículo creado correctamente", {
        variant: "success",
      });
      navigate("/view-catalog");
    } catch (error) {
      // Si se produce algún error en la validación o creación del artículo,
      console.error("Error al crear el artículo:", error);
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      // Finalizar el spinner independientemente de si la creación del artículo fue exitosa o no
      setSpinner(false);
    }
  };

  if (dataUserAccess === "VENTAS_ROL") {
    return (
      <>
        <Alert severity="error" variant="outlined" style={design.alert}>
          Alto ahí! No tienes los accesos necesarios, intenta contactarte con tu
          encargado de sucursal o con el personal de Movicare
        </Alert>
      </>
    );
  }

  return (
    <>
      <Grid container>
        <Grid xs={12} md={4} xl={4} lg={4}>
          <CustomAutocomplete
            onChange={(value) => setForm({ ...form, departament: value })}
            label="Departamento"
            required={true}
            onClick={getData}
            getOptionLabel={(option) => option}
            options={departament}
            grid={12}
          />
        </Grid>
        <Grid xs={12} md={4} xl={4} lg={4}>
          <CustomAutocomplete
            onChange={(value) => setForm({ ...form, subdepartament: value })}
            label="Subdepartamento"
            required={true}
            onClick={getData}
            getOptionLabel={(option) => option}
            options={subdepartament}
            grid={12}
          />
          <Grid xs={12} md={12} xl={12} lg={12} style={design.align}>
            <Box sx={design.Box}>
              <p style={design.text}>
                En caso de no conocer el subdepartamento, seleccionar varios.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} md={4} xl={4} lg={4}>
          <CssTextField
            id="clave"
            label="Clave"
            required={true}
            variant="outlined"
            value={form?.code}
            onChange={(e) => setForm({ ...form, code: e.target.value })}
            onKeyUp={(e) =>
              setForm({ ...form, code: e.target.value.toUpperCase() })
            }
            helperText={
              form?.code?.length === 0
                ? null
                : form?.code?.length < 4 && "Mínimo 3 carácteres"
            }
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="nombre"
            label="Nombre del estudio"
            required={true}
            variant="outlined"
            value={form.name}
            onChange={(name) => setForm({ ...form, name: name.target.value })}
            onKeyUp={(e) =>
              setForm({ ...form, name: e.target.value.toUpperCase() })
            }
            helperText={
              form?.name?.length === 0
                ? null
                : form?.name?.length < 4 && "Mínimo 3 carácteres"
            }
          />
        </Grid>
        <Grid container xs={12} md={6} xl={6} lg={6}>
          <Grid xs={2} md={2} xl={2} style={design.alinear}>
            <Avatar src={form?.image?.img} />
          </Grid>
          <CustomAutocomplete
            value={form.name}
            onChange={handleSelect}
            label="Imagen"
            required={true}
            getOptionLabel={(option) => option.name}
            options={images}
            grid={10}
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="costo"
            label="Costo $"
            required={true}
            variant="outlined"
            type="number"
            min="1"
            value={form.cost}
            onChange={(cost) => {
              if (cost.target.value >= 0) {
                setForm({ ...form, cost: cost.target.valueAsNumber });
              }
            }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">.00</InputAdornment>,
            }}
            helperText="El costo siempre debe ser menor al precio del público."
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="precio"
            label="Precio al publico $"
            required={true}
            variant="outlined"
            type="number"
            min="1"
            value={form.price}
            onChange={(price) => {
              if (price.target.value >= 0) {
                setForm({ ...form, price: price.target.valueAsNumber });
              }
            }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">.00</InputAdornment>,
            }}
            helperText={
              form?.price?.length === 0
                ? null
                : +form.cost > +form.price &&
                  "El precio debe ser mayor al costo"
            }
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="tiempo"
            label="Tiempo de entrega"
            required={true}
            variant="outlined"
            value={form.deliveryTime}
            onChange={(name) =>
              setForm({ ...form, deliveryTime: name.target.value })
            }
            onKeyUp={(e) => setForm({ ...form, deliveryTime: e.target.value })}
            helperText="Ingresar tiempo en días"
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="descripcion"
            label="Descripción"
            required={true}
            variant="outlined"
            value={form.description}
            onChange={(name) =>
              setForm({ ...form, description: name.target.value })
            }
            onKeyUp={(e) => setForm({ ...form, description: e.target.value })}
            helperText={
              form?.description?.length === 0
                ? null
                : form?.description?.length < 4 && "Mínimo 3 carácteres"
            }
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="requisitos"
            label="Requisitos"
            multiline={5}
            maxRows={4}
            required={true}
            variant="outlined"
            helperText={
              form?.req?.length === 0
                ? null
                : form?.req?.length < 11 &&
                  "Mínimo 10 carácteres.Si requieres dar saltos de línea coloca +"
            }
            value={form.req}
            onChange={(req) => setForm({ ...form, req: req.target.value })}
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="recom"
            label="Recomendaciones"
            multiline={5}
            maxRows={4}
            required={false}
            variant="outlined"
            helperText={
              form?.recomd?.length === 0
                ? null
                : form?.recomd?.length < 11 &&
                  "Mínimo 10 carácteres. Si requieres dar saltos de línea coloca //"
            }
            value={form.recomd}
            onChange={(recomd) =>
              setForm({ ...form, recomd: recomd.target.value })
            }
          />
        </Grid>
        <Grid xs={12} md={12} xl={12} lg={12} style={design.align}>
          {spinner ? (
            <CircularProgress style={{ marginTop: 30 }} />
          ) : (
            <DarkGreenButton
              width={"70%"}
              height={"70px"}
              onClick={handleCreate}
            >
              Crear estudio
            </DarkGreenButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default Study;
const design = {
  Box: {
    backgroundColor: "#BDE0FE",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    width: "96%",
    marginTop: -1.5,
    display: "flex",
  },
  align: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: "#093046",
    fontFamily: "Lexend",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 550,
    paddingLeft: 15,
    alignSelf: "flex-end",
  },
  Alert: {
    backgroundColor: "#F08050",
    width: "95%",
    height: 50,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 1,
    marginLeft: 1,
  },
  info: { color: "#FFF", marginLeft: 10 },
  txtAlert: {
    color: "#FFF",
    alignSelf: "center",
    fontWeight: 400,
    marginLeft: "3%",
  },
  alinear: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  alert: {
    width: "91%",
    fontSize: 12,
    marginTop: "2%",
    marginBottom: "2%",
    marginLeft: "5%",
    borderRadius: 15,
  },
};
