/* eslint-disable no-unused-vars */
/**
 * Módulo que muestra los componentes base
 * @module ComponentsStyle
 */

import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import CardInfoPed from "../Components/CardInfoPed";
import { DarkGreenButton } from "../CSS/Contained/DarkGreenButton";
import { DarkBlueButton } from "../CSS/Contained/DarkBlueButton";
import { ModerateBlueButton } from "../CSS/Contained/ModerateBlueButton";
import { SoftOrangeButton } from "../CSS/Contained/SoftOrangeButton";
import { DarkRedButton } from "../CSS/Contained/DarkRedButton";
import { SoftBlueButton } from "../CSS/Contained/SoftBlueButton";

import { BlueButton } from "../CSS/Outlined/BlueButton";
import { CianButton } from "../CSS/Outlined/CianButton";
import { GreenButton } from "../CSS/Outlined/GreenButton";
import { OrangeButton } from "../CSS/Outlined/OrangeButton";
import { PurpleButton } from "../CSS/Outlined/PurpleButton";
import { RedButton } from "../CSS/Outlined/RedButton";
import CustomTextField from "../Components/CustomTextField";
import CustomAutocomplete from "../Components/CustomAutocomplete";
import StripedGrid from "../Components/StripeDataGrid";

/**
 * Componente funcional que muestra componentes base de la aplicación.
 * @function
 * @returns {JSX.Element} El componente ComponentsStyle.
 */
const ComponentsStyle = () => {
  //-------------------Tamaño de imagenes----------------------
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };

  const [widthIMG, setWidthIMG] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWidthIMG(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [form, setForm] = useState("");
  const [data, setData] = useState(pedidos);

  return (
    <>
      {/* Stack para mostrar tarjetas de pedidos */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <h1 style={design.title}>Bienvenido</h1>
        <Box
          width={widthIMG.width < 1000 ? "10%" : "15%"}
          height={widthIMG.width < 1000 ? "20%" : "15%"}
          component="img"
          // 300 x 300
          src="https://movicaremx.com/IMG/MoviLabs/movilabs_300.png"
          alt={"Franquicia_name"}
        />
      </Stack>
      <h2 style={design.subtitle}>Nombre del operador</h2>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid container spacing={2} style={design.cards}>
          {pedidos.map((doc, idx) => (
            <Grid
              item
              spacing={2}
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              key={idx}
            >
              <CardInfoPed loading={true} data={doc} index={idx} />
            </Grid>
          ))}
        </Grid>
      </Stack>
      {/* Stack para mostrar campos de texto y Autocomplete personalizado */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <CustomTextField
          label="Correo electrónico"
          type="email"
          value={form.email}
          width="96%"
          grid={6}
          required={false}
          name="email"
          // onChange={handleChangeForm}
          // style={{
          //   width: width ? width : "98%",
          // }}
          id="label"
          // type={type}
          variant="outlined"
          multiline="bool"
          rows="2"
          // InputProps={InputProps}
        />
        <CustomAutocomplete
          label="Custom Autocomplete"
          options={movies}
          grid={12}
          // onTextChange={}
          // onChange={}
          // getOptionLabel={}
          // renderOption={}
          width="98%"
          required={true}
        />
      </Stack>
      {/* Stack para mostrar botones con diferentes estilos */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <DarkGreenButton>Crear orden</DarkGreenButton>
        <ModerateBlueButton>Zonas de servicio</ModerateBlueButton>
        <SoftOrangeButton>PBE ORANGE</SoftOrangeButton>
        <DarkRedButton>PBE RedButton</DarkRedButton>
        <SoftBlueButton>PBE PurpleButton</SoftBlueButton>
        <DarkBlueButton>Visualizar ordenes</DarkBlueButton>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <GreenButton>GreenButton</GreenButton>
        <CianButton>CianButton</CianButton>
        <OrangeButton>OrangeButton</OrangeButton>
        <RedButton>RedButton</RedButton>
        <PurpleButton>PurpleButton</PurpleButton>
        <BlueButton>BlueButton</BlueButton>
      </Stack>
       {/* Componente StripedGrid para mostrar datos en una tabla */}
      <StripedGrid
        loading={false}
        columns={columns}
        rows={data.map((row) => ({ ...row, id: row.np }))}
        pageSize={25}
        oddBackgroundColor="#E6E6E640"
        evenBackgroundColor="#CCCCCC66"
        oddOpacity={0.7}
      />
    </>
  );
};

export default ComponentsStyle;

const design = {
  centrar: {
    marginLeft: "40%",
    marginTop: "-4%",
  },
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
    marginTop: "-5%",
    // marginLeft: "49.8%",
    marginRight: "5%",
  },
  cards: {
    marginLeft: "10%",
    marginRight: "10%",
    maxWidth: "80%",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
  },
  columnList: {
    whiteSpace: "pre-wrap",
    lineHeight: "1.5%",
    margin: 10,
  },
};

const pedidos = [
  {
    np: "12345",
    DatosCliente: {
      nombre: "Juan Leonardo Rafael Martinez Pérez",
      telefono: "555-123-4567",
    },
    Direccion: {
      ciudad: "Bénito Juárez",
      delegacion: "Bénito Juárez",
      estado: "Ciudad de México",
    },
    status: {
      laboratorista: 0,
    },
    services_Dates: {
      laboratorista: new Date(),
    },
    metodoDePago: "Tarjeta de crédito",
    total: "$100.00",
    laboratorista: "Personal/mxf7UN5D8NXxePbIPSbUPRk8Pdv1",
  },
];

const movies = [
  { label: "The Shawshank Redemption" },
  { label: "The Godfather" },
  { label: "The Godfather: Part II" },
  { label: "The Dark Knight" },
  { label: "12 Angry Men" },
  { label: "Schindler's List" },
  { label: "Pulp Fiction" },
];

const columns = [
  {
    field: "np",
    headerName: "Folio",
    width: 180,
    renderHeader: () => <p style={design.headerTable}>Folio</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      const { np = 0 } = row;
      return "PED-" + np;
    },
  },
  {
    field: "DatosCliente.nombre",
    headerName: "Nombre",
    width: 250,
    renderHeader: () => <p style={design.headerTable}>Nombre</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      // console.log(params);
      const { nombre = "" } = row.DatosCliente;
      return nombre;
    },
  },
  {
    field: "DatosCliente.telefono",
    headerName: "Teléfono",
    width: 140,
    renderHeader: () => <p style={design.headerTable}>Teléfono</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      const { telefono } = row.DatosCliente;
      return telefono;
    },
  },
  {
    field: "direccion",
    headerName: "Dirección de servicio",
    width: 250,
    headerAlign: "center",
    renderHeader: () => <p style={design.headerTable}>Dirección de servicio</p>,
    valueFormatter: ({ row }) => {
      const {
        Direccion: { ciudad, delegacion, estado },
      } = row;
      return `${ciudad},${delegacion},${estado}`;
    },
  },
];
