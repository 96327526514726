/* eslint-disable no-useless-escape */
/**
 * Valida los datos de un nuevo usuario antes de su registro.
 * @param {Object} usuario El objeto que contiene los datos del usuario a validar.
 * @param {string} usuario.email El correo electrónico del usuario.
 * @param {string} usuario.name El nombre del usuario.
 * @param {string} usuario.last_name El apellido del usuario.
 * @param {string} usuario.phone El número de teléfono del usuario.
 * @param {number} usuario.id_branch El identificador de la sucursal del usuario.
 * @param {string} usuario.rol El rol del usuario.
 * @param {string} birthday La fecha de nacimiento del usuario.
 * @throws {Error} Se lanzará un error si no se proporciona un correo electrónico.
 * @throws {Error} Se lanzará un error si el correo electrónico no tiene un formato válido.
 * @throws {Error} Se lanzará un error si no se proporciona un nombre.
 * @throws {Error} Se lanzará un error si no se proporciona un apellido.
 * @throws {Error} Se lanzará un error si no se proporciona un número de teléfono.
 * @throws {Error} Se lanzará un error si el número de teléfono no tiene 10 dígitos.
 * @throws {Error} Se lanzará un error si no se selecciona una fecha de nacimiento.
 * @throws {Error} Se lanzará un error si no se selecciona una sucursal.
 * @throws {Error} Se lanzará un error si no se asigna un rol.
 */
export const validateNewUser = (usuario, birthday) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (!usuario?.email) {
    throw new Error("No olvides que el correo es obligatorio");
  }
  if (!usuario.email.match(regex)) {
    throw new Error("Verifica el correo electrónico, no contiene un formato correcto");
  }
  if (!usuario?.name) {
    throw new Error("No se ha ingresado un nombre");
  }
  if (!usuario?.last_name) {
    throw new Error("No se ha ingresado un apellido");
  }
  if (!usuario?.phone) {
    throw new Error("No se ha ingresado un número de teléfono");
  }
  if (usuario?.phone.length !== 10) {
    throw new Error("Debes ingresar 10 dígitos en el campo teléfono");
  }
  if (!birthday) {
    throw new Error("Selecciona la fecha de nacimiento!");
  }
  if (!usuario?.id_branch) {
    throw new Error("Selecciona la sucursal de alta");
  }
  if (!usuario?.rol) {
    throw new Error("No se han asignado las autorizaciones correctas");
  }
};
