import firebase from "../firebase";
import axios from "axios";

/**
 * Actualiza el historial de inicio de sesión del usuario en la base de datos.
 * @param {string} uid - El ID del usuario.
 * @param {string} franquicia - El nombre de la franquicia.
 * @throws {Error} Si hay un error al actualizar el historial de inicio de sesión.
 */
export const updateHistoryLogin = async ( uid, franquicia ) => {
  // console.log('uid - updateHistoryLogin', uid);
  // console.log('franquicia -updateHistoryLogin', franquicia);
  try {
    const franquiciaRef = firebase.db.collection("Franquicias").doc(franquicia);

    const userReference = franquiciaRef.collection("Personal").doc(uid);

    const horaInicio = new Date();
    const ip = await getIp();
    const device = get_Device();
    const browser = detectBrowser();
    const new_Login = {
      horaInicio,
      ip,
      dispositivo: device || null,
      browser,
      versionAplicacion: process.env.REACT_APP_VERSION,
    };

    localStorage.setItem("@lastSignIn", `${horaInicio.getTime()}`);
    // add
    await userReference.update({ horaInicio: new_Login });
    return;
  } catch (error) {
    console.log("Error in updateHistoryLogin",error);
    throw new Error("Update history log error");
  }
};

/**
 * Detecta el navegador web utilizado por el usuario.
 * @returns {string} El nombre del navegador.
 */
export const detectBrowser = () => {
  var sBrowser,
    sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser;
  // alert("You are using: " + sBrowser);
};

/**
 * Obtiene la dirección IP del cliente.
 * @returns {Promise<string|null>} La dirección IP del cliente.
 */
const getIp = async () => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");

    // console.log(res.data);

    return res.data.IPv4;
  } catch (error) {
    console.log("Error in getIP updateHistoryLogin",error);
    return null;
  }
};
/**
 * Obtiene el tipo de dispositivo utilizado por el usuario.
 * @returns {string} El tipo de dispositivo ('Mobile' o 'Desktop').
 */
const get_Device = () => {
  const width = window.innerWidth;

  return width <= 768 ? "Mobile" : "Desktop";
};
