export const timeStamp_toStringWithoutHours = (date = null) => {
    if (!date) return null;
  
    let fecha;
  
    try {
      fecha = date.toDate();
    } catch (error) {
      fecha = date;
    }
    try {
      const dia = fecha.getDate();
      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();
  
      return `${dia < 10 ? "0" + dia : dia}/${
        month < 10 ? "0" + month : month
      }/${year} `;
    } catch (error) {
      return "_n";
    }
  };