// Valores constantes predefinidos que se utilizan como identificadores para las acciones.
/**
 * Identificador de acción para el inicio de sesión.
 * @type {string}
 * @constant
 */
export const LOGIN = "LOGIN";
/**
 * Identificador de acción para el token de autenticación.
 * @type {string}
 * @constant
 */
export const AUTH_TOKEN = "AUTH_TOKEN";