import { servidor } from "../servidor";

/**
 * Envía un correo electrónico asociado a una orden.
 *
 * @param {string} id - ID de la orden.
 * @param {string} type - Tipo de orden (MoviLabs | MoviCare).
 * @returns {boolean} - Devuelve true si el correo electrónico se envió correctamente.
 * @throws {Error} - Se lanza un error si no se proporciona el ID de la orden o si hay un error en la solicitud.
 */
export const sendEmailOrder = async (id = "", type = "MoviLabs") => {
  // console.log("sedEmailOrder - type",type);
  // console.log("sedEmailOrder - id",id);
  try {
    // Verifica si se proporciona un ID de orden
    if (!id) {
      throw new Error("No se puede enviar el correo electrónico. Falta el ID de la orden.");
    }

    // Realiza la solicitud para enviar el correo electrónico
    const response = await servidor.post("/api/pedidos/nuevoPedido", {
      id,
      type,
    });
    // console.log("sedEmailOrder - response.data",response.data);

    return response.data; // Devuelve true si la solicitud fue exitosa
  } catch (error) {
    // Mejora: Proporciona mensajes de error más descriptivos
    if (error.response) {
      // La solicitud se hizo, pero el servidor respondió con un código de error
      throw new Error(`Error en la solicitud: ${error.response.status} - ${error.response.data.message}`);
    } else if (error.request) {
      // La solicitud se hizo, pero no se recibió respuesta del servidor
      throw new Error("No se recibió respuesta del servidor. Verifica tu conexión a Internet y vuelve a intentarlo.");
    } else {
      // Otros errores (por ejemplo, error de JavaScript)
      throw new Error(`Error desconocido: ${error.message}`);
    }
  }
};
