/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Edit } from "@mui/icons-material";
import { Grid } from "@mui/material";
import StepperOrder from "../../Components/Ordenes/V2/StepperOrder";
import CustomData from "../../Components/Ordenes/V2/CustomData";
import CustomAddress from "../../Components/Ordenes/V2/CustomAddress";
import CustomStudies from "../../Components/Ordenes/V2/CustomStudies";
import CustomPayment from "../../Components/Ordenes/V2/CustomPayment";
import CustomServiceHours from "../../Components/Ordenes/V2/CustomServiceHours";
import Modal from "../../Components/Modal";
import { useOrder } from "../../Context/Ordenes_v2/Order.context";
import { useDimensions } from "../../hooks/useDimensions";
import TicketOrden from "../../Components/Ordenes/V2/TicketOrden";

const CreateOrder = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const [typeCreate, setTypeCreate] = useState(false);
  const { branch, selectBranch, setActivity, activity } = useOrder();

  const [openModal, setOpenModal] = useState(true);

  const [touch, setTouch] = useState(`SECTION-0`);
  const scrollToSection = (idx) => {
    const section = document.getElementById(`SECTION-${idx}`);
    setTouch(idx);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  //
  const style = {
    height: `${dimensionHeight}` - 190,
    overflow: "auto",
    paddingLeft: 15
  };

  const handleClick = () => {
    if (branch) handleOpenModel();
  };

  const widthTablet = dimensionWidth > 768 && dimensionWidth < 1023;
  const widthTelefono = dimensionWidth > 320 && dimensionWidth < 767;
  const Ticket = widthTablet > widthTelefono;
  //
  // Modal abrir y cerrar
  const handleOpenModel = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container backgroundColor="#D9EDFF" className="C4">
        <Grid item xs={12} md={6} xl={7} lg={7} style={design.ColorFondo}>
          <p style={design.title}>
            Crea una {!typeCreate ? "toma a domicilio" : "recoleccion"}
          </p>{" "}
          <p style={design.textCreate}>
            {branch?.name || null}{" "}
            <Edit onClick={handleClick} style={design.Edit} />
          </p>
          <Grid container>
            {dimensionWidth > 1200 && (
              <Grid item xs={0} md={2} xl={2}>
                <StepperOrder
                  activity={activity}
                  onClick={scrollToSection}
                  windowHeight={dimensionHeight}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} style={style}>
              <CustomData setActivity={setActivity} />
              <CustomAddress widthTelefono={widthTelefono} />
              <CustomStudies widthTelefono={widthTelefono} />
              <CustomPayment />
              <CustomServiceHours />
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={Ticket ? 5 : 12} sm={12} md={6} lg={5} xl={5}>
          <TicketOrden
            windowHeight={dimensionHeight}
            widthTelefono={widthTelefono}
          />
        </Grid>
      </Grid>
      <Modal.Start
        open={openModal}
        handleClose={handleCloseModal}
        typeCreate={typeCreate}
        setTypeCreate={setTypeCreate}
        onSelect={(value) => {
          selectBranch(value);
          handleCloseModal();
        }}
      />
    </>
  );
};

export default CreateOrder;

const design = {
  title: {
    textAlign: "center",
    fontWeight: "500",
    color: "#00518c",
    fontSize: 27,
    marginBottom: 2,
  },
  textEdit: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  textCreate: {
    fontSize: 17,
    textAlign: "center",
    marginRight: "2%",
    marginTop: "-0.5%",
    fontWeight: "600",
    color: "#4496D2",
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  Edit: {
    fontSize: 17,
    color: "#4496D2",
    marginTop: 5,
  },
  ColorFondo: {
    padding: "0px 5px",
    backgroundColor: "#FFF",
    borderTopRightRadius: 40,
  },
};
