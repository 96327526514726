import { db } from "../../../firebase/firebasev2";
import { doc, updateDoc, getDoc, setDoc } from "firebase/firestore";

export const updateItem = async (item, path = "", store,user) => {
  try {
    if (!path) throw Error("Se requiere un path de Franquicia");

    // Realizar la actualización del artículo en la colección principal
    await updateDoc(doc(db, path), item);

    await updateItemWithHistory(item, path, store, user);
    return;
  } catch (error) {
    console.error("Error al actualizar el artículo:", error);
    throw error;
  }
};

const updateItemWithHistory = async (item, path = "", store, user) => {
  try {
    if (!path) throw Error("Se requiere un path de Franquicia");

    // Agregar el responsable de la modificación al artículo
    const pathResponsable = `Franquicias/${store}/Personal/${user}`;
   
    const responsable = doc(db, pathResponsable);

    // Realizar la actualización del artículo en la colección principal
    await updateDoc(doc(db, path), item);

    // Construir la ruta de la subcolección History
    const historyPath = `${path}/History/Movements`;

    // Obtener la referencia al documento Movements dentro de la subcolección History
    const ref = doc(db, historyPath);

    // Obtener el snapshot del documento Movements
    const response = await getDoc(ref);
    const { Movimientos = [] } = response.data();

    //Ahora, agregar un registro en la subcolección History
    const newMovimiento = {
      description: "Modificación",
      fecha: new Date(),
      responsable:responsable ,
      detailsModify: item, // Preservar los datos del artículo antes del cambio
    };

    Movimientos.push(newMovimiento);
    // Verificar si el documento existe
    if (!response.exists()) {
      // Si el documento Movements no existe, crearlo y agregar el registro
      await setDoc(ref, {
        description: "Modificación",
        fechaAlta: new Date(),
        responsable: responsable,
      });
    } else {
      // Si el documento Movements ya existe, actualizar el arreglo de Movimientos
      await updateDoc(ref, { Movimientos });
    }
    window.location.reload();
    return;
  } catch (error) {
    console.error("Error al actualizar el historico de movimientos:", error);
    throw error;
  }
};
