import { Button, styled } from "@mui/material";
/**
 * PurpleButton component is a styled Material-UI Button with custom styles.
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const PurpleButton = styled(Button)(({ width, height })=> ({
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "15px 25px",
    width: width || "60%",
    fontFamily:"Lexend",
    textTransform:"none",
    fontWeight:500,
    height: height ? height : "66px",
    margin: "2%",
    fontSize:19,
    border: " 5px solid #90A4F3",
    color: "#90A4F3",
    borderRadius: "32px",
    textOverflow: "clip",
    whiteSpace: "normal",
    overflow: "hidden",
    lineHeight: 1,
    // boxShadow: "10px 10px 37px 0px rgba(0, 111, 191, 0.25)",
  }));