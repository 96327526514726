/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Female,
  Male,
  Celebration,
  WhatsApp,
  AssignmentInd,
  AttachEmail,
  Info,
} from "@mui/icons-material";
import ResultsTable from "./ResultsTable";
import TableIntakeMedicines from "./TableIntakeMedicines";
import { getDoc } from "firebase/firestore";
import { timeStamp_toStringWithoutHours } from "../../../../Utils/timeStamp_toStringWithoutHours";
import { dateInstant } from "../../../../Utils/dateInstant";

const TicketsPersonal = ({
  nombre,
  fechaNacimiento,
  edad,
  sexo,
  pasaporte,
  telefono,
  correo,
  Estudios,
  observaciones,
  ingesta,
  Motivo,
  responsable,
  fechaAlta,
}) => {
  const [responsableInfo, setResponsable] = useState();
  useEffect(() => {
    const getResponsable = async () => {
      const respGet = await getDoc(responsable);
      const respData = respGet.data();
      const name = respData.DatosPersonales?.nombre;
      const lastName = respData.DatosPersonales?.apellidos;

      if (typeof lastName === "undefined") {
        setResponsable(name);
        return;
      }
      setResponsable(name + " " + lastName);
    };
    getResponsable();
  }, []);

  return (
    <Accordion style={design.Card}>
      {/* Details initial bolet x px */}
      <AccordionSummary expandIcon={<Info />}>
        <Grid container style={design.aling}>
          <Tooltip title={sexo}>
            {sexo === "Femenino" ? (
              <Female style={design.Female} />
            ) : (
              <Male style={design.Male} />
            )}
          </Tooltip>
          <p style={design.name}>{nombre}</p>
          <Tooltip title="Fecha de nacimiento">
            <Celebration style={design.Cake} />
          </Tooltip>
          <p style={design.text}>
            {timeStamp_toStringWithoutHours(fechaNacimiento)} - {edad} años
          </p>
          {pasaporte && (
            <>
              <Tooltip title="Pasaporte">
                <AssignmentInd style={design.Assign} />
              </Tooltip>
              <p style={design.text}>{pasaporte}</p>
            </>
          )}
          {telefono && (
            <>
              <Tooltip title="Teléfono">
                <WhatsApp style={design.Phone} />
              </Tooltip>
              <p style={design.text}>{telefono}</p>
            </>
          )}
          {correo && (
            <>
              <Tooltip title="Correo electrónico">
                <AttachEmail style={design.Alter} />
              </Tooltip>
              <p style={design.text}>{correo}</p>
            </>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* List studies x bolet */}
          <ResultsTable
            Estudios={Estudios}
            motivo={Motivo}
            observaciones={observaciones}
          />
          {/* Ingest x bolet */}
          {ingesta?.length > 0 && (
            <>
              <TableIntakeMedicines array={ingesta} />
            </>
          )}
        </Grid>
        <p style={design.responsable}>
          {responsableInfo} el {dateInstant(fechaAlta)}
        </p>
      </AccordionDetails>
    </Accordion>
  );
};
export default TicketsPersonal;
const design = {
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 23,
    width: "95%",
    padding: 5,
    marginLeft: "2%",
    marginTop: "1%",
  },
  name: {
    fontWeight: "bold",
    fontSize: 14,
    marginRight: "1%",
  },
  aling: {
    marginTop: -10,
    marginBottom: -10,
    alignItems: "center",
  },
  text: {
    fontSize: 15,
    marginLeft: "1%",
    marginRight: "1%",
    fontWeight: "initial",
  },
  responsable: { color: "#ababab", textAlign: "right" },
  Female: { color: "#ea899a", fontSize: 25 },
  Male: { color: "#365278", fontSize: 25 },
  Cake: { color: "#9e0f02", fontSize: 25 },
  Assign: { color: "#662e46", fontSize: 25 },
  Phone: { color: "#322e66", fontSize: 25 },
  Alter: { color: "#322e66", fontSize: 25 },
};
