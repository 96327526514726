import { initializeApp } from "@firebase/app";
import firebaseConfig from "./config";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

// Inicializa la aplicación Firebase con la configuración proporcionada en el archivo config.
const app = initializeApp(firebaseConfig);
/**
 * Objeto Firestore para interactuar con la base de datos Firestore.
 * @type {import("@firebase/firestore").Firestore}
 */
export const db = getFirestore(app);

/**
 * Objeto de autenticación para interactuar con Firebase Authentication.
 * @type {import("firebase/auth").Auth}
 */
export const auth = getAuth();
