/**
 * Returns an object containing branches.
 * @returns {Object.<string, string>} Object containing branch keys and their corresponding values.
 */
export const castBranche = () => {
    let branches = {
        bXraSpPxCXDULwQ7sp57: "Texcoco",
        "5lFq3BNUhsluQz9bqjku": "San Vicente",
        bLuXl7Z8FqtV0bozMYSt: "Tepexpan",
        lz8BzO5udd3bzsjJNZDr: "Camarones",
        r5VMdU34BogYoeSMEjRO: "Los Reyes",
        uAA2FdDW6rOJOWEu0A3H: "Chalco de Díaz Covarrubias",
        KZ8sqcmUR64cSIZDOwrd: "Matriz",
        XbOHlB21m9OZMxYM6POh: "Matriz",
    };
    return branches;
}
