import { styled } from "@mui/system";
import Box from "@mui/material/Box";

export const AnimatedBox = styled(Box)`
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-5px);
    }
  }

//   &:hover { Solo si queremos que se active con el mouse
    animation: bounce 2s infinite;
//   }
`;
