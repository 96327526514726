import { servidor } from "../servidor";

/**
 * Obtiene las zonas asociadas a sucursales y parámetros específicos.
 *
 * @param {Array} branches - Array de sucursales.
 * @param {string} store - Franquicia.
 * @param {string} cp - Código postal.
 * @returns {Array} - Array de objetos que representan las zonas obtenidas.
 * @throws {Error} - Se lanza un error si hay un problema al obtener las zonas de alguna sucursal.
 */
export const getZones = async (branches, store, cp) => {
  try {

    const promises = branches.map(async (branch) => {
      const response_zona = await servidor.post(`/movilabs/Zonas_v2/${cp}`, {
        franquicia: store,
        sucursal: branch.id,
      });

      return response_zona.data.length
        ? { zones: response_zona.data, branch: branch.id }
        : null;
    });

    const results = await Promise.all(promises);
    return results.filter(Boolean);
  } catch (error) {
    // Mejora: Proporciona mensajes de error más descriptivos
    if (error.response) {
      // La solicitud se hizo, pero el servidor respondió con un código de error
      throw new Error(`Error en la solicitud: ${error.response.status} - ${error.response.data.message}`);
    } else if (error.request) {
      // La solicitud se hizo, pero no se recibió respuesta del servidor
      throw new Error("No se recibió respuesta del servidor. Verifica tu conexión a Internet y vuelve a intentarlo.");
    } else {
      // Otros errores (por ejemplo, error de JavaScript)
      throw new Error(`Error desconocido: ${error.message}`);
    }
  }
};
