import { getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

/**
 * Hook personalizado para obtener datos de Firestore por referencia.
 * @param {Object} reference - Referencia a los documentos de Firestore.
 * @returns {Object} Un objeto que contiene los datos obtenidos.
 */
export const useGetByReference = (reference = null) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const referenceGet = async () => {
      const entries = Object.entries(reference);

      const x = entries.map(async ([key, value]) => {
  
        if (!value || !value.path) return null;

        const values =  await getDoc(value);
      
        if (!values.exists) {
          return null;
        }
  
        return values.data();
      });
      const data_values = await Promise.all(x);
      setData(data_values.filter(Boolean));
    };
    referenceGet()
  }, [reference]);

  return { data };
};
