// eslint-disable-next-line
export default (currentDate = new Date()) => {
    try {
      // const date = currentDate.toDate();
      const date = new Date(currentDate);
  
      const weekDay = date.getDay();
  
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const fullyear = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
  
      return `${days[weekDay]} ${day < 10 ? "0" + day : day}/${
        month < 10 ? "0" + month : month
      }/${fullyear} a las ${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      } `;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  
  