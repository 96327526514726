import firebase from "../firebase";

/**
 * Obtiene los datos de un responsable dado su ruta en la base de datos.
 * @param {string} responsablePath - La ruta del documento del responsable en la base de datos.
 * @returns {Promise<{apellidos: string, nombre: string}>} Los datos del responsable (apellidos y nombre).
 * @throws {Error} Si hay un error al obtener los datos del responsable o si el documento del responsable no existe.
 */
const getResponsibleData = async (responsablePath) => {
  try {
    // Obtener la referencia del documento del responsable
    const responsableRef = firebase.db.doc(responsablePath);

    // Obtener los datos del documento del responsable
    const responsableSnapshot = await responsableRef.get();

    // Verificar si el documento existe
    if (responsableSnapshot.exists) {
      // Obtener los datos del documento
      const datosResponsable = responsableSnapshot.data();

      // Acceder a los DatosPersonales del responsable
      const datosPersonales = datosResponsable.DatosPersonales;

      // Acceder a los apellidos del responsable
      const apellidos = datosPersonales.apellidos;

      // Acceder al nombre del responsable
      const nombre = datosPersonales.nombre;

      // Devolver los datos del responsable
      return { apellidos, nombre };
    } else {
      throw new Error('El documento del responsable no existe.');
    }
  } catch (error) {
    console.error('Error al obtener los datos del responsable:', error);
    throw error;
  }
};

export default getResponsibleData;