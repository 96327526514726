export const dateInstant = (date = null) => {
    if (!date) return null;
  
    let fecha;
  
    try {
      fecha = date.toDate();
    } catch (error) {
      fecha = date;
    }
    try {
      const dia = fecha.getDate();
      const month = fecha.getMonth() + 1;
      const year = fecha.getFullYear();
      const hours = fecha.getHours();
      const minutes = fecha.getMinutes();
  
      return `${dia < 10 ? "0" + dia : dia}/${
        month < 10 ? "0" + month : month
      }/${year} a las ${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
    } catch (error) {
      return "_n";
    }
  };