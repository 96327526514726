import { useEffect, useState } from "react";

/**
 * Hook personalizado para gestionar el estado y la lógica relacionada con la cantidad y descuento de productos.
 * @param {Object} params - Parámetros del hook.
 * @param {Function} params.onChange - Función de retorno de llamada para cambios en la cantidad de productos.
 * @param {Object} params.product - Información detallada del producto.
 * @param {number} params.value - Valor inicial de la cantidad de productos.
 * @param {number} params.productDiscount - Descuento aplicado al producto.
 * @returns {Object} Objeto con propiedades y funciones relacionadas con la cantidad y descuento de productos.
 */
export const useProduct = ({
  onChange,
  product = null,
  value = 0,
  productDiscount,
}) => {
  const [counter, setCounter] = useState("");
  const [discount, setDiscount] = useState(0);
  const [subtotalProduct, setSubtotalProduct] = useState(0);

  /**
   * Incrementa la cantidad de productos y calcula el subtotal aplicando el descuento.
   * @param {number} value - Nuevo valor de la cantidad de productos.
   * @param {number} valueDiscount - Nuevo valor del descuento aplicado.
   */
  const increaseBy = (value, valueDiscount, all, idx) => {

    if (all) {
      const updatedAll = all.map((doc, index) => {
        const cantidad = doc.cantidad + value;
        const subtotal = cantidad * (doc?.precio || 0);
        const total = subtotal * (1 - doc.descuento / 100);
        
        // Incrementa la propiedad "cantidad" solo para el índice correspondiente
        if (index === idx) {
          return {
            ...doc,
            cantidad: cantidad,
            total: total,
            subtotal: subtotal,
          };
        }

        return doc;
      });
      return updatedAll;
    }
    // Asegurando que los valores sean positivos o cero
    let newValue = Math.max(value, 0);
    let newValueDiscount = Math.max(valueDiscount, 0);

    // Actualizando el estado de la cantidad y el descuento
    setCounter(newValue);
    setDiscount(newValueDiscount);

    // Calculando el total antes de aplicar el descuento
    const total = newValue * (product?.price || 0);

    // Eliminando la propiedad _highlightResult del objeto producto (si existe)
    delete product._highlightResult;

    // Aplicando el descuento al total
    const discountApply = total * (1 - valueDiscount / 100);

    // Actualizando el subtotal del producto
    setSubtotalProduct(Math.round(discountApply));

    // Llamando a la función de cambio si está definida
    onChange &&
      onChange({
        ...product,
        count: newValue,
        discount: newValueDiscount,
        subtotal: total,
        total: discountApply,
      });
  };

  const decrementBy = (value, all, idx) =>{
    if (all) {
      const updatedAll = all.map((doc, index) => {
        const cantidad = doc.cantidad - value;
        const subtotal = cantidad * (doc?.precio || 0);
        const total = subtotal * (1 - doc.descuento / 100);
        
        // Incrementa la propiedad "cantidad" solo para el índice correspondiente
        if (index === idx) {
          return {
            ...doc,
            cantidad: cantidad,
            total: total,
            subtotal: subtotal,
          };
        }

        return doc;
      });
      return updatedAll;
    }
  }

  useEffect(() => {
    setCounter(value);
  }, [value]);

  useEffect(() => {
    setDiscount(productDiscount);
  }, [productDiscount]);

  return {
    counter,
    discount,
    setCounter,
    setDiscount,
    setSubtotalProduct,
    increaseBy,
    decrementBy,
    subtotalProduct,
  }; // Devuelve un objeto con las propiedades necesarias
};
