/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CustomAutocomplete from "../CustomAutocomplete";
import { CssTextField } from "../../CSS/CssTextField";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import imagesArticles from "../../Utils/imagesArticles";
import { validateToken } from "../../Apis/validateToken";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { updateItem } from "./Controllers/updateitem.controller";
import {
  getDepartaments,
  getSubDepartaments,
} from "./Controllers/getDepartments.controller";
import { verifyFields } from "./Controllers/createItem.controller";

const Details = ({ articulo }) => {
  const [form, setForm] = useState({
    ...articulo,
  });

  const [store, setStore] = useState("");
  const { token, user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [departamento, setDepartamento] = useState([]);
  const [subdepartamento, setSubdepartamento] = useState([]);
  const images = imagesArticles.getImagesArticles();

  useEffect(() => {
    getDepartaments().then(setDepartamento);
    getSubDepartaments().then(setSubdepartamento);
  }, []);

  useEffect(() => {
    validateToken(token, user.uid)
      .then(({ branch, store }) => {
        setStore(store);
      })
      .catch(console.log);
  }, []);

  const [reload, setReload] = useState(false);
  const handleModify = async () => {
    try {
      // Verificar los campos del formulario antes de la modificación
      await verifyFields(form);

      // Realizar la actualización del artículo en la base de datos
      setReload(true);

      await updateItem(form, form.path, store, user.uid);
      enqueueSnackbar("Artículo modificado correctamente", {
        variant: "success",
      });
      setReload(true);
    } catch (error) {
      // Si se produce algún error en la validación o modificación del artículo,
      console.error("Error al modificar el artículo:", error);
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      // Finalizar el spinner independientemente de si la modificación del artículo fue exitosa o no
      setReload(false);
    }
  };

  const handleSelect = (img) => {
    setForm({
      ...form,
      image: img?.img,
    });
  };

  return (
    <>
      <Grid container>
        <Grid xs={12} md={3} xl={3} lg={3}>
          <CssTextField
            id="clave"
            label="Clave"
            variant="outlined"
            value={form?.code?.toUpperCase()}
            onChange={(e) => setForm({ ...form, code: e.target.value })}
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Grid>
        <Grid xs={12} md={9} xl={9} lg={9}>
          <CssTextField
            id="nombre"
            label="Nombre"
            variant="outlined"
            value={form?.name?.toUpperCase()}
            // onChange={(e) => setForm({ ...form, nombre: e.target.value })}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setForm({ ...form, name: e.target.value })}
            onKeyUp={(e) =>
              setForm({ ...form, name: e.target.value.toUpperCase() })
            }
            helperText={form?.name?.length < 4 && "Mínimo 3 carácteres"}
          />
        </Grid>
        <Grid container xs={12} md={6} xl={6} lg={6}>
          <Grid xs={2} md={2} xl={2} style={design.alinear}>
            <Avatar src={form?.image} />
          </Grid>
          <CustomAutocomplete
            value={form?.image}
            onChange={handleSelect}
            label="Imagen"
            required={true}
            getOptionLabel={(option) => option.name}
            options={images}
            grid={10}
          />
        </Grid>
        <Grid container xs={12} md={6} xl={6} lg={6}>
          <CustomAutocomplete
            value={form?.available}
            onChange={(value) => {
              const newValue = value.name === "Disponible" ? true : false;
              setForm({ ...form, available: newValue });
            }}
            label={`${form?.available ? "Disponible" : "No Disponible"}`}
            required={true}
            getOptionLabel={(option) => option.name}
            options={Disponibilidad}
            grid={12}
          />
        </Grid>
        <CustomAutocomplete
          value={form?.departament}
          onChange={(value) => setForm({ ...form, departament: value })}
          label={`${form?.departament}`}
          required={true}
          getOptionLabel={(option) => option}
          options={departamento}
          grid={6}
        />
        <CustomAutocomplete
          value={form?.subdepartament}
          onChange={(value) => setForm({ ...form, subdepartament: value })}
          label={`${form?.subdepartament}`}
          required={true}
          getOptionLabel={(option) => option}
          options={subdepartamento}
          grid={6}
        />
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="costo"
            label="Costo"
            variant="outlined"
            type="number"
            min="1"
            value={form?.cost}
            onChange={(cost) => {
              if (cost.target.value >= 0) {
                setForm({ ...form, cost: cost.target.valueAsNumber });
              }
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">.00</InputAdornment>,
            }}
            helperText="El costo siempre debe ser menor al precio del público."
          />
        </Grid>
        <Grid xs={12} md={6} xl={6} lg={6}>
          <CssTextField
            id="precio"
            label="precio"
            variant="outlined"
            type="number"
            min="1"
            value={form?.price}
            onChange={(price) => {
              if (price.target.value >= 0) {
                setForm({ ...form, price: price.target.valueAsNumber });
              }
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <InputAdornment position="end">.00</InputAdornment>,
            }}
            helperText={
              form?.price?.length === 0
                ? null
                : +form?.cost > +form?.price &&
                  "El precio debe ser mayor al costo"
            }
          />
        </Grid>
        <Grid xs={12} md={12} xl={12} lg={12}>
          <CssTextField
            id="tiempo"
            label="Tiempo de entrega"
            required={true}
            variant="outlined"
            value={form?.deliveryTime}
            onChange={(name) =>
              setForm({ ...form, deliveryTime: name.target.value })
            }
            onKeyUp={(e) => setForm({ ...form, deliveryTime: e.target.value })}
            helperText="Ingresar tiempo en días"
          />
        </Grid>
        <Grid xs={12} md={12} xl={12} lg={12}>
          <CssTextField
            id="descripcion"
            label="Descripcion"
            required={true}
            variant="outlined"
            value={form?.description}
            onChange={(name) =>
              setForm({ ...form, description: name.target.value })
            }
            onKeyUp={(e) => setForm({ ...form, description: e.target.value })}
            helperText={
              form?.description?.length === 0
                ? null
                : form?.description?.length < 4 && "Mínimo 3 carácteres"
            }
          />
        </Grid>
        <Grid xs={12} md={12} xl={12} lg={12}>
          <CssTextField
            id="requisitos"
            label="Requisitos"
            variant="outlined"
            required={true}
            multiline
            maxRows={4}
            value={form?.req}
            onChange={(e) => setForm({ ...form, req: e.target.value })}
            InputLabelProps={{ shrink: true }}
            helperText={
              form?.req?.length === 0
                ? null
                : form?.req?.length < 11 && "Mínimo 10 carácteres.Si requieres dar saltos de línea coloca +"
            }
          />
        </Grid>
        <Grid xs={12} md={12} xl={12} lg={12}>
          <CssTextField
            id="recomendaciones"
            label="Recomendaciones"
            variant="outlined"
            multiline
            maxRows={4}
            value={form?.recomd}
            onChange={(e) => {
              if (e.target.value === undefined) {
                setForm({ ...form, recomd: "" });
              } else {
                setForm({ ...form, recomd: e.target.value });
              }
            }}
            InputLabelProps={{ shrink: true }}
            helperText={
              form?.recomd?.length === 0
                ? null
                : form?.recomd?.length < 11 && "Mínimo 10 carácteres. Si requieres dar saltos de línea coloca //"
            }
          />
        </Grid>
      </Grid>

      <Box xs={12} md={12} xl={12} lg={12} style={design.align}>
        {reload ? (
          <CircularProgress />
        ) : (
          <DarkGreenButton width={"70%"} height={"70px"} onClick={handleModify}>
            Modificar estudio
          </DarkGreenButton>
        )}
      </Box>
    </>
  );
};
export default Details;

const design = {
  align: {
    display: "flex",
    justifyContent: "center",
  },
  Alert: {
    backgroundColor: "#F08050",
    width: "92%",
    height: 30,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 1,
    marginLeft: 1,
  },
  info: { color: "#FFF" },
  txtAlert: {
    color: "#FFF",
    alignSelf: "center",
    fontWeight: 400,
    marginLeft: "3%",
  },
  alinear: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  selec: {
    width: "98%",
    margin: 10,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#0E5B91",
        borderWidth: 2,
        borderRadius: 10,
      },
    },
  },
  menu: { paddingLeft: "5%", width: "100%" },
};

const Disponibilidad = [{ name: "Disponible" }, { name: "No disponible" }];
