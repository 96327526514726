import React from "react";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CustomTextField from "./CustomTextField";
import { DarkGreenButton } from "../CSS/Contained/DarkGreenButton";

const CustomDialog = ({
  grid = 12,
  widthIMG,
  input,
  image,
  open,
  title,
  body,
  handleClose,
  handleClick,
  isLoading,
  form,
  onChange,
}) => {
  return (
    <>
      <Grid item xs={grid} md={grid} xl={grid}>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent style={design.modal}>
            {image && (
              <Box
                width={widthIMG < 1000 ? "30%" : "30%"}
                height={widthIMG < 1000 ? "7%" : "15%"}
                sx={{
                  marginTop: widthIMG < 1000 ? 0 : 0,
                  marginLeft: "35%",
                }}
                component="img"
                src={image}
                alt={title}
              />
            )}
            <DialogTitle>{title}</DialogTitle>
            <DialogContentText>{body}</DialogContentText>
            {input === true ? (
              <CustomTextField
                id={title + "-Input"}
                name={title + "-Input"}
                title={title + "-Input"}
                label="Correo electrónico"
                value={form}
                onChange={onChange}
                required
                type="email"
                width="90%"
              />
            ) : null}
            <DarkGreenButton
              variant="contained"
              disabled={isLoading}
              onClick={handleClick}
              width="90%"
            >
              {isLoading ? ". . ." : "Recuperar"}
            </DarkGreenButton>
          </DialogContent>
        </Dialog>
      </Grid>
    </>
  );
};
export default CustomDialog;

const design = {
  modal: {
    backgroundColor: "rgba(244, 244, 244, 1)",
    boxShadow:
      "0px 2.229900360107422px 8.362126350402832px 0px rgba(0, 0, 0, 0.25)",
  },
};
