import React from "react";
import { Tooltip, Box,Grid } from "@mui/material";
import { castStatus } from "../../Utils/castStatus";

const StatusXStaff = ({
  status,
  estatus,
  width,
}) => {

  const ICONS = {
    laboratorista:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png",
    radiologo:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Rayos_X.png",
    doctor:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Consultas.png",
    administrador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Call_center.png",
    operador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
  };

  if (!status) {
    return (
      <>
        <Grid item>
          <Tooltip arrow title={"Laboratorio" || "Verificar información"}>
            <Box
              component="img"
              src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png"
              alt="Laboratorio"
              // 64 x 173
              width={width}
              height={width}
            />
          </Tooltip>
          {castStatus(estatus)} <br />
        </Grid>
      </>
    );
  }

  return Object.entries(status).map(([key, value]) => (
    <>
      <Tooltip arrow title={key || "Verificar información"}>
        <Box
          component="img"
          src={ICONS[key]}
          alt={ICONS[key]}
          width={width}
          height={width}
        />
      </Tooltip>
      &nbsp;{castStatus(value)} <br />
    </>
  ));
};

export default StatusXStaff;
