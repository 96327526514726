/**
 * Array containing user IDs and their respective availability EXPORT in datagrid.
 * @type {Array<Object>}
 */
export const USERS_DISPONIBILITY = [
  { uid: "iYcUOfvEUfdohfDXyUxRemKuXTk1" }, //MARIANA
  { uid: "lpwQ36Gk4cO6oYI0kqMR76bzrs22" }, //LEONARDO
  { uid: "5xENJbxL92gI4Xtw6IPYKReuo0Z2" }, //BRENDA
  { uid: "BwpVXoj0ZlYh7kUVFZ40xnyweWN2" }, //SEBAS
  { uid: "WwVSg2qep4PWO2P27GtcufZWXEI2" }, //TATO
  { uid: "NkT3bNkTevPAyAumSQ3AKU9KfmI3" }, //MARISOL
  { uid: "mxf7UN5D8NXxePbIPSbUPRk8Pdv1" }, //CHUCHE
];
/**
 * Array containing user IDs and their respective maximum hours of availability.
 * @type {Array<Object>}
 */
export const USERS_DISPONIBILITY_WITH_MAX_HOURS = [
  { uid: "vuZc0d5DWshiqukg8xfKNu4KCXO2", maxHours: 19 }, //MIMI
  { uid: "ZgA3lhhXcdelekpOjbmg4dtfPRu2", maxHours: 19 }, //CARO
  { uid: "iYcUOfvEUfdohfDXyUxRemKuXTk1", maxHours: 21 }, //MARIANA
  { uid: "lpwQ36Gk4cO6oYI0kqMR76bzrs22", maxHours: 21 }, //LEONARDO
  { uid: "5xENJbxL92gI4Xtw6IPYKReuo0Z2", maxHours: 21 }, //BRENDA
  { uid: "BwpVXoj0ZlYh7kUVFZ40xnyweWN2", maxHours: 24 }, //SEBAS
  { uid: "WwVSg2qep4PWO2P27GtcufZWXEI2", maxHours: 24 }, //TATO
  { uid: "NkT3bNkTevPAyAumSQ3AKU9KfmI3", maxHours: 24 }, //MARISOL
  { uid: "mxf7UN5D8NXxePbIPSbUPRk8Pdv1", maxHours: 24 }, //CHUCHE
];
