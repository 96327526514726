import { useEffect, useState } from "react";
import firebase from "../firebase";
import { castBranche } from "../Utils/castBranche";
import getResponsibleData from "../Controllers/getResponsibleData";

/**
 * Hook personalizado para contar pedidos por responsable de una sucursal.
 * @param {string} store - El nombre de la sucursal.
 * @returns {Object} - Un objeto que contiene el recuento de pedidos por responsable.
 */
const useOrderCounterResponsible = (store) => {
  const [pedidosPorSucursal, setPedidosPorSucursal] = useState({});

  useEffect(() => {
    // Obtener la referencia a la colección 'PedidosAux'
    const pedidosRef = firebase.db.collection("PedidosAux");

    /**
     * Función para obtener la fecha límite del mes actual.
     * @returns {Date} - La fecha límite del mes actual.
     */
    const obtenerFechaLimite = () => {
      const fechaActual = new Date();
      fechaActual.setDate(1);
      fechaActual.setHours(0, 0, 0, 0); // Establecer la hora, los minutos, los segundos y los milisegundos a 0
      // console.log(fechaActual);
      return fechaActual;
    };
    // Cuando se quiera recabar nuevamente un mes previo insertar 
    // las fechas iniciales y finales, ademas comentar linea 41
    //  y utilizar las lineas 42/43
    // const startDate = new Date("2024-04-01");
    // const endDate = new Date("2024-04-30");
    /**
     * Función asincrónica para contar los pedidos por responsable.
     */
    const contarPedidos = async () => {
      const fechaLimite = obtenerFechaLimite();
      pedidosRef
        .where("franquicia", "==", store)
        .where("fechaAlta", ">", fechaLimite)
        // .where("fechaAlta", ">=", startDate)
        // .where("fechaAlta", "<=", endDate)
        .get()
        .then(async (snapshot) => {
          const pedidosTotalesPorSucursal = {};

          for (const doc of snapshot.docs) {
            const pedidoData = doc.data();
            const sucursalId = `${castBranche()[pedidoData.sucursal]}`;
            const responsableRef = pedidoData.responsable;

            if (responsableRef) {
              const responsablePath = responsableRef.path;
              const { nombre } = await getResponsibleData(responsablePath); // Obtener datos del responsable
              const clave = `${sucursalId}-${nombre}`;
              if (pedidosTotalesPorSucursal[clave]) {
                pedidosTotalesPorSucursal[clave]++;
              } else {
                pedidosTotalesPorSucursal[clave] = 1;
              }
            }
          }

          setPedidosPorSucursal(pedidosTotalesPorSucursal);
        });
    };

    // Llamar a la función de conteo al cargar el hook y cada vez que haya cambios en la colección
    contarPedidos();
    const unsubscribe = pedidosRef.onSnapshot(contarPedidos);

    // Limpiar la suscripción al desmontar el componente
    return () => unsubscribe();
  }, [store]);

  return pedidosPorSucursal;
};

export default useOrderCounterResponsible;
