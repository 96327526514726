/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import CustomTextField from "../../CustomTextField";
import { Alert, Grid, InputAdornment } from "@mui/material";
import SearchArticles from "./Article/SearchArticles";
import { useProduct } from "../../../hooks/Products/useProduct";
import { useOrder } from "../../../Context/Ordenes_v2/Order.context";
import { useSnackbar } from "notistack";

/**
 * Componente funcional para gestionar los estudios personalizados.
 * @returns {JSX.Element} Componente de React para gestionar los estudios personalizados.
 */
const CustomStudies = () => {
  const { onProductCountChange, activity } = useOrder();

  const [form, setForm] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setForm(null);
  }, [!refresh]);

  return (
    <div id="SECTION-2">
      <ProductProvider
        product={form}
        setForm={setForm}
        onProductCountChange={onProductCountChange}
      >
        <Grid container>
          <Grid xs={12} md={3} xl={3}>
            <p style={design.title}>3. Estudios</p>
          </Grid>
          <Grid xs={12} md={9} xl={9}>
            <Alert severity="info" variant="outlined" style={design.alert}>
              Recuerda que el descuento máximo es de 30%
            </Alert>
          </Grid>
        </Grid>
        <Grid container style={design.border}>
          <SearchArticles onSelect={setForm} refresh={refresh} />
          <Item
            activity={activity}
            form={form}
            setForm={setForm}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Grid>
      </ProductProvider>
    </div>
  );
};

/**
 * Componente funcional para mostrar un ítem del estudio.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.form - Objeto que contiene los datos del artículo.
 * @returns {JSX.Element|null} Componente de React para mostrar un ítem del estudio.
 */
const Item = ({ activity, form, setForm, setRefresh, refresh }) => {
  const {
    increaseBy,
    discount,
    counter,
    setCounter,
    setDiscount,
    setSubtotalProduct,
    subtotalProduct,
    onProductCountChange,
  } = useContext(ProductContext);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Maneja la adición de un ítem, realizando validaciones y llamando a la función para agregar el ítem.
   *
   * @function handleAddItem
   */
  const handleAddItem = () => {
    const product = {
      cantidad: counter || 0,
      descuento: discount || 0,
      departamento: form?.departament || "Laboratorio",
      id: form?.id,
      nombre: form?.name,
      precio: form?.price,
      subtotal: subtotalProduct || 0,
      total: counter * form?.price || 0, 
    };

    try {
      // Validaciones para garantizar que la cantidad y el descuento sean válidos.
      if (!product?.cantidad || product?.cantidad < 0) {
        throw new Error("Especifica una cantidad");
      }
      if (product?.descuento < 0) {
        throw new Error("Descuento no válido.");
      }

      // Si no hay ítem, se retorna sin hacer nada más.
      if (!product) {
        console.log("no hay producto");
        return;
      }

      onProductCountChange(counter, product);
      setRefresh(!refresh);
      setCounter(0);
      setDiscount(0);
      setSubtotalProduct(0);
      setForm(null);
    } catch (error) {
      // En caso de error, muestra una notificación con el mensaje de error.
      enqueueSnackbar(error.message, { variant: "warning" });
    }
  };

  if (!form?.code) return null; // Verifica si form está definido antes de renderizar
  return (
    <>
      <CustomTextField
        label="Cantidad"
        type="number"
        name="amount"
        value={counter}
        grid={6}
        required={false}
        onChange={(e) => {
          if (e.target.value < 0) return 0;
          increaseBy(parseInt(e.target.value.trim()), 0);
        }} // Va al hook useProduct a hacer el calculo
        onKeyDown={(e) => {
          if (e.code.toUpperCase().includes("ENTER")) {
            handleAddItem();
            activity.push(2);
          }
        }}
      />
      <CustomTextField
        label="Precio"
        type="number"
        name="price"
        disabled={true}
        value={form?.price} // valor del producto predeterminado
        grid={6}
        required={false}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <CustomTextField
        label="Descuento sobre artículo"
        type="number"
        name="discount"
        InputLabelProps={{ shrink: true }}
        value={discount}
        grid={6}
        onChange={(e) => {
          if (e.target.value < 0) return 0;
          if(e.target.value > 30) return 0;
          increaseBy(counter, parseInt(e.target.value.trim()));
        }} // Va al hook useProduct a hacer el calculo, enviando cantidad + ingreso
        required={false}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onKeyPress={(e) => {
          if (e.code.toUpperCase().includes("ENTER")) {
            handleAddItem();
            activity.push(2);
          }
        }}
      />
      <CustomTextField
        label="Subtotal"
        type="number"
        name="subtotal"
        disabled={true}
        grid={6}
        value={subtotalProduct} // Va al hook useProduct a retornar el calculo
        required={false}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </>
  );
};

/**
 * Contexto para gestionar los datos del producto.
 * @type {React.Context}
 */
export const ProductContext = createContext();
const { Provider } = ProductContext;

/**
 * Proveedor de contexto para el producto.
 * @param {Object} props - Propiedades del proveedor de contexto.
 * @param {JSX.Element} props.children - Elementos hijos del proveedor de contexto.
 * @param {Object} props.product - Producto relacionado con el proveedor.
 * @param {number} props.value - Valor inicial del producto.
 * @param {number} props.productDiscount - Descuento aplicado al producto.
 * @param {Function} props.onChange - Función de cambio para el producto.
 * @returns {JSX.Element} Proveedor de contexto para el producto.
 */
const ProductProvider = ({
  children,
  product = null,
  setForm,
  value = 0,
  productDiscount,
  onProductCountChange,
}) => {
  const {
    counter,
    discount,
    setCounter,
    setDiscount,
    setSubtotalProduct,
    increaseBy,
    subtotalProduct,
  } = useProduct({
    product: product || { id: null, price: 0, cantidad: 0, subtotal: 0 },
    value,
    productDiscount,
  });
  return (
    <Provider
      value={{
        counter,
        increaseBy,
        product,
        setForm,
        discount,
        setCounter,
        setDiscount,
        setSubtotalProduct,
        subtotalProduct,
        onProductCountChange,
      }}
    >
      {children}
    </Provider>
  );
};

export default CustomStudies;

// Estilos de diseño
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 28,
    lineHeight: "20px",
  },
  border: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  alert: {
    width: "87%",
    fontSize: 12,
    backgroundColor: "transparent",
    marginTop: "2%",
    marginBottom: "2%",
    borderRadius: 15,
  },
};
