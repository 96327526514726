/**
 * @author Dulce Maria Muñoz Garcia.
 * @function castStatus()
 * @description Retorna el estatus correspondiente al número de este.
 * @param {number} estatus Número del mes a castear.
 * @return retorna el string del estatus, string de error en caso contrario.
 */
export const castStatus = (estatus) => {
  const DEFAULT_ESTATUS = "ERROR DE ESTATUS";
  const ESTATUS_PEDIDO = {
    0: "Pendiente de confirmación",
    1: "Pedido confirmado",
    2: "Estamos en camino",
    3: "Hemos llegado a la toma",
    4: "Datos capturados",
    5: "Toma finalizada",
    6: "Entrega de muestras a laboratorio",
    7: "En espera de resultados",
    8: "Resultados recibidos",
    9: "Cargando resultados",
    10: "Validación de resultado",
    11: "Finalizado",
    100: "Pedido cancelado",
    110: "En crédito",
  };
  // console.log(estatus);
  const palabra = ESTATUS_PEDIDO[estatus] || DEFAULT_ESTATUS;
  return palabra;
};
