/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Tooltip,
  Box,
  Tabs,
  Tab,
  // TabPanel,
} from "@mui/material";
import PropTypes from "prop-types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebasev2";
import CustomAutocomplete from "./CustomAutocomplete";
import CustomTextField from "./CustomTextField";
import { GreenButton } from "../CSS/Outlined/GreenButton";
import { useOrder } from "../Context/Ordenes_v2/Order.context";
import { useDimensions } from "../hooks/useDimensions";

// Componente que maneja el inicio de la orden
const Start = ({ open, typeCreate, setTypeCreate, onSelect = () => {} }) => {
  const { avalibleBranches, searchBranchByPostalCode } = useOrder();
  const dimensionWidth = useDimensions("width");
  const Phone = dimensionWidth < 1000;
  // Estilos condicionales para el texto de las opciones
  const ColorV = {
    color: typeCreate === 0 ? "#000" : "#c4c4c4",
    ...design.descrip,
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12} xl={12}>
              {/* Botón para tomar a domicilio */}
              <GreenButton
                onClick={() => setTypeCreate(0)}
                style={{
                  backgroundColor: typeCreate === 0 ? "#35b32e" : "#FFF",
                  color: typeCreate === 0 ? "#FFF" : "#35b32e",
                  border: "2px solid #35b32e",
                  borderRadius: "32px",
                  width: "90%",
                  height: Phone ? 50 : null,
                }}
              >
                Toma a domicilio
              </GreenButton>
              {/* Descripciones para la opción de toma a domicilio */}
              <div style={design.Line}>
                <p style={ColorV}>
                  &bull; La toma a domicilio el operador esta capacitado para
                  realizar las tomas de sangre según el estudio que se agende
                  <br />
                  <br />
                  &bull;Los artículos que lleva el operador es el adecuado para
                  realizar las tomas de sangre
                </p>
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6} xl={6}>
              {/* Botón para recolección 
              <CianButton
                disabled={true}
                variant="contained"
                component="span"
                onClick={() => setTypeCreate(1)}
                style={{
                  backgroundColor: typeCreate === 1 ? "#00518c" : "#FFF",
                  color: typeCreate === 1 ? "#FFF" : "#00518c",
                  border: "2px solid #00518c",
                  borderRadius: "32px",
                  width: "90%",
                  height: Phone ? 50 : null,
                }}
              >
                Recolección
              </CianButton>
              {/* Descripciones para la opción de recolección 
              <div style={design.Line}>
                <p style={ColorA}>
                  &bull; El operador que asista a la recolección NO esta
                  capacitado para realizar tomas de sangre
                  <br />
                  &bull; Los artículos que lleva el operador es limitado
                </p>
              </div>
            </Grid> */}
            {/* Campo de texto para el código postal */}
            <CustomTextField
              label="Código postal"
              type="text"
              name="direccion.cp"
              // maxLength={5}
              width="96%"
              onChange={(e) => {
                const cpValue = e.target.value;
                searchBranchByPostalCode(cpValue);
              }}
              grid={12}
              required={true}
            />

            {/* Autocompletar para seleccionar sucursales */}
            <CustomAutocomplete
              label="Sucursales"
              disabled={!avalibleBranches.length}
              options={avalibleBranches?.length ? avalibleBranches : []}
              getOptionLabel={(option) => option.name}
              grid={12}
              onChange={onSelect}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

// Componente que muestra los detalles de un producto
const Details = ({ open, handleClose, id, setInfo, franquicia, sucursal }) => {
  const [detalles, setDetalles] = useState({});

  /**
   * Función asincrónica para obtener los detalles de un producto.
   * @async
   * @function getDetails
   * @returns {Promise<void>} No retorna ningún valor explícito.
   */
  const getDetails = async () => {
    if (typeof id === "string") {
      // Construye la referencia al documento en la base de datos.
      const ref = doc(
        db,
        "Franquicias",
        franquicia,
        "Sucursales",
        sucursal,
        "Products",
        id
      );
      // Obtiene el documento de la base de datos.
      const response = await getDoc(ref);
      // Extrae los datos del documento.
      const data = response.data();

      // Verifica si los datos son válidos.
      if (typeof data === "undefined") {
        // Si no hay datos, establece el estado de la información como falso y retorna.
        setInfo(false);
        return;
      }

      // Si hay datos, establece los detalles del producto y el estado de la información como verdadero.
      setDetalles(data);
      setInfo(true);
      return;
    }

    // Si el ID no es una cadena, establece el estado de la información como falso y retorna.
    setInfo(false);
    return;
  };

  // Efecto secundario que se ejecuta después de que el componente se monta.
  useEffect(() => {
    getDetails();
  }, []);
  // console.log(detalles);
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={2} xl={2}>
              <Box
                component="img"
                alt={detalles?.name}
                src={detalles?.image}
                m="0% 35%"
                // 95 x 95
                width={90}
                height={90}
              />
            </Grid>
            <Grid item xs={12} md={8} xl={8}>
              <h1 style={design.title}>{detalles?.name}</h1>
            </Grid>
            <Grid item xs={12} md={2} xl={2}>
              <h2 style={design.total}>
                {detalles?.price?.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  currencyDisplay: "symbol",
                })}
              </h2>
            </Grid>

            {/* Sección de requisitos */}
            {detalles?.req && (
              <Grid item xs={12} md={4} xl={4}>
                <b>Requisitos: </b>
                {detalles.req.split("+").map((req, index) => (
                  <p key={index}>&bull; {req}</p>
                ))}
              </Grid>
            )}
            {/* Sección de entrega de resultados */}
            {detalles?.deliveryTime && (
              <Grid item xs={12} md={4} xl={4} textAlign="center">
                {/* <b>Entrega de resultados:</b> */}
                <p>
                  <Tooltip arrow title="Entrega de resultados">
                    <Box
                      component="img"
                      alt="Entrega de resultados"
                      src="https://movicaremx.com/IMG/app_mobile/FormatPNG/puntualidad.png"
                      m="0% 2%"
                      // 500 x 500
                      width={40}
                      height={40}
                    />
                  </Tooltip>
                  {detalles.deliveryTime}
                </p>
              </Grid>
            )}
            {/* Sección de recomendaciones */}
            {detalles?.recomd && (
              <Grid item xs={12} md={4} xl={4}>
                <b>Recomendaciones: </b>
                {detalles.recomd.split("//").map((rec, index) => (
                  <p key={index}>&#8227; {rec}</p>
                ))}
              </Grid>
            )}
            {/* Sección de descripción */}
            {detalles?.description && (
              <Grid item xs={12} md={12} xl={12}>
                <p>
                  <b>Descripción: </b>
                  {detalles?.description}
                </p>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

// Componente que muestra los detalles de descuentos y promoción mensual
const Discounts = ({
  open,
  handleClose,
  promotionOfTheMonth,
  imageDiscounts,
  imageDiscountsPublic,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Grid
        style={{ justifyContent: "center", alignItems: "center" }}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </Grid>
    );
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
        <DialogContent dir="row">
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              color="#00518c"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Tabuladores descuentos"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab label="Promoción del mes" {...a11yProps(0)} />
              <Tab label="Tabulador público" {...a11yProps(1)} />
              <Tab label="Tabulador empleados" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box
                component="img"
                alt="Promoción del mes"
                src={promotionOfTheMonth}
                m="0% 14%"
                // 1081 x 1200
                width="80%"
                height="50%"
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                component="img"
                alt="Tabla de descuentos al público"
                src={imageDiscountsPublic}
                m="0% 13%"
                // 1081 x 1100
                width="90%"
                height="50%"
              />
            </TabPanel>

            <TabPanel value={value} index={2}>
              <Box
                component="img"
                alt="Tabla de descuentos colaboradores"
                src={imageDiscounts}
                m="0% 13%"
                // 1081 x 1100
                width="90%"
                height="50%"
              />
            </TabPanel>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
// Exportación del componente principal y los estilos asociados
export default { Start, Details, Discounts };

/**
 * Estilos predeterminados para el componente ModalDetailsProduct.
 *
 * @constant
 * @type {Object}
 */
const design = {
  title: {
    // Estilos para el título
    fontWeight: "bold",
    color: "#4496D2",
    textAlign: "center",
  },
  total: {
    // Estilos para el total
    padding: "0 10%",
    color: "#356c2d",
    textAlign: "right",
  },
  desc: {
    // Estilos para la descripción
    textAlign: "justify",
  },
};
