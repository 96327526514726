import { Button, styled } from "@mui/material";

/**
 * ModerateBlueButton component is a styled Material-UI Button with custom styles.
 * @param {object} props - Component props.
 * @param {string} [props.width="60%"] - Button width.
 * @param {string} [props.height="66px"] - Button height.
 * @returns {JSX.Element} Styled Material-UI Button component.
 */
export const ModerateBlueButton = styled(Button)(({ width, height }) => ({
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 25px",
  gap: "10px",
  width: width || "60%",
  fontFamily: "Lexend",
  textTransform: "none",
  fontWeight: 500,
  height: height ? height : "66px",
  margin: "2%",
  fontSize: 19,
  border: "5px solid #4496D2",
  backgroundColor: "#4496D2",
  color: "#fff",
  borderRadius: "32px",
  boxShadow: "10px 10px 37px 0px #BDE0FEA6",
  "&:hover": {
    border: "5px solid #4496D2",
    backgroundColor: "#4496D2",
    color: "#fff",
    fontFamily: "Lexend",
    textTransform: "none",
    fontWeight: 500,
  },
}));
