/* eslint-disable no-new-object */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Proveedor de contexto y gestor de estado para las órdenes.
 * @module OrderState
 * @param {object} props - Propiedades del componente.
 * @param {ReactNode} props.children - Componentes hijos que estarán envueltos por el contexto de órdenes.
 * @returns {JSX.Element} Componente proveedor de contexto y gestor de estado para las órdenes.
 */
import {
  useEffect,
  useReducer,
  useContext,
  createContext,
  useState,
} from "react";
import OrderReducer from "./reducer";
import { servidor } from "../../servidor";
import { validateToken } from "../../Apis/validateToken";
import { useAuth } from "../Auth_v2/Auth.context";
import { getBranches } from "../../Apis/getSucursales";
import {
  SELECT_BRANCH,
  SET_AVALAIBLE_BRANCHES,
  SET_BRANCHES,
  SET_COLONIES,
} from "./types";
import { useSnackbar } from "notistack";
import {
  validateDireccion,
  validateHorarios,
  validateUsuario,
  validatePayment,
  validateOrder,
} from "../../Validations/validateOrder";
import firebase from "../../firebase";
import { useNavigate } from "react-router-dom";
import { sendEmailOrder } from "../../Apis/senEmailOrder";
import { getZones } from "../../Apis/getZones";
import timeStamp_toString from "../../Utils/timeStamp_toString";

// Creación del contexto para el estado relacionado con las órdenes
const OrderContext = createContext();
// Estado inicial para el contexto de órdenes
const initialState = {
  branches: [],
  branch: null,
  avalibleBranches: [],
  colonies: [],
};
// -------------------------------------------------------------------------

// Componente que proporciona el contexto y gestiona el estado de las órdenes
const OrderState = ({ children }) => {
  //navegación
  const navigate = useNavigate();
  // Obtiene la información del usuario actual del contexto de autenticación
  const { user } = useAuth();
  // Utiliza useReducer para gestionar el estado con el reducer OrderReducer
  const [state, dispatch] = useReducer(OrderReducer, initialState);
  // Estado local para el formulario y el carrito de compras
  const [form, setForm] = useState({});
  // Carrito de compras: sumar y restar del carrito de compras
  const [shoppingCart, setShoppingCart] = useState([]);
  //Estado para el llenado del paciente
  const [patient, setPatient] = useState({});
  //Estado para esperar
  const [loading, setLoading] = useState(false);

  //Estado de zona
  const [zona] = useState([]);
  /**
   * Lista de ítems de la orden.
   * @type {Object[]}
   */
  const [Orden] = useState([]);
  /**
   * Horarios seleccionados para la orden.
   * @type {Object}
   */
  const [horariosSelected, setHorarios] = useState({});
  /**
   * tag seleccionados para la orden.
   * @type {Object}
   */
  const [tags, setTags] = useState([]);
  /**
   * Totales calculados para la orden.
   * @type {Object}
   */
  const [totales, setTotales] = useState({ total: 0, subtotal: 0 });
  //estado de sucursal
  const [store, setStore] = useState();

  //Actividad del stepper
  const [activity, setActivity] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  // Función para manejar cambios en el formulario
  // -------------------------------------------------------------------------
  /**
   * Función para manejar cambios en el formulario.
   * @param {Event} e - Evento de cambio.
   */
  const handleChangeForm = (e) => {
    const key = e.target?.name;
    const value = e.target?.value;
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "direccion.referencia") {
      activity.push(1);
    }
  };

  // Función para obtener los campos para un articulo
  // -------------------------------------------------------------------------
  const filterFields = (doc) => {
    const {
      nombre,
      cantidad,
      total,
      subtotal,
      descuento,
      id = null,
      clave = null,
      precio,
    } = doc;
    return {
      nombre,
      cantidad,
      total: subtotal,
      subtotal: total,
      precio,
      descuento,
      id: id ?? clave,
    };
  };
  // -------------------------------------------------------------------------

  /**
   * Selecciona los ítems del laboratorio.
   * @param {Object} item - Ítem a evaluar.
   * @returns {Object|null} - Ítem filtrado o null si no corresponde al laboratorio.
   */
  // -------------------------------------------------------------------------
  const selectLaboratorio = (item) => {
    const { departamento } = item;
    if (
      `${departamento}`.toUpperCase().includes("LABORATORIO") ||
      `${departamento}`.toUpperCase().includes("ESTUDIOS DE GABINETE") ||
      `${departamento}`.toUpperCase().includes("ESTUDIOS ESPECIALES") ||
      `${departamento}`.toUpperCase().includes("PROMOCIONES")
    ) {
      return item;
    }
    return null;
  };
  // -------------------------------------------------------------------------

  /**
   * Selecciona los ítems de Radiología.
   * @param {Object} item - Ítem a evaluar.
   * @returns {Object|null} - Ítem filtrado o null si no corresponde al laboratorio.
   */
  // -------------------------------------------------------------------------
  const selectRadiologia = (item) => {
    const { departamento } = item;

    if (`${departamento}`.toUpperCase().includes("RAD")) {
      return item;
    }
    return null;
  };
  // -------------------------------------------------------------------------

  /**
   * Selecciona los ítems de servicios.
   * @param {Object} item - Ítem a evaluar.
   * @returns {Object|null} - Ítem filtrado o null si no corresponde a servicios (AMBULANCIA/RESONANCIA/TOMOGRAFIAS).
   */
  // -------------------------------------------------------------------------
  const selectOperador = (item) => {
    const { departamento } = item;

    if (
      `${departamento}`.toUpperCase().includes("SERV") ||
      `${departamento}`.toUpperCase().includes("TOM") ||
      `${departamento}`.toUpperCase().includes("RES")
    ) {
      return item;
    }
    return null;
  };
  // -------------------------------------------------------------------------

  /**
   * Función para manejar cambios personalizados en el formulario.
   * @param {string} key - Clave del campo del formulario.
   * @param {any} value - Valor del campo del formulario.
   */
  // -------------------------------------------------------------------------
  const handleCustomForm = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "name") {
      activity.push(0);
    }
  };
  // -------------------------------------------------------------------------

  /**
   * @function getDepartamentos
   * @description Obtiene los departamentos de la orden para generar los horarios de servicio.
   */
  // -------------------------------------------------------------------------
  const getDepartamentos = async () => {
    const tags = Orden.map((e) => {
      return `${e?.departamento}`.toUpperCase() || null;
    }).filter(Boolean);

    const FINALLY_TAGS = tags.filter(
      (item, index) => tags.indexOf(item) === index
    );

    if (!FINALLY_TAGS.includes("RADIOLOGÍA")) {
      delete horariosSelected.radiologia;
    }

    setTags(FINALLY_TAGS);
  };
  // -------------------------------------------------------------------------

  /**
   * Calcula los totales de la orden
   * Calculate discount over the order
   * Quedan cambiados los totales son iguales a los subtotales dentro de product estan al reves
   */
  // -------------------------------------------------------------------------
  const calculateTotales = () => {
    const total = Orden.reduce((prev, curr) => curr.subtotal + prev, 0);

    setTotales({ subtotal: total, total });
  };
  // -------------------------------------------------------------------------

  /**
   * Función para manejar cambios en la cantidad de productos dentro del carrito.
   * @param {object} param0 - Objeto con la cantidad y el producto.
   */
  // -------------------------------------------------------------------------
  const onProductCountChange = async (count, product) => {
    setShoppingCart((oldShoppingCart) => {
      // Copia el carrito de compras existente a un nuevo arreglo
      const newShoppingCart = [...Object.values(oldShoppingCart)];
      // Encuentra el índice del producto en el nuevo arreglo
      const productIndex = newShoppingCart.findIndex(
        (item) => item.id === product.id
      );
      if (count === 0) {
        // Si el recuento es cero, elimina el producto del arreglo
        if (productIndex !== -1) {
          newShoppingCart.splice(productIndex, 1);
          Orden.splice(productIndex, 1);
          getDepartamentos();
          calculateTotales();

          if (!Orden.length) {
            let arraySinDuplicados = [...new Set(activity)];
            let arregloSinDos = arraySinDuplicados.filter(
              (numero) => numero !== 2
            );

            activity.length = 0;
            arregloSinDos.forEach((elemento) => {
              if (elemento !== 4) {
                activity.push(elemento);
              }
            });
          }
        }
      } else {
        // Si el recuento no es cero, actualiza o agrega el producto en el arreglo
        if (productIndex !== -1) {
          // Si el producto ya está en el carrito, actualiza su cantidad
          newShoppingCart[productIndex] = { ...product };
          Orden[productIndex] = product;

          getDepartamentos();
          calculateTotales();
        } else {
          // Si el producto no está en el carrito, agrégalo al final del arreglo
          newShoppingCart.push({ ...product, id: product.id });
          Orden.push(product);

          getDepartamentos();
          calculateTotales();
        }
      }
      // Devuelve el nuevo arreglo como el nuevo estado del carrito de compras
      return newShoppingCart;
    });
  };
  // -------------------------------------------------------------------------

  /**
   * Función para buscar sucursales por código postal.
   * @param {string} value - Valor del código postal a buscar.
   * @param {boolean} isDirection - Indica si se trata de una dirección.
   */
  // -------------------------------------------------------------------------
  const searchBranchByPostalCode = async (value = "", isDirection) => {
    try {
      Orden.length = 0;
      setTotales({ subtotal: 0, total: 0 });
      onProductCountChange(0, shoppingCart);
      setTags([]);
      zona.length = 0;
      shoppingCart.length = 0;

      if (!value || value.length !== 5) {
        return;
      }
      const token = await validateToken(
        localStorage.getItem("token"),
        user.uid
      );
      const { store } = token;

      setStore(store);

      const DOMICILIO = await servidor.get(`/Domicilios/Direccion/${value}`);
      const { Domicilios = [] } = DOMICILIO.data;

      if (!Domicilios.length) {
        throw new Error("No se encontró ningún código postal.");
      }

      const response_zone = await getZones(state.branches, store, value);

      zona.push(Domicilios[0].estado);
      zona.push(response_zone[0].zones[0].Codigo);
      zona.push(response_zone[0].zones[0].Zona);

      const avalibleBranches = state.branches.filter((el) =>
        response_zone.some((f) => {
          return f.branch === el.id;
        })
      );

      if (!avalibleBranches.length && response_zone.length) {
        enqueueSnackbar("Intenta seleccionar la sucursal más cercana", {
          variant: "info",
        });
      }

      dispatch({ type: SET_AVALAIBLE_BRANCHES, payload: avalibleBranches });

      if (!response_zone.length) {
        enqueueSnackbar(
          "Aún no tienes Zonas asignadas, verifica con un administrador",
          { variant: "warning" }
        );
        return;
      }

      const { estado, municipio } = Domicilios[0];
      handleCustomForm("direccion.estado", estado);
      handleCustomForm("direccion.delegacion", municipio);
      handleCustomForm("direccion.cp", value);
      // activity.push(1)

      dispatch({
        type: SET_COLONIES,
        payload: Domicilios.map(({ colonia }) => colonia),
      });

      enqueueSnackbar("Verifica las sucursales disponibles", {
        variant: "info",
      });

      if (isDirection) {
        selectBranch(avalibleBranches[0]);
        setStore(store);
        zona.length = 0;
        shoppingCart.length = 0;
        Orden.length = 0;
        zona.push(Domicilios[0].estado);
        zona.push(response_zone[0].zones[0].Codigo);
        zona.push(response_zone[0].zones[0].Zona);
        setTotales({ subtotal: 0, total: 0 });
        onProductCountChange(0, shoppingCart);
        setTags([]);
        activity.push(1);
      }
    } catch (error) {
      setStore("");
      dispatch({ type: SELECT_BRANCH, payload: null });
      dispatch({ type: SET_AVALAIBLE_BRANCHES, payload: [] });
      handleCustomForm("direccion.estado", "");
      handleCustomForm("direccion.delegacion", "");
      handleCustomForm("direccion.cp", "");

      if (
        error.message ===
        "Cannot read properties of undefined (reading 'zones')"
      ) {
        enqueueSnackbar(
          "No contamos con disponibilidad, intentalo con otro codigo postal ",
          {
            variant: "error",
          }
        );
      } else {
        enqueueSnackbar("Ocurrió un error al buscar la dirección", {
          variant: "error",
        });
      }
    }
  };
  // -------------------------------------------------------------------------

  // Obtener sucursales disponibles al cargar el componente
  // -------------------------------------------------------------------------
  useEffect(() => {
    getBranches(user.uid, localStorage.getItem("token"))
      .then((response) => {
        dispatch({ type: SET_BRANCHES, payload: response });
      })
      .catch(console.log);
  }, []);
  // -------------------------------------------------------------------------

  // -------------------------------------------------------------------------
  /**
   * Función para seleccionar una sucursal.
   * @param {object} branch - Sucursal a seleccionar.
   */
  const selectBranch = (branch) => {
    return dispatch({ type: SELECT_BRANCH, payload: branch });
  };

  const [imagePromotion, setImagePromotion] = useState("");
  const [imageDiscounts, setImageDiscounts] = useState("");
  const [imageDiscountsPublic, setImageDiscountsPublic] = useState("");

  const data = async () => {
    const ref = firebase.db.collection("Franquicias").doc(store);
    const response = await ref.get();

    if (!response.exists) {
      console.error("Document not found");
      return;
    }

    const { imagenPromotion,discounts,discountsPublic } = response.data();
    setImagePromotion(imagenPromotion);
    setImageDiscounts(discounts);
    setImageDiscountsPublic(discountsPublic);

    return;
  };

  useEffect(() => {
    data();
  }, [store]);
  /**
   * @function checkEmailExists
   * @description Verifica si un correo electrónico ya existe en la colección "Invitados".
   * @param {string} correo - Correo electrónico a verificar.
   * @returns {Promise<boolean>} - Verdadero si el correo electrónico existe, falso en caso contrario.
   */
  // -------------------------------------------------------------------------
  const checkEmailExists = async (correo) => {
    try {
      const emailExistsQuery = await firebase.db
        .collection("Invitados")
        .where("DatosPersonales.correo", "==", correo)
        .where("origen", "==", store)
        .limit(1)
        .get();

      return !emailExistsQuery.empty;
    } catch (error) {
      console.error("Error al verificar el correo electrónico:", error);
      throw error;
    }
  };
  // -------------------------------------------------------------------------

  /**
   * Busca servicios asociados a un usuario específico basándose en su referencia.
   * @async
   * @param {string|null} ref - Referencia del usuario.
   * @returns {Promise<Object|null>} Objeto con información sobre los servicios o null si no hay datos.
   */
  const searchServices = async (ref = null) => {
    if (!ref) return 0;
    console.log(ref);
    const response = await firebase.db
      .collection("PedidosAux")
      .where("DatosCliente.refUsuario", "==", ref)
      .get();

    if (response.empty) return null;

    let firstService = null;
    let lastService = null;
    let numberOfServices = 0;

    response.docs.forEach((doc) => {
      const servicesDates = doc.data()?.services_Dates;

      if (servicesDates) {
        // Verificar y actualizar el primer servicio
        for (const type in servicesDates) {
          if (
            servicesDates[type] &&
            (!firstService || servicesDates[type] < firstService)
          ) {
            firstService = servicesDates[type];
          }
        }

        // Verificar y actualizar el último servicio
        for (const type in servicesDates) {
          if (
            servicesDates[type] &&
            (!lastService || servicesDates[type] > lastService)
          ) {
            lastService = servicesDates[type];
          }
        }

        numberOfServices++;
      }
    });

    return {
      numberOfServices,
      lastService: timeStamp_toString(lastService),
      firstService: timeStamp_toString(firstService),
    };
  };
  /**
   * Busca un usuario por ID en Firestore.
   * @param {string} COllECTION - Colección de Firestore a buscar.
   * @param {string} ID - ID del usuario.
   * @returns {Promise<Object|null>} - Datos del usuario o null si no se encuentra.
   */
  const searchUser = async (COllECTION, ID) => {
    const response = await firebase.db.collection(COllECTION).doc(ID).get();

    if (!response.exists) {
      enqueueSnackbar(
        "No pudimos encontrar a este usuario 🫠 , comunícate con soporte",
        { variant: "warning" }
      );
      return null;
    }

    return {
      ...response.data(),
      from: COllECTION,
      id: response.id,
      ref: response.ref,
    };
  };
  /**
   * Selecciona un usuario para su uso en la orden.
   * @param {string} value - Valor del usuario a seleccionar.
   */
  const selectUser = async (value) => {
    const [COllECTION, ID] = `${value}`.split("/");

    // console.log(value);
    const user = await searchUser(COllECTION, ID);

    if (!user) return;

    const numberOfServices = await searchServices(user.ref);

    setPatient({ ...user, ...numberOfServices });
    return;
  };
  /**
   * @function createNewUser
   * @description Crea un nuevo usuario en la colección "Invitados".
   * @param {Object} user - Datos del usuario.
   * @param {Object|null} [create=null] - Referencia para crear.
   * @returns {Promise<Object>} - Referencia del usuario creado.
   */
  // -------------------------------------------------------------------------
  const createNewUser = async (user) => {
    try {
      const newDirection = {
        calle: form["direccion.calle"] || "",
        ciudad: form["direccion.ciudad"] || "",
        cp: form["direccion.cp"] || "",
        delegacion: form["direccion.delegacion"] || "",
        estado: form["direccion.estado"] || "",
        numeroExt: form["direccion.numeroExt"] || "",
        numeroInt: form["direccion.numeroInt"] || "",
        refrencia: form["direccion.referencia"] || "",
        nombre: "Hogar",
      };
      const newUser = {
        correo: user.correo,
        nombre: user.nombre,
        telefono: user.telefono,
      };
      if (!user?.correo) {
        return;
      } else {
        // 1. Verificar si el correo electrónico ya existe
        const emailExists = await checkEmailExists(user?.correo);

        if (emailExists) {
          throw new Error(
            "El correo electrónico ya está registrado.Te recomendamos buscar al paciente para generar tu orden."
          );
        }

        // 2. Consultar el último folio
        const snapshot = await firebase.db
          .collection("Invitados")
          .orderBy("folio", "desc")
          .limit(1)
          .get();

        let lastFolio = 0;
        if (!snapshot.empty) {
          lastFolio = snapshot.docs[0].data().folio;
        }

        // 3. Incrementar el folio para el nuevo usuario
        const newFolio = lastFolio + 1;

        // 4. Crear en nuevo usuario con el folio actualizado
        const reference = firebase.db.collection("Invitados").doc();
        await reference.set({
          DatosPersonales: newUser,
          fechaAlta: new Date(),
          fechaRU: null,
          Direcciones: [newDirection],
          // nombre: "Hogar",
          // Asegúrate de tener Direccion definido
          origen: store,
          folio: newFolio,
          terminos: true,
          token: null,
          usuario: null,
          versionAplicacion: process.env.REACT_APP_VERSION,
          versionBuild: 0,
        });

        return reference;
      }
    } catch (error) {
      console.error("Error al crear el nuevo usuario:", error);
      throw error;
    }
  };
  // -------------------------------------------------------------------------

  // Actualizar el localStorage con el formulario y el carrito de compras
  // -------------------------------------------------------------------------
  useEffect(() => {
    localStorage.setItem(
      "sharedData",
      JSON.stringify({
        ...form,
        shoppingCart,
      })
    );
  }, [form, shoppingCart]);
  // -------------------------------------------------------------------------

  //Funcion para crear la orden
  // -------------------------------------------------------------------------
  const createOrder = async () => {
    try {
      setLoading(true);
      // Eliminar elementos duplicados de la zona
      const Zona = zona.filter(function (elemento, indice) {
        return zona.indexOf(elemento) === indice;
      });
      const responsable = firebase.db
        .collection("Franquicias")
        .doc(store)
        .collection("Personal")
        .doc(firebase.auth.currentUser.uid);

      const origen = firebase.db
        .collection("Franquicias")
        .doc(store)
        .collection("Sucursales")
        .doc(state.branch.id);

      const newUser = {
        correo: form["email"],
        nombre: form["name"],
        refUsuario: form["refUsuario"],
        telefono: form["phone"],
      };
      const newDirection = {
        Zona: Zona,
        calle: form["direccion.calle"],
        ciudad: form["direccion.ciudad"],
        cp: form["direccion.cp"],
        delegacion: form["direccion.delegacion"],
        estado: form["direccion.estado"],
        numeroExt: form["direccion.numeroExt"],
        numeroInt: form["direccion.numeroInt"] || null,
        referencia: form["direccion.referencia"],
      };

      await validateUsuario(newUser);
      await validateDireccion(newDirection);
      await validateOrder(Orden);
      await validatePayment(form["payment"]);
      await validateHorarios(horariosSelected);

      enqueueSnackbar(
        "Procesando tu orden, por favor, no cierres ni recargues la página. ¡Gracias por tu colaboración!",
        {
          variant: "info",
        }
      );

      if (!newUser.refUsuario) {
        const ref = await createNewUser(newUser);
        if (ref) {
          newUser.refUsuario = ref;
          enqueueSnackbar("Se ha creado un nuevo Invitado", {
            variant: "success",
          });
        } else {
          newUser.refUsuario = null;
          newUser.correo = null;
        }
      }

      // Validate if the object contains the specified values
      const horarios = new Object(horariosSelected);
      if (horarios.hasOwnProperty("laboratorio")) {
        if (!horariosSelected.laboratorio) {
          delete horariosSelected.laboratorio;
        }
      }

      if (horarios.hasOwnProperty("radiologia")) {
        if (!horariosSelected.radiologia) {
          delete !horariosSelected.radiologia;
        }
      }
      if (horarios.hasOwnProperty("operador")) {
        if (!horariosSelected.operador) {
          delete !horariosSelected.operador;
        }
      }

      const status = {
        laboratorista: 0,
        radiologo: 0,
        operador: 0,
      };

      const ORDER = {
        Calificacion: { omitir: false },
        DatosCliente: newUser,
        Direccion: newDirection,
        Factura: null,
        Orden: Orden.map(filterFields),
        //===============================
        // Create a Lab Order detail
        Laboratorio: Orden.map(selectLaboratorio)
          .filter(Boolean)
          .map(filterFields),
        //===============================
        // Create a RayosX order detail
        RayosX: Orden.map(selectRadiologia).filter(Boolean).map(filterFields),
        //===============================
        // Create a Services order detail
        Operador: Orden.map(selectOperador).filter(Boolean).map(filterFields),
        //===============================
        codigo_Descuento: null,
        descuento: 0,
        fechaAlta: new Date(),
        franquicia: store,
        laboratorista: null,
        radiologo: null,
        operador: null,
        metodoDePago: form?.payment,
        observaciones: form?.observaciones || null, // revisar estos campos en UI estan invertidos
        responsable: responsable,
        services_Dates: {
          laboratorio: horariosSelected?.laboratorio
            ? horariosSelected?.laboratorio
            : new Date(),
          radiologia: horariosSelected?.radiologia
            ? horariosSelected?.radiologia
            : new Date(),
          operador: horariosSelected?.operador
            ? horariosSelected?.operador
            : new Date(),
        },
        status: status,
        subtotal: totales.subtotal,
        sucursal: state.branch.id,
        origen: origen,
        token: null,
        tokenStripe: null,
        total: totales.total,
      };

      if (!ORDER?.RayosX?.length) {
        delete ORDER.RayosX;
        delete ORDER.radiologo;
        delete status.radiologo;
        delete ORDER.services_Dates.radiologia;
      }
      if (!ORDER?.Laboratorio?.length) {
        delete ORDER.Laboratorio;
        delete ORDER.laboratorista;
        delete status.laboratorista;
        delete ORDER.services_Dates.laboratorio;
      }
      if (!ORDER?.Operador?.length) {
        delete ORDER.Operador;
        delete ORDER.operador;
        delete status.operador;
        delete ORDER.services_Dates.operador;
      }

      const ref = firebase.db.collection("PedidosAux").doc();
      await ref.set(ORDER);

      await new Promise((resolve) => {
        setTimeout(() => {
          sendEmailOrder(
            ref?.id,
            state.branch.name ? "MoviLabs" : "MoviCare"
          ).then(() => resolve());
        }, 2600);
      });
      setLoading(false);

      await ref.collection("HistorialMovimientos").doc("Pedido").set({
        Movimientos: [],
        Modificaciones: [],
        descripcion: "Creación de pedido",
        fechaAlta: new Date(),
        origen: origen,
        responsable: responsable,
      });

      navigate("/order-details/" + ref?.id);

      return ref.id;
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      console.log("ERROR::", error);
    }
  };
  return (
    <OrderContext.Provider
      value={{
        ...state,
        loading,
        onProductCountChange,
        shoppingCart,
        handleChangeForm,
        handleCustomForm,
        searchBranchByPostalCode,
        selectBranch,
        setPatient,
        createOrder,
        setHorarios,
        setActivity,
        activity,
        form,
        totales,
        patient,
        selectUser,
        data,
        imagePromotion,
        imageDiscounts,
        imageDiscountsPublic,
        tags,
        store,
        horariosSelected,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

// Exportar el hook personalizado para consumir el contexto de órdenes
export const useOrder = () => useContext(OrderContext);

// Exportar el componente OrderState
export default OrderState;
