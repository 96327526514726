import React, { useEffect, useState } from "react";
import {
  Grid,
  styled,
  lighten,
  darken,
  Typography,
} from "@mui/material";
import CustomAutocomplete from "../../../CustomAutocomplete";

// const client = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APPLICATION_ID, // ID de la aplicación Algolia
//   process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY // Clave de API de búsqueda de Algolia
// );

const SearchArticles = ({ onSelect, refresh }) => {

  const [results, setResults] = useState([]);


  return (
    <CustomAutocomplete
      label="Seleccionar artículos"
      grid={12}
      width="99%"
      onChange={(value) => setResults(value)}
      getOptionLabel={(option) => option.name}
      options={filterOptions}
      //   filterOptions={filterOptions}
      //   onTextChange={handleChange}

      groupBy={(option) => option.firstLetter}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, value) => (
        <Grid container {...props}>
          <Grid item xs={2} md={2} xl={2}>
            <Typography noWrap>
              <p style={{ fontSize: 11 }}>
                {value?.code}
                <br /> {value?.departament}
              </p>
            </Typography>
          </Grid>
          <Grid container xs={7} md={8} xl={8}>
            <Typography noWrap>
              <p style={design.Alinear}>{value?.name}</p>
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} xl={2}>
            <Typography noWrap>
              <p style={design.price}>
                {value?.price?.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  currencyDisplay: "symbol",
                })}{" "}
              </p>
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  );
};
export default SearchArticles;

// Estilos de diseño
const design = {
  margin: { marginLeft: "1%", marginRight: "1%" },
  txt: { fontWeight: "bold" },
  price: {
    color: "#356c2d",
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 14,
  },
  Alinear: {
    alignItems: "center",
    whiteSpace: "pre-wrap",
    fontWeight: "bold",
    paddingLeft: 10,
  },
};

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
const filterOptions = [
  {
    code: "BH",
    departament: "Laboratorio",
    name: "Biometria Hematica",
    price: 3,
  },
  {
    code: "EGO",
    departament: "Laboratorio",
    name: "Examen General de Orina",
    price: 3,
  },
  {
    code: "QS-27",
    departament: "Laboratorio",
    name: "Quimica Sanguinea 27 elementos",
    price: 3,
  },
];
