/**
 * Tipo de acción para establecer las sucursales disponibles.
 * @constant {string}
 */
export const SET_BRANCHES = "SET_BRANCHES";
/**
 * Tipo de acción para establecer las sucursales disponibles en la zona.
 * Usado para guardar las sucursales filtradas en la zona.
 * @constant {string}
 */
export const SET_AVALAIBLE_BRANCHES = "SET_AVALAIBLE_BRANCHES";
/**
 * Tipo de acción para seleccionar una sucursal.
 * @constant {string}
 */
export const SELECT_BRANCH = "SELECT_BRANCH";
/**
 * Tipo de acción para establecer las colonias.
 * @constant {string}
 */
export const SET_COLONIES = "SET_COLONIES";
