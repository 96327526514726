/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebasev2";
import firebase from "../../../../firebase";
import Personal from "../../Personal";
import { dateInstant } from "../../../../Utils/dateInstant";

const TableHistory = ({ id = null, franquicia }) => {
  const [Movimientos, setMovimientos] = useState([]);
  const [movInicial, setMovimientoInicial] = useState({});

  // Función para obtener el historial de movimientos
  const getHistoryMov = async () => {
    const ref = doc(db, "PedidosAux", id, "/HistorialMovimientos", "/Pedido");
    const response = await getDoc(ref);
 

    if (!response.data()) {
      const uid = firebase.auth.currentUser.uid;
      const responsableRef = doc(
        db,
        "Franquicias",
        franquicia,
        "Personal",
        uid
      );

      await setDoc(ref, {
        Movimientos: [],
        Modificaciones: [],
        descripcion: "Creación de pedido",
        fechaAlta: new Date(),
        origen: "Laboratorio",
        responsableRef,
      });
       window.location.reload();
      return;
    }

    const {
      Movimientos = [],
      descripcion,
      fechaAlta,
      responsable,
    } = response.data();

    // Actualizar el estado con la información obtenida
    setMovimientos(Movimientos);
    setMovimientoInicial({
      descripcion: descripcion,
      fechaAlta: fechaAlta,
      nombre: responsable,
    });
  };

  // Obtener el historial de movimientos al cargar el componente
  useEffect(() => {
    getHistoryMov();
  }, []);

  return (
    <TableContainer style={design.container}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell style={design.cellCenter}>
              <p style={design.txtTitle}>Estatus</p>
            </TableCell>
            <TableCell style={design.cellCenter}>
              <p style={design.txtTitle}>Horario</p>
            </TableCell>
            <TableCell style={design.cellCenter}>
              <p style={design.txtTitle}>Responsable</p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={design.cell}>
              <p style={design.txt}>{movInicial?.descripcion}</p>
            </TableCell>
            <TableCell style={design.cell}>
              <p style={design.txt}>{dateInstant(movInicial?.fechaAlta)}</p>
            </TableCell>
            <TableCell align="center" style={design.cell}>
              <Personal
                responsables={{
                  responsable: movInicial?.nombre || null,
                }}
              />
            </TableCell>
          </TableRow>
          {Movimientos?.map((doc) => {
            const details = doc.status === "Entrega de muestras a laboratorio";
            return (
              <TableRow key={doc.id}>
                <TableCell style={design.cell}>
                  <p style={design.txt}>{doc?.estatus || doc?.status}</p>
                  {details && (
                    <p style={design.txtDes}>
                      Lo recibió {doc?.receptor},{" "}
                      {doc?.observaciones
                        ? doc?.observaciones
                        : "sin observaciones"}
                    </p>
                  )}
                </TableCell>
                <TableCell style={design.cell}>
                  <p style={design.txt}>{dateInstant(doc?.fechaAlta)}</p>
                </TableCell>
                <TableCell style={design.cell}>
                  <Personal
                    responsables={{
                      laboratorista: doc?.laboratorista || null,
                      radiologo: doc?.radiologo || null,
                      administrador: doc?.administrador || null,
                      responsable: doc?.responsable || null,
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableHistory;

// Estilos del componente
const design = {
  container: {
    border: "2px solid rgba(171, 215, 255, 1)",
    borderRight: "2px solid rgba(171, 215, 255, 1)",
    borderRadius: 20,
    maxHeight: 350,
    width: "98%",

  },
  cellCenter: {
    backgroundColor: "rgba(171, 215, 255, 1)",
  },
  txtTitle: {
    color: "rgba(9, 48, 70, 1)",
    textAlign: "center",
    fontWeight: "700",
    fontSize: 15,
  },
  cell: {
    borderBottom: "2px dashed rgba(171, 215, 255, 1)",
  },
  txt: {
    color: "rgba(9, 48, 70, 1)",
    fontSize: 13,
    textAlign: "center",
  },
  txtDes: {
    fontSize: 12,
    color: "#4496D2",
    fontWeight: "bold",
    marginLeft: "5%",
  },
};
