/**
 * Componente estilizado para un botón de color cian.
 * @module CianButton
 * @param {Object} props - Propiedades del componente.
 * @param {string} [props.width="60%"] - Ancho del botón.
 * @param {string} [props.height="66px"] - Button height.
 * @returns {JSX.Element} Elemento JSX que representa el componente CianButton.
 */
import { Button, styled } from "@mui/material";

export const CianButton = styled(Button)(({ width, height }) => ({
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "15px 25px",
  width: width || "60%",
  fontFamily: "Lexend",
  textTransform: "none",
  fontWeight: 500,
  height: height ? height : "66px",
  margin: "2%",
  fontSize: 19,
  border: " 5px solid #4496D2",
  color: "#4496D2",
  borderRadius: "32px",
  textOverflow: "clip",
  whiteSpace: "normal",
  overflow: "hidden",
  lineHeight: 1,
}));
