import { servidor } from "../servidor";
/**
 * Obtiene el token de sesión para un usuario dado.
 * @function
 * @async
 * @param {string} uid - Identificador único del usuario.
 * @throws {Error} Se lanza un error si el usuario no es válido.
 * @throws {string} Se lanza un mensaje de error específico en casos de error no manejado, conexión de servidor no disponible, credenciales no válidas o sesión no autorizada.
 * @returns {Promise<any>} Promesa que se resuelve con el token de sesión.
 * @example
 * // Uso de getSessionToken
 * try {
 *   const token = await getSessionToken(user.uid);
 *   console.log("Token de sesión:", token);
 * } catch (error) {
 *   console.error(error);
 * }
 */
export const getSessionToken = async (uid) => {
  try {
    // console.log('UID PARA GENERAR JWT',uid)
    // Verifica si el usuario es valido
    if (!uid) throw Error("Usuario no valido.");

    return new Promise((resolve, reject) => {
      // Realizar la solicitud al endpoint de login
      servidor
        .get(`/movilabs/login/session/${uid}`)
        .catch((error) => {
           // Manejar errores de la solicitud al servidor
          try {
            if (!error?.response?.status) {
              return reject("Unhandled error");
            }
            if (error?.response?.status === 404) {
              return reject("Server out connection");
            }
            if (error?.response?.status === 500) {
              return reject("Credenciales no válidas.");
            }
          } catch (error) {
            return reject("Sesion no autorizada!");
          }
        })
        .then((response) => {
          // Procesar la respuesta exitosa
          if (response?.status !== 200) {
            return reject("Error");
          }
        
          resolve(response.data);
        });
    });
  } catch (error) {
    // Manejar otros errores
    console.log("Error in getSessionToken.controller",error);
    throw error;
  }
};
