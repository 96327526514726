import React, { useState } from "react";
import { CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import StripedGrid from "../../Components/StripeDataGrid";
import { BorderColor, Loop, SaveAlt, Undo } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

const ViewQuote = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const clickArow = (row) => {
    console.log("ROW Data!", row);
  };

  const columns = [
    {
      field: "np",
      headerName: "Folio",
      width: 120,
      renderHeader: () => <p style={design.headerTable}>Folio</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { np = 0 } = row;
        return "COT-" + np;
      },
    },
    {
      field: "DatosCliente.nombre",
      headerName: "Nombre",
      width: 250,
      renderHeader: () => <p style={design.headerTable}>Nombre</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { nombre = "" } = row.DatosCliente;
        return nombre;
      },
    },
    {
      field: "DatosCliente.correo",
      headerName: "Correo",
      width: 200,
      renderHeader: () => <p style={design.headerTable}>Correo</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { correo = "" } = row.DatosCliente;
        return correo;
      },
    },
    {
      field: "origen",
      headerName: "Origen",
      width: 200,
      renderHeader: () => <p style={design.headerTable}>Origén</p>,
      headerAlign: "center",
    },
    {
      field: "estudios",
      headerName: "estudios",
      width: 125,
      renderHeader: () => <p style={design.headerTable}>N. Estud</p>,
      headerAlign: "center",
    },
    {
      field: "total",
      headerName: "total",
      width: 130,
      renderHeader: () => <p style={design.headerTable}>Total</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        return row.total.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          currencyDisplay: "symbol",
        });
      },
    },
    {
      field: "responsable",
      headerName: "responsable",
      width: 130,
      renderHeader: () => <p style={design.headerTable}>Respons.</p>,
      headerAlign: "center",
    },
    {
      field: "acciones",
      headerName: "acciones",
      width: 210,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Acciones</p>,
      renderCell: () => {
        return ICON.map((doc) => {
          return (
            <Tooltip arrow title={doc.name}>
              <IconButton onClick={doc.rute}>{doc.icon}</IconButton>
            </Tooltip>
          );
        });
      },
    },
  ];

  const ICON = [
    {
      icon: <BorderColor style={{ color: "#893e5e" }} />,
      name: "Editar",
      rute: () => navigate("/create-quote"),
    },
    {
      icon: <Loop style={{ color: "#104976" }} />,
      name: "Convertir",
      rute: () => navigate("/create-order"),
    },
    {
      icon: <Undo style={{ color: "#0A493C" }} />,
      name: "Reenviar",
      rute: () => console.log("Reenviar"),
    },
    {
      icon: <SaveAlt style={{ color: "#0A493C" }} />,
      name: "Descargar",
      rute: () => console.log("Descargar"),
    },
  ];

  const rows = [
    {
      id: 0,
      np: 1000,
      DatosCliente: {
        nombre: "Leonardo Rafael Marcial Herrera",
        correo: "leo.1012.98@gmail.com",
      },
      origen: "San Vicente Chicoloapan",
      estudios: 2,
      total: 1000,
      responsable: "Brenda",
    },
  ];

  if (loading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={25}
      >
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Stack flex={1} direction="column">
      <h1 style={design.title}>Visualizar órdenes</h1>
      <StripedGrid
        columns={columns}
        rows={rows}
        loading={false}
        onRowClick={(row) => clickArow(row.row)}
        pageSize={pageSize}
        oddBackgroundColor="#E6E6E640"
        evenBackgroundColor="#65B32E40"
        oddOpacity={0.7}
        w="95%"
      />
    </Stack>
  );
};

export default ViewQuote;

const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
    textAlign: "center",
  },
};
