import React, { useState } from "react";
import { Grid } from "@mui/material";
import CustomTextField from "../CustomTextField";

const CustomData = () => {
  const [form, setForm] = useState({});

  const handleChangeForm = (e) => {
    const key = e.target?.name;
    const value = e.target?.value;
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div id="SECTION-0">
        <p style={design.title}>Datos de contacto</p>
        <Grid container style={design.Card}>
          <CustomTextField
            label="Nombre del paciente"
            type="text"
            value={form.name}
            grid={12}
            required={true}
            name="name"
            onChange={handleChangeForm}
            maxLength={10}
            InputLabelProps={{ shrink: true }}
          />
          <CustomTextField
            label="Teléfono"
            type="phone"
            value={form.phone}
            grid={6}
            required={true}
            name="phone"
            onChange={handleChangeForm}
            maxLength={10}
            InputLabelProps={{ shrink: true }}
          />
          <CustomTextField
            label="Correo electrónico"
            type="email"
            value={form.email}
            width="96%"
            grid={6}
            required={false}
            name="email"
            onChange={handleChangeForm}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </div>
    </>
  );
};

export default CustomData;

const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
};
