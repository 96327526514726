/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import StripedGrid from "../Components/StripeDataGrid";
import getZonasQuery from "../Apis/getZonesQuery";

/**
 * Componente que muestra las áreas de servicio disponibles y un mapa interactivo.
 * @returns {JSX.Element} Elemento JSX que representa el componente ServiceAreas.
 */
const ServiceAreas = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mapa =
    "https://www.google.com/maps/d/u/0/embed?mid=1qfgGHSLooklN7pJrpGJOgKIWS6MQawk&ehbc=2E312F";
  // Estado para almacenar los datos de las zonas de servicio
  const [dataZones, setDataZones] = useState([]);
  // Estado para almacenar el código postal seleccionado
  const [selectedPostalCode, setSelectedPostalCode] = useState(null);

  /**
   * Función asíncrona para obtener las zonas de servicio.
   * @returns {Promise<void>} Promesa vacía.
   */
  const getZonas = async () => {
    try {
      const token = localStorage.getItem("token");

      // Obtener las zonas de servicio desde la API
      const zonasResponse = await getZonasQuery(token);

      // Asignar un id único a cada objeto en zonasResponse
      const dataWithIds = zonasResponse.map((row, index) => ({
        ...row,
        id: index, // Utilizar el índice como id
      }));

      // Actualizar el estado con las zonas de servicio
      setDataZones(dataWithIds);
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // Obtener las zonas de servicio al cargar el componente
  useEffect(() => {
    getZonas();
  }, []);

  /**
   * Maneja el clic en una fila de la tabla.
   * @param {string} cp Código postal de la fila seleccionada.
   */
  const handleRowClick = (cp) => {
    setSelectedPostalCode(cp);
  };

  return (
    <>
      <Stack flex={1} direction="column">
        {/* Título */}
        <h1 style={design.title}>Zonas disponibles</h1>
        <Grid container>
          {/* Tabla de zonas de servicio */}
          <Grid xs={12} md={6} xl={6}>
            <StripedGrid
              loading={false}
              columns={columns}
              rows={dataZones.map((row) => ({ ...row, id: row.id }))}
              pageSize={9}
              oddBackgroundColor="#d5ddfa"
              evenBackgroundColor="#becaf8"
              oddOpacity={0.7}
              w="95%"
              onRowClick={(row) => handleRowClick(row.cp)}
            />
          </Grid>
          {/* Mapa interactivo */}
          <Grid xs={12} md={6} xl={6}>
            <iframe
              title="Zonas disponibles"
              src={`${mapa}?cp=${selectedPostalCode}`}
              style={design.mapa}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ServiceAreas;

// Estilos del componente
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  mapa: {
    marginLeft: "3%",
    width: "95%",
    height: "400px",
    marginTop: "3%",
  },
};

// Columnas de la tabla de zonas de servicio
const columns = [
  {
    field: "CP",
    headerName: "Código postal",
    width: 180,
    headerstyle: design.headerTable,
  },
  {
    field: "Colonia",
    headerName: "Colonia",
    width: 200,
    headerstyle: design.headerTable,
  },
  {
    field: "Delegacion",
    headerName: "Delegación",
    width: 200,
    headerstyle: design.headerTable,
  },
  {
    field: "Sucursales",
    headerName: "Sucursal",
    width: 200,
    headerstyle: design.headerTable,
  },
];
