import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebasev2";

/**
 * Obtiene la información de un cliente desde Firebase Firestore.
 *
 * @param {string} path - Ruta del documento del cliente en Firestore.
 * @returns {Promise<Object>} - Promesa que se resuelve con los datos del cliente o se rechaza con un error.
 */
export const getClient = async (path) => {
  try {
    // Crea una referencia al documento del cliente en Firestore
    const ref = doc(db, path);

    // Obtiene los datos del documento
    const response = await getDoc(ref);

    // Si el cliente no existe en la base de datos, lanza un error
    if (!response.exists) {
      throw new Error("Usuario no encontrado.");
    }

    // Retorna los datos del cliente junto con el ID y la ruta
    return { ...response.data(), id: response.id, path, refUsuario: response.ref};
  } catch (error) {
    // Maneja errores y lanza una excepción con un mensaje descriptivo
    console.error('Error in getClient:', error);
    throw new Error(`Error al obtener información del cliente: ${error.message}`);
  }
};
