/**
 * Returns an object containing stores.
 * @returns {Object.<string, string>} Object containing store keys and their corresponding values.
 */
export const castStore = () => {
    let stores = {
        X1mFXnBjf48uFNWFB9ow: "Laboratorio Clínico Durán",
        ihnZXN27LSXT1OjDDqM6: "Tu empresa",
        pNtt17TB92KPMuaFAFc1: "CAEMII",
    };
    return stores;
}
