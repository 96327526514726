/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import TableArticle from "../../Components/Ordenes/V2/Details/TableArticle";
import { Box, Grid } from "@mui/material";
import { useDimensions } from "../../hooks/useDimensions";

const ReceiverPage = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const [sharedData, setData] = useState({});
  const [form, setForm] = useState({});

  useEffect(() => {
    const handleStorageChange = () => {
      const DATA = localStorage.getItem("sharedData");
      setData(JSON.parse(DATA) || {});
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  console.log(sharedData.name);
  if (!sharedData.name) {
    return (
      <Box
        width={dimensionWidth}
        height={dimensionHeight - 75}
        component="img"
        src="https://firebasestorage.googleapis.com/v0/b/movicaremx-cbf09.appspot.com/o/promo_LCD_Nov2023.png?alt=media&token=b89a8fad-c493-4f60-ba99-54941c214d1e"
        alt="Logotipo-MoviLabs"
      />
    );
  }
  return (
    <div>
      <p style={design.title}>Nombre: {sharedData?.name}</p>
      {sharedData?.phone && (
        <p style={design.title}>Teléfono: {sharedData?.phone}</p>
      )}
      {sharedData?.email && (
        <p style={design.title}>Correo electrónico: {sharedData?.email}</p>
      )}
      <p style={design.title}>
        Dirección:{" "}
        {sharedData["direccion.calle"] && `${sharedData["direccion.calle"]},`}
        {sharedData["direccion.numeroExt"] &&
          `${sharedData["direccion.numeroExt"]},`}
        {sharedData["direccion.numeroInt"] &&
          `${sharedData["direccion.numeroInt"]},`}
        {sharedData["direccion.cp"] && `${sharedData["direccion.cp"]},`}
        {sharedData["direccion.ciudad"] && `${sharedData["direccion.ciudad"]},`}
        {sharedData["direccion.estado"] && `${sharedData["direccion.estado"]},`}
        {sharedData["direccion.referencia"] &&
          `${sharedData["direccion.referencia"]}.`}
      </p>
      {sharedData.shoppingCart && (
        <>
          <Grid style={design.box}>
            <Grid container style={design.TableTitle}>
              <Grid item xs={5} md={6} xl={6}>
                <p style={design.titleTable}>Estudio</p>
              </Grid>
              <Grid item xs={4} md={3} xl={3} style={design.row}>
                <p style={design.titleTable}>Cantidad</p>
              </Grid>
              <Grid item xs={2} md={2} xl={2}>
                <p style={design.titleTable}>Subtotal</p>
              </Grid>
            </Grid>
            <Grid container style={design.Scroll}>
              <Grid
                container
                style={{
                  alignContent: "baseline",
                }}
              >
                {Object.entries(sharedData?.shoppingCart || {}).map(
                  ([_, doc]) => {
                    const type = _;
                    return (
                      <>
                        <TableArticle
                          name={doc.name}
                          price={doc.price}
                          quantity={doc.cantidad}
                          subtotal={doc.subtotal}
                        />
                      </>
                    );
                  }
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={9} md={9} xl={9} justifyContent="flex-start">
              <p style={design.sub}>Descuento</p>
              <p style={design.sub}>Subtotal</p>
              <p style={design.sub}>Total</p>
            </Grid>
            <Grid item xs={3} md={3} xl={3}>
              <p style={design.totals}>
                {form.Discount ? form.Discount : "0"}%
              </p>
              <p style={design.totals}>
                {form?.Subtotal
                  ? form?.Subtotal?.toLocaleString("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      currencyDisplay: "symbol",
                    })
                  : "$0.00"}
              </p>
              <p style={design.totals}>
                {form?.Totals
                  ? form?.Totals?.toLocaleString("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      currencyDisplay: "symbol",
                    })
                  : "$0.00"}
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ReceiverPage;
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#093046",
    fontSize: 19,
    marginLeft: "5%",
  },
  titleTable: {
    textAlign: "center",
    fontWeight: "600",
    color: "#093046",
    fontSize: 15,
  },
  row: {
    borderLeft: "2px dashed #00518c",
    borderRight: "2px dashed #00518c",
  },
  Scroll: {
    height: 300,
    overflow: "auto",
    alignContent: "",
    textAlign: "center",
  },
  TableTitle: {
    backgroundColor: "#D9EDFF",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  box: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  sub: {
    fontWeight: "700",
    color: "#000",
    fontSize: 13,
    textAlign: "right",
  },
  totals: {
    fontWeight: "500",
    color: "#000",
    fontSize: 13,
    marginLeft: 20,
  },
};
