import { servidor } from "../servidor";
 
/**
 * Obtiene las zonas a través de una consulta a la API.
 *
 * @param {string} token - Token de autenticación.
 * @returns {Array} - Array de zonas obtenidas.
 * @throws {Error} - Se lanza un error si no se proporciona el token o si hay un error en la solicitud.
 */
const getZonasQuery = async (token) => {
  // console.log(token,'token in getZones')
  try {
    // Verifica si se proporciona un token
    if (!token) {
      throw new Error("Se requiere un token para realizar la consulta.");
    }

    // Realiza la consulta a la API para obtener las zonas
    const response = await servidor.get("/movilabs/Zonas", {
      headers: { "x-token": token },
    });

    // Mapea los datos y agrega un ID a cada elemento
    return response.data.map((doc, id) => ({ ...doc, id })) || [];
  } catch (error) {
    // console.error(error.response.message)
    // Mejora: Proporciona mensajes de error más descriptivos
    if (error.response) {
      // La solicitud se hizo, pero el servidor respondió con un código de error
      throw new Error(
        `Error en la solicitud: ${error.response.status} - ${error.response.message}`
      );
    } else if (error.request) {
      // La solicitud se hizo, pero no se recibió respuesta del servidor
      throw new Error(
        "No se recibió respuesta del servidor. Verifica tu conexión a Internet y vuelve a intentarlo."
      );
    } else {
      // Otros errores (por ejemplo, error de JavaScript)
      throw new Error(`Error desconocido: ${error.message}`);
    }
  }
};

export default getZonasQuery;
