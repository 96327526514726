import { servidor } from "../../servidor";
import get_user from "../getUser";
import firebase from "../../firebase";
import { db } from "../../firebase/firebasev2";
import { updateDoc, doc, getDoc } from "firebase/firestore";

/**
 * Cancela un pedido con la posibilidad de cancelar servicios específicos.
 * @param {object} state - El estado del pedido a cancelar.
 * @param {object} ref - La referencia del pedido a cancelar.
 * @param {object} franquicia - La información de la franquicia asociada al pedido.
 * @param {string} franquicia.Franquicia - El nombre de la franquicia.
 * @param {object} pedido - Los detalles del pedido a cancelar.
 * @returns {Promise<boolean>} Una promesa que indica si la cancelación se realizó con éxito.
 */
export const cancelar_Pedido = async (state, ref, franquicia, pedido) => {
  const { motivoOpe,motivoLab, motivoRad, checkedLab, checkedRad, checkedOpe} = state;

  const responsable = await get_user(firebase.auth.currentUser.uid, franquicia);

  try {
    await validatesData(state);

    if (checkedLab) {
      const total = await changeTotal(pedido, "Laboratorio");
      const docChangue = {
        motivo: motivoLab,
        fecha: new Date(),
        responsable,
        amount: total,
      };
      await updateDoc(ref, {
        "Cancelacion.laboratorista": docChangue,
        "status.laboratorista": 100,
      });
    }
    if (checkedRad) {
      const total_Rx = await changeTotal(pedido, "RayosX");
      const docChangue = {
        motivo: motivoRad,
        fecha: new Date(),
        responsable,
        amount: total_Rx,
      };
      await updateDoc(ref, {
        "Cancelacion.radiologo": docChangue,
        "status.radiologo": 100,
      });
    }
    if (checkedOpe) {
      const total_Ope = await changeTotal(pedido, "Operador");
      const docChangue = {
        motivo: motivoOpe,
        fecha: new Date(),
        responsable,
        amount: total_Ope,
      };
      await updateDoc(ref, {
        "Cancelacion.operador": docChangue,
        "status.operador": 100,
      });
    }

    await updateHistorial_Movimientos(
      `Pedido cancelado`,
      ref,
      responsable,
      checkedLab,
      checkedRad,
      checkedOpe,
    );

    servidor.post(`/api/pedidos/Cancelacion/${ref.id}`);

    return true;
  } catch (error) {
    console.log("Error in cancelar_Pedido:",error);
    throw error;
  }
};

/**
 * Valida los datos del estado del pedido antes de proceder con la cancelación.
 * @param {object} state - El estado del pedido a cancelar.
 * @throws {Error} Error si los datos del estado del pedido no son válidos.
 */
const validatesData = async ({
  checkedLab,
  checkedRad,
  checkedOpe,
  motivoRad,
  motivoLab,
  motivoOpe,
}) => {
  try {
    if (!checkedLab && !checkedRad && !checkedOpe) {
      throw new Error("Selecione un servicio para cancelar  ");
    }
    if ((!!checkedLab && !motivoLab) || (!!checkedRad && !motivoRad) || (!!checkedOpe && !motivoOpe)) {
      throw new Error("Se requiere un motivo para generar la cancelación ");
    }
    if (
      (!!checkedLab && motivoLab.length < 15) ||
      (!!checkedRad && motivoRad.length < 15) ||
      (!!checkedOpe && motivoOpe.length < 15)
    ) {
      throw new Error("Intenta ser más descriptivo del motivo de cancelación ");
    }
    return;
  } catch (error) {
    console.log("Error in ValidatesData in cancelar_Pedido:",error);
    throw error;
  }
};
/**
 * Calcula el total restante del pedido según el tipo de servicio.
 * @param {object} pedido - Los detalles del pedido.
 * @param {string} type - El tipo de servicio.
 * @returns {number} El total restante del pedido.
 */
const changeTotal = async (pedido, type) => {
  if (type === "Laboratorio") {
    let totalRest = pedido.Laboratorio.map((e) => e.total).reduce(
      (prev, current) => current + prev,
      0
    );
    return totalRest;
  }
  let totalRest = pedido.RayosX.map((e) => e.total).reduce(
    (prev, current) => current + prev,
    0
  );
  return totalRest;
};

/**
 * Actualiza el historial de movimientos del pedido después de la cancelación.
 * @param {string} estatus - El estado del pedido.
 * @param {object} pedidoRef - La referencia del pedido.
 * @param {object} responsable - El usuario responsable de la cancelación.
 * @param {boolean} laboratorio - Indica si se canceló el servicio de laboratorio.
 * @param {boolean} radiologia - Indica si se canceló el servicio de radiología.
 * @param {boolean} operador - Indica si se canceló el servicio de operador.
 */
const updateHistorial_Movimientos = async (
  estatus,
  pedidoRef,
  responsable,
  laboratorio,
  radiologia,
  operador,
) => {
  const ref = doc(
    db,
    "PedidosAux",
    pedidoRef.id,
    "/HistorialMovimientos",
    "/Pedido"
  );

  const response = await getDoc(ref);

  const { Movimientos = [] } = response.data();

  if (!!laboratorio) {
    Movimientos.push({
      estatus,
      fechaAlta: new Date(),
      responsable,
      descripcion: "Laboratorio",
    });
  }
  if (!!radiologia) {
    Movimientos.push({
      estatus,
      fechaAlta: new Date(),
      responsable,
      descripcion: "RayosX",
    });
  }
  if (!!operador) {
    Movimientos.push({
      estatus,
      fechaAlta: new Date(),
      responsable,
      descripcion: "Operador",
    });
  }
  await updateDoc(ref, { Movimientos });
};
