import { db } from "../../../firebase/firebasev2";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  query,
  getDocs,
  where,
} from "firebase/firestore";

/**
 * Crea un nuevo artículo en la base de datos.
 * @param {Object} item - El artículo a crear.
 * @param {string} [path=""] - La ruta de la colección donde se creará el artículo.
 * @param {string} responsable - El responsable de la creación del artículo.
 * @returns {Promise<string>} Una promesa que resuelve con la ruta del documento creado.
 * @throws {Error} Si no se proporciona una ruta de colección o si hay un problema al crear el artículo.
 */
export const createItem = async (item, path = "", user, store) => {
  try {
    if (!path) throw Error("Se requiere un path de Franquicia");
    const pathResponsable = `Franquicias/${store}/Personal/${user.uid}`;
    const referenceResponsable = doc(db, pathResponsable);

    // Agregar campos adicionales al artículo
    item.available = true;
    item.responsable = referenceResponsable;
    item.fechaAlta = new Date();
    const imageUrl = item.image.img;
    delete item.image;

    let itemFinal = { ...item, image: imageUrl };
    // Agregar el artículo a la colección
    const docRef = await addDoc(collection(db, path), itemFinal);

    // Crear la colección "History" y agregar un documento con el historial de movimientos
    const historyPath = `${path}/${docRef.id}/History/Movements`;
    const historyDocRef = doc(db, historyPath);

    await setDoc(historyDocRef, {
      description: "Creación de Estudio",
      fechaAlta: new Date(),
      responsable: referenceResponsable,
      Movimientos: [],
    });

    return docRef.path;
  } catch (error) {
    console.error("Error al crear el artículo:", error.message);
    throw error;
  }
};

/**
 * Verifica si existe una clave en la base de datos.
 * @param {string} code - La clave a verificar.
 * @param {string} path - La ruta de la colección donde buscar la clave.
 * @returns {Promise<string>} Una promesa que resuelve con un mensaje indicando el resultado de la verificación.
 * @throws {Error} Si hay un problema al buscar la clave en la base de datos.
 */
export const verifyCode = async (code, path) => {
  try {
    const q = query(collection(db, path), where("code", "==", code));
    const resp = await getDocs(q);
    if (!resp.empty) {
      throw new Error("Existe un registro con la misma clave " + code);
    }
    return `Agregando el artículo ${code}`;
  } catch (error) {
    console.error("Error al verificar la clave:", error);
    throw new Error("Tenemos un problema...\n" + error.message || error);
  }
};

/**
 * Verifica los campos del formulario del artículo.
 * @param {Object} form - El formulario del artículo.
 * @returns {Promise<boolean>} Una promesa que resuelve con true si los campos son válidos.
 * @throws {Error} Si hay un problema en la validación de los campos.
 */
export const verifyFields = async (form) => {

  try {
    // Validar cada campo del formulario
    if (!form.departament) {
      throw new Error("Selecciona un departamento para el artículo");
    }
    if (!form.subdepartament) {
      throw new Error("Selecciona un subdepartamento para el artículo");
    }
    if (!form.code) {
      throw new Error("Ingresa una clave para el artículo");
    }
    if (!form.name) {
      throw new Error("Ingresa el nombre del artículo");
    }
    if (!form.image) {
      throw new Error("Selecciona una imagen para el artículo");
    }
    if (!form.cost) {
      throw new Error("Ingresa el costo del artículo");
    }
    if (!form.price) {
      throw new Error("Ingresa el precio del artículo");
    }
    if (
      isNaN(form.price) ||
      isNaN(form.cost) ||
      form.price < 0 ||
      form.cost < 0
    ) {
      throw new Error(
        "Ingresa solo valores numéricos para los campos de precio o costo"
      );
    }
    if (form.price <= form.cost) {
      throw new Error("El precio debe ser mayor al costo");
    }

    if (!form.deliveryTime) {
      throw new Error(
        "Ingresa el tiempo de entrega de resultados para el artículo"
      );
    }
    if (!form.description) {
      throw new Error("Ingresa una descripcion");
    }

    if (form.description && form.description.length < 10) {
      throw new Error(
        "Ingresa al menos 10 caracteres dentro de la descripción"
      );
    }
    if (!form.req) {
      throw new Error("Ingresa los requisitos");
    }
    if (form.req && form.req.length < 10) {
      throw new Error("Ingresa al menos 10 caracteres dentro de requisitos");
    }
    // Si todas las validaciones pasan, retornar true
    return true;
  } catch (error) {
    console.error("Error en la validación de campos:", error.message);
    throw error;
  }
};
