/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Tooltip, Box } from "@mui/material";
import { CssTextField } from "../../CSS/CssTextField";

const Cancelacion = ({ type, onChangeText = () => {}, setState, state }) => {
  const [checkedName, setCheckedName] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    onChangeText();
  }, []);

  const handleChange = (event) => {
    const value = event.target.value;
    setChecked(event.target.checked);
    setCheckedName(value);
    setState({
      ...state,
      checkedLab:
        value === "Laboratorio" ? event.target.checked : state.checkedLab,
      checkedRad: value === "RayosX" ? event.target.checked : state.checkedRad,
      checkedOpe:
        value === "Operador" ? event.target.checked : state.checkedOpe,
    });
  };

  const LABELS_SERVDA = {
    laboratorio:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png",
    radiologia:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Rayos_X.png",
    doctor:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Consultas.png",
    administrador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Call_center.png",
    operador:
      "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
  };

  const createTooltipLabel = (value, imageSrc, tooltipTitle) => (
    <>
      <Tooltip arrow title={tooltipTitle}>
        <Box
          component="img"
          src={imageSrc}
          alt={value}
          width={32}
          height={32}
        />
      </Tooltip>
      {tooltipTitle}
    </>
  );

  const CHECKEDS = {
    laboratorista: (
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ "aria-label": "controlled" }}
            style={{ color: "#65b32e", fontFamily: "Lexend" }}
          />
        }
        label={createTooltipLabel(
          "Laboratorio",
          LABELS_SERVDA.laboratorio,
          "Laboratorio"
        )}
        value={"Laboratorio"}
      />
    ),
    radiologo: (
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ "aria-label": "controlled" }}
            style={{ color: "#65b32e" }}
          />
        }
        label={createTooltipLabel("RayosX", LABELS_SERVDA.radiologia, "RayosX")}
        value="RayosX"
      />
    ),
    operador: (
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ "aria-label": "controlled" }}
            style={{ color: "#65b32e" }}
          />
        }
        label={createTooltipLabel(
          "Operador",
          LABELS_SERVDA.operador,
          "Operador"
        )}
        value="Operador"
      />
    ),
    administrador: null,
  };

  const LABELS = {
    true: (
      <CssTextField
        label={`Motivo de cancelacion ${checkedName}`}
        name="Motivo de cancelación"
        title="Motivo de cancelación"
        type="text"
        variant="outlined"
        required
        multiline
        value={
          checkedName === "Laboratorio"? state.motivoLab : checkedName === "RayosX"? state.motivoRad: state.motivoOpe
        }
        minRows={4}
        onChange={({ target: { value } }) => {
          
          if (checkedName === "Laboratorio") {
            setState({
              ...state,
              motivoLab: value,
            });
          }
          if (checkedName === "RayosX" ) {
            setState({
              ...state,
              motivoRad: value,
            });
          }
          if (checkedName === "Operador" ) {
            setState({
              ...state,
              motivoOpe: value,
            });
          }
        }}
      />
    ),
    false: null,
  };


  return (
    <>
      {CHECKEDS[type]}
      {LABELS[checked]}
    </>
  );
};

export default Cancelacion;
