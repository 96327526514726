/* eslint-disable no-unused-vars */
/**
 * Componente para gestionar el método de pago en un formulario de pedido.
 * @module CustomPayment
 */
import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import CustomAutocomplete from "../../../CustomAutocomplete";
import CustomTextField from "../../../CustomTextField";

const CustomPayment = ({ activity }) => {
  const updatePayment = (e) => {
    //   handleCustomForm("payment", e?.label ?? "");
    activity.push(3);
  };
  const [form, setForm] = useState({});

  return (
    <div id="SECTION-3">
      <p style={design.title}>4. Método de pago</p>
      <Grid container style={design.Card}>
        <Grid item xs={12} md={6} xl={6}>
          <CustomAutocomplete
            onChange={updatePayment}
            name="payment"
            value={form?.payment}
            label="Tipo de pago"
            options={metodos}
            grid={12}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <li {...props}>
                <Box
                  component="img"
                  src={option.icon}
                  alt={option.label}
                  width="5%"
                  marginRight={"2%"}
                />
                {option.label}
              </li>
            )}
          />
        </Grid>
        <Grid xs={12} md={6} xl={6}>
          <CustomTextField
            label="Observaciones"
            type="text"
            name="observaciones"
            value={form?.observaciones}
            // onChange={handleChangeForm}
            grid={12}
            required={false}
            multiline={5}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomPayment;

// Estilos del componente
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  From: {
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 18,
  },
};

// Opciones para el tipo de pago
const metodos = [
  {
    icon: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Efectivo.png",
    label: "Efectivo",
    id: 1,
  },
  {
    icon: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Transferencia.png",
    label: "Transferencia",
    id: 2,
  },
  {
    icon: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/icono_tarjeta.png",
    label: "Tarjeta de débito",
    id: 3,
  },
  {
    icon: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Credito.png",
    label: "Tarjeta de crédito",
    id: 4,
  },
];
