/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Personal from "../../Components/Ordenes/Personal";
import Dates from "../../Components/Ordenes/Dates";
import StripedGrid from "../../Components/StripeDataGrid";
import Origin from "../../Components/Ordenes/Origin";
import StatusXStaff from "../../Components/Ordenes/StatusXStaff";
import { CircularProgress, Stack } from "@mui/material";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebasev2";
import { validateToken } from "../../Apis/validateToken";
import timeStamp_toString from "../../Utils/timeStamp_toString";
import getQuery from "../../Controllers/Ordenes/getQuerysArchive/getQuery";
import { castStatus } from "../../Utils/castStatus";

const RESPONSABLES = {
  laboratorista: "Laboratorista",
  radiologo: "Radiologo",
  administrador: "Atencion a clientes",
  operador: "Operador",
};

const Archive = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { token, user } = useAuth();
  const [loading, setLoading] = useState(true);
  let franquicia = null;
  let sucursal = null;

  const getPedidos = async () => {
    setLoading(true);
    if (!token && !user?.uid) return [];

    const response = await validateToken(token, user?.uid);

    if (typeof response != "object") {
      const response = await validateToken(token, user.uid);
      return response;
    }

    franquicia = response.store;
    sucursal = response.branch;

    const query = getQuery("laboratorista", franquicia, sucursal);
    const query1 = getQuery("radiologo", franquicia, sucursal);
    const query2 = getQuery("administrador", franquicia, sucursal);
    const query3 = getQuery("operador", franquicia, sucursal);

    const array = await constructData(query);
    const array1 = await constructData(query1);
    const array2 = await constructData(query2);
    const array3 = await constructData(query3);

    let fixPedidos = [...array, ...array1, ...array2, ...array3];

    const orders = fixPedidos.filter((obj, index) => {
      return index === fixPedidos.findIndex((o) => obj.np === o.np);
    });

    const ordenes = orders.sort((a, b) => b.np - a.np);

    setLoading(false);
    setData(ordenes);
    return ordenes;
  };

  useEffect(() => {
    getPedidos();
  }, [token, user]);

  const constructData = async (query) => {
    const array = await getDocs(query);
    const orderPromises = array.docs.map(async (doc) => {
      const [origin, numBoletas] = await Promise.all([
        getOrigen(doc.data().origen, doc.data().franquicia),
        getBoletas(doc.ref),
      ]);

      return {
        ...doc.data(),
        id: doc.id,
        aux: ` PED-${doc.data().np} | ${doc.data().DatosCliente.nombre} | ${
          doc.data().DatosCliente.correo
        }`,
        // Actualiza las propiedades del objeto doc con los resultados obtenidos
        origin: origin,
        numBoletas: numBoletas,
        ref: doc.ref,
      };
    });
    return await Promise.all(orderPromises);
  };

  const getOrigen = async (origen, franquicia) => {
    if (typeof origen === "string") {
      return `MoviCare-${origen}`;
    } else {
      const getOrigen = await getDoc(origen);
      if (!getOrigen.exists) {
        throw new Error("No fue posible obtener el origen.");
      }
      const reference = doc(db, "Franquicias", franquicia);
      const getReference = await getDoc(reference);
      const dataFranquicia = getReference.data();
      const data = getOrigen.data();
      if (!dataFranquicia?.Nombre || !data?.Nombre) {
        return "Sin Origen";
      }
      return dataFranquicia?.Nombre + "-" + data?.Nombre;
    }
  };

  const getBoletas = async ({ path = "" }) => {
    const ref = collection(db, path, "/Boletas");
    const response = await getDocs(ref);
    return response.size;
  };

  const clickArow = (row) => {
    navigate("/order-details/" + row.id);
  };

  const columns = [
    {
      field: "np",
      headerName: "Folio",
      width: 180,
      renderHeader: () => <p style={design.headerTable}>Folio</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { np = 0 } = row;
        return "PED-" + np;
      },
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 250,
      renderHeader: () => <p style={design.headerTable}>Nombre</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { nombre = "" } = row.DatosCliente;
        return nombre.toUpperCase();
      },
    },
    {
      field: "services_Dates",
      headerName: "Fecha de Servicio",
      width: 300,
      valueFormatter: ({ row }) => {
        if (row?.services_Dates?.laboratorio) {
          return `${"Laboratorio:"} ${timeStamp_toString(
            row?.services_Dates?.laboratorio
          )}`;
        }
        if (row.services_Dates?.radiologia) {
          return `${"RayosX:"} ${timeStamp_toString(
            row?.services_Dates?.radiologia
          )}`;
        }
        if (row.services_Dates?.operador) {
          return `${"Servicios:"} ${timeStamp_toString(
            row?.services_Dates?.operador
          )}`;
        }
        return `${"Laboratorio:"} ${timeStamp_toString(row?.fechaServicio)}`;
      },

      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Fecha de servicio</p>,
      renderCell: ({ row }) => {
        return (
          <>
            <div style={design.columnList}>
              <Dates
                widthIMG={25}
                fecha={row.services_Dates}
                fechaServicio={row.fechaServicio}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "direccion",
      headerName: "Dirección de servicio",
      width: 250,
      headerAlign: "center",
      renderHeader: () => (
        <p style={design.headerTable}>Dirección de servicio</p>
      ),
      valueFormatter: ({ row }) => {
        const {
          Direccion: { ciudad, delegacion, estado },
        } = row;
        return `${ciudad + "," || ""}${delegacion},${estado}`;
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Total</p>,
      valueFormatter: ({ row }) => {
        const { total } = row;
        return `${total.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          currencyDisplay: "symbol",
        })}`;
      },
    },
    {
      field: "metodoDePago",
      headerName: "Método de pago",
      width: 200,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Método de pago</p>,
      valueFormatter: ({ row }) => {
        const { metodoDePago } = row;
        return `${metodoDePago}`;
      },
    },
    {
      field: "pacients",
      headerName: "#Pacientes", // Numero total de boletas dentro del pedido
      width: 180,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Pacientes</p>,
      valueFormatter: ({ row }) => row.numBoletas,
      renderCell: ({ row }) => {
        if (row.numBoletas) {
          return row.numBoletas;
        }
      },
    },
    {
      field: "services",
      headerName: "# Servicios", // Numero total de servicios LAB + RX + {...}
      width: 150,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Servicios</p>,
      valueFormatter: ({ row }) => {
        const rayosX = row?.rayosX;
        const laboratorio = row?.Laboratorio;
        const laboratorioOld = row?.Orden;
        const operador = row?.Operador;
        let total = 0;
        if (laboratorio) {
          const x = laboratorio
            .map((e) => e.cantidad)
            .reduce((prev, current) => prev + current, 0);
          total = total + x;
        }
        if (rayosX) {
          const y = rayosX
            .map((e) => e.cantidad)
            .reduce((prev, current) => prev + current, 0);
          total = total + y;
        }
        if (operador) {
          const y = operador
            .map((e) => e.cantidad)
            .reduce((prev, current) => prev + current, 0);
          total = total + y;
        }

        if (laboratorioOld) {
          const total = laboratorioOld
            .map((e) => e.cantidad)
            .reduce((prev, current) => prev + current, 0);
          return total;
        }
        return total;
      },
    },
    {
      field: "origen",
      headerName: "Origen",
      width: 180,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Origen</p>,
      renderCell: ({ row }) => (
        <Origin
          franquicia={row?.franquicia}
          sucursal={row?.sucursal}
          origen={row?.origen}
        />
      ),
      valueFormatter: ({ row }) => {
        return row.origin;
      },
    },
    {
      field: "responsable",
      headerName: "Personal",
      width: 200,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Personal</p>,
      renderCell: ({ row }) => (
        <>
          <div style={design.columnList}>
            <Personal
              status={row.status}
              responsables={{
                laboratorista: row.laboratorista || null,
                radiologo: row.radiologo || null,
                administrador: row.administrador || null,
                responsable: row.responsable || null,
                operador: row.operador || null,
              }}
            />
          </div>
        </>
      ),
    },
    {
      field: "status",
      headerName: "Estatus",
      width: 280,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { status, estatus } = row;

        if (status) {
          const fecha = Object.entries(status).map(([key, value]) => {
            return `${RESPONSABLES[key]}` + ":" + `${castStatus(value)}`;
          });
          return fecha.join("  ");
        }
        return "Laboratorio: " + castStatus(estatus);
      },
      renderHeader: () => <p style={design.headerTable}>Estatus</p>,
      renderCell: ({ row }) => {
        if (!row.status) {
          return (
            <>
              <StatusXStaff estatus={row.estatus} width={30} showName={false} />
            </>
          );
        }
        return (
          <div style={design.columnList}>
            <StatusXStaff width={25} status={row.status} />
          </div>
        );
      },
    },
  ];
  if (loading && !data.length) {
    return (
      <>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={25}
        >
          <CircularProgress />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Stack flex={1} direction="column">
        <h1 style={design.title}>Archivo</h1>

        <StripedGrid
          loading={false}
          columns={columns}
          rows={data}
          onRowClick={(row) => clickArow(row.row)}
          evenBackgroundColor="#65B32E40"
          oddBackgroundColor="#65B32E40"
          pageSize={15}
          oddOpacity={0.7}
          w="93%"
        />
      </Stack>
    </>
  );
};

export default Archive;

const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
  },
  columnList: {
    whiteSpace: "pre-wrap",
    margin: 10,
    lineHeight: "1.5%",
  },
  columnListEstatus: {
    whiteSpace: "pre-wrap",
    // margin: 10,
    lineHeight: "1.5",
  },
};
