import app from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/database";

import firebaseConfig from "./config";

/**
 * Clase que representa la instancia de Firebase.
 */
class Firebase {
  /**
   * Constructor de la clase Firebase.
   */
  constructor() {
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.database = app.database();
    this.storage = app.storage();
    this.auth = app.auth();
  }

  /**
   * Método para inicializar Firebase con una URL de base de datos específica.
   */
  async init() {
    let url = await localStorage.getItem("@db");
    firebaseConfig.databaseURL = url;
  }
}

const firebase = new Firebase();
export default firebase;
