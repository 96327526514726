
import React, { useState } from "react";
import { Chip, Grid } from "@mui/material";
import CustomTextField from "../../../CustomTextField";
import CustomAutocomplete from "../../../CustomAutocomplete";

const CustomAddress = ({ widthTelefono, activity }) => {
  const [select, setSelect] = useState();
  const [form, setForm] = useState({});

  const handleChangeForm = (e) => {
    const key = e.target?.name;
    const value = e.target?.value;
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "direccion.referencia") {
      activity.push(1);
    }
  };

  const handleDirecction = async (index, doc) => {
    //Funcion para verificar la sucursal
    // await searchBranchByPostalCode(doc.cp, isDireccion);
    // //Llenado de formulario
    // handleCustomForm("direccion.calle", doc?.calle ?? "");
    // handleCustomForm("direccion.numeroExt", doc?.numeroExt ?? "");
    // handleCustomForm("direccion.numeroInt", doc?.numeroInt ?? "");
    // handleCustomForm("direccion.referencia", doc?.referencia ?? "");
    // handleCustomForm("direccion.ciudad", doc?.ciudad ?? "");
    // handleCustomForm("direccion.cp", doc?.cp ?? "");
    setSelect(index);

    console.log("Direccion", doc);
  };

  return (
    <div id="SECTION-1">
      <Grid container>
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12}>
          <p style={design.title}>2. Dirección</p>
        </Grid>
      </Grid>
      <Grid container style={design.Card}>
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12} textAlign="center">
          {Directions.map((doc, index) => {
            return (
              <Chip
                label={doc.name}
                onClick={() => handleDirecction(index, doc)}
                style={{
                  backgroundColor: select === index ? "#00518c" : null,
                  color: select === index ? "#FFF" : "#00518c",
                  ...design.chips,
                }}
              />
            );
          })}
        </Grid>
        <CustomTextField
          label="Código postal"
          type="text"
          name="direccion.cp"
          value={form["direccion.cp"]}
          onChange={handleChangeForm}
          grid={6}
          required={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomAutocomplete
          onChange={(value) => handleChangeForm("direccion.ciudad", value)}
          label="Colonia"
          getOptionLabel={(option) => option}
          options={colonies}
          value={form["direccion.ciudad"]}
          grid={6}
        />
        <CustomTextField
          label="Delegación/Municipio"
          type="text"
          name="direccion.delegacion"
          value={form["direccion.delegacion"]}
          onChange={handleChangeForm}
          grid={6}
          required={false}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Estado"
          type="text"
          name="direccion.estado"
          value={form["direccion.estado"]}
          onChange={handleChangeForm}
          grid={6}
          required={false}
          disabled={true}
          width="96%"
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Calle"
          type="text"
          width="98%"
          name="direccion.calle"
          value={form["direccion.calle"]}
          onChange={handleChangeForm}
          grid={12}
          required={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Num. Ext."
          type="text"
          name="direccion.numeroExt"
          value={form["direccion.numeroExt"]}
          onChange={handleChangeForm}
          grid={6}
          required={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Num. Int."
          type="text"
          width="96%"
          name="direccion.numeroInt"
          value={form["direccion.numeroInt"]}
          onChange={handleChangeForm}
          grid={6}
          required={false}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Referencia"
          type="text"
          name="direccion.referencia"
          value={form["direccion.referencia"]}
          onChange={handleChangeForm}
          grid={12}
          required={true}
          multiline={5}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </div>
  );
};

export default CustomAddress;

const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  chips: {
    border: "2px solid #00518c",
    borderRadius: 20,
    marginLeft: 10,
  },
};
const Directions = [{ name: "Casa" }, { name: "Trabajo" }];
const colonies = ["Colonia 1", "Colonia 2"];
