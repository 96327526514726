import { useEffect, useState } from "react";
import firebase from "../firebase";
import { castBranche } from "../Utils/castBranche";

/**
 * Hook personalizado para contar pedidos mensuales por sucursal.
 * @param {string} store - El nombre de la sucursal.
 * @returns {Object} - Un objeto que contiene el recuento de pedidos por sucursal.
 */
const useOrderCounterMonthlyBefore = (store) => {
  const [pedidosPorSucursal, setPedidosPorSucursal] = useState({});

  useEffect(() => {
    // Obtener la referencia a la colección 'PedidosAux'
    const pedidosRef = firebase.db.collection("PedidosAux");

    /**
     * Función para obtener la fecha límite del mes anterior.
     * @returns {Date} - La fecha límite del mes anterior.
     */
    const obtenerFechaLimite = () => {
      const fechaActual = new Date();
      fechaActual.setDate(0); // Establecer el último día del mes anterior
      fechaActual.setHours(0, 0, 0, 0); // Establecer la hora, los minutos, los segundos y los milisegundos a 0
      return fechaActual;
    };

    /**
     * Función para contar los pedidos mensuales por sucursal.
     */
    const contarPedidos = () => {
      const fechaLimite = obtenerFechaLimite();
      const fechaInicioMesAnterior = new Date(fechaLimite.getFullYear(), fechaLimite.getMonth(), 1, 0, 0, 0, 0);
      
      pedidosRef
        .where("franquicia", "==", `${store}`)
        .where("fechaAlta", ">=", fechaInicioMesAnterior)
        .where("fechaAlta", "<", fechaLimite)
        .get()
        .then((snapshot) => {
          const pedidosTotalesPorSucursal = {};

          snapshot.forEach((doc) => {
            const pedidoData = doc.data(); //Datos de los pedidos
            const sucursalId = `${castBranche()[pedidoData.sucursal]}`; //Obtener el id de la sucursal, casteando el nombre

            if (pedidosTotalesPorSucursal[sucursalId]) {
              pedidosTotalesPorSucursal[sucursalId]++;
            } else {
              pedidosTotalesPorSucursal[sucursalId] = 1;
            }
          });

          setPedidosPorSucursal(pedidosTotalesPorSucursal);
        });
    };

    // Llamar a la función de conteo al cargar el hook y cada vez que haya cambios en la colección
    contarPedidos();
    const unsubscribe = pedidosRef.onSnapshot(contarPedidos);

    // Limpiar la suscripción al desmontar el componente
    return () => unsubscribe();
  }, [store]);

  return pedidosPorSucursal;
};

export default useOrderCounterMonthlyBefore;
