
import { servidor } from "../servidor";

/**
 * Obtiene las sucursales asociadas a un usuario.
 *
 * @param {string} uid - ID de usuario.
 * @param {string} token - Token de autenticación.
 * @returns {Promise<Array>} - Promesa que se resuelve con un array de sucursales o se rechaza con un error.
 */
export const getBranches = async (uid, token) => {

  try {
    const response = await servidor.get("/movilabs/store/branches/", {
      headers: {
        uid: uid,
        token: token,
      },
    });

    const branches = response.data.branches || [];
    return branches;

  } catch (error) {
    // Mejora: Proporciona mensajes de error más descriptivos
    if (error.response) {
      // El servidor respondió con un código de error
      throw new Error(`Error al obtener sucursales: ${error.response.status}`);
    } else if (error.request) {
      // La solicitud se hizo pero no se recibió respuesta
      throw new Error("No se pudo conectar al servidor. Por favor, verifica tu conexión a Internet.");
    } else {
      // Otros errores
      throw new Error(`Error desconocido: ${error.message}`);
    }
  }
};
