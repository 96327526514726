import axios from "axios";
// import https from "https";
/**
 * Agente HTTPS con configuración para permitir solicitudes sin verificación de certificado.
 * @type {import("https").Agent}
 */
// export const agent = new https.Agent({
//   rejectUnauthorized: false,
// });

export const servidor = axios.create({
  baseURL: 
    process.env.NODE_ENV === "production"
      ? "https://movicare-server.herokuapp.com"
      : "http://192.168.1.236:8080",
  timeout: 60000,
});
