/**
 * Componente para gestionar el método de pago en un formulario de pedido.
 * @module CustomPayment
 */
import React from "react";
import { Grid, Box } from "@mui/material";
import CustomTextField from "../../CustomTextField";
import CustomAutocomplete from "../../CustomAutocomplete";
import { useOrder } from "../../../Context/Ordenes_v2/Order.context";

/**
 * Componente funcional para gestionar el método de pago en un formulario de pedido.
 * @returns {JSX.Element} Elemento JSX que representa el componente CustomPayment.
 */
const CustomPayment = () => {
  // Obtiene el estado del formulario y la función para cambiarlo desde el contexto de pedidos
  const { form, handleChangeForm, handleCustomForm, activity } = useOrder();
 
  const updatePayment = (e) =>{
    handleCustomForm("payment", e?.label ?? "");
    activity.push(3)
  }
  
  return (
    <div id="SECTION-3">
      <p style={design.title}>4. Método de pago</p>
      {/* Contenedor del formulario de método de pago */}
      <Grid container style={design.Card}>
        <Grid item xs={12} md={6} xl={6}>
          <CustomAutocomplete
            onChange={updatePayment}
            name="payment"
            value={form?.payment}
            label="Tipo de pago"
            options={metodos}
            grid={12}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <p {...props}>
                {option.icon} &nbsp;&nbsp;
                {option.label}
              </p>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          {/* Campo de texto para agregar observaciones */}
          <CustomTextField
            label="Observaciones"
            type="text"
            name="observaciones"
            value={form?.observaciones}
            onChange={handleChangeForm}
            grid={12}
            required={false}
            multiline={5}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomPayment;

// Estilos del componente
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  From: {
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 18,
  },
};

// Opciones para el tipo de pago
const metodos = [
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Efectivo.png"
        alt="Efectivo"
        width={30}
        height={30}
      />
    ),
    label: "Efectivo",
    id: 1,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Transferencia.png"
        alt="Transferencia"
        width={30}
        height={30}
      />
    ),
    label: "Transferencia",
    id: 2,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/icono_tarjeta.png"
        alt="Tarjeta de débito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de débito",
    id: 3,
  },
  {
    icon: (
      <Box
        component="img"
        src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Payments/Credito.png"
        alt="Tarjeta de crédito"
        width={30}
        height={30}
      />
    ),
    label: "Tarjeta de crédito",
    id: 4,
  },
  
];
