import React from "react";
import { Grid } from "@mui/material";
import { useOrder } from "../../../Context/Ordenes_v2/Order.context";
import { DarkGreenButton } from "../../../CSS/Contained/DarkGreenButton";
import CostomTableCrear from "./CostumTableCrear";

/**
 * TicketOrden component displays order details along with a table of items and a button to schedule.
 * @param {object} props - Component props.
 * @param {number} props.windowHeight - Height of the window.
 * @param {boolean} props.widthTelefono - Flag indicating if the width is for a phone.
 * @returns {JSX.Element} React component.
 */
const TicketOrden = ({ windowHeight, widthTelefono }) => {
  const {
    form,
    shoppingCart,
    onProductCountChange,
    totales,
    createOrder,
    loading,
    store,
    branch,
  } = useOrder();

  return (
    <>
      <Grid style={design.ticket}>
        <p style={design.title}>
          <b>Nombre: </b>
          {form?.name}
        </p>
        <p style={design.title}>
          <b>Correo electrónico: </b>
          {form?.email && form?.email}
        </p>
        <p style={design.title}>
          <b>Teléfono: </b>
          {form?.phone && form?.phone}
        </p>
        <p style={design.title}>
          <b>Dirección: </b>
          {form["direccion.calle"] && `${form["direccion.calle"]},`}
          {form["direccion.numeroExt"] && `${form["direccion.numeroExt"]},`}
          {form["direccion.numeroInt"] && `${form["direccion.numeroInt"]},`}
          {form["direccion.cp"] && `${form["direccion.cp"]},`}
          {form["direccion.ciudad"] && `${form["direccion.ciudad"]},`}
          {form["direccion.estado"] && `${form["direccion.estado"]}.`}
        </p>
        <p style={design.title}>
          <b>Referencias: </b>
          {form["direccion.referencia"] && `${form["direccion.referencia"]}.`}
        </p>

        {/* Tabla de articulos */}
        <CostomTableCrear
          botomDisable={false}
          order={shoppingCart}
          onProductCountChange={onProductCountChange}
          totales={totales}
          store={store}
          branch={branch}
          payment={form.payment}
        />
        <DarkGreenButton
          disabled={loading}
          onClick={() => {
            createOrder();
          }}
          width={"90%"}
        >
          Agendar
        </DarkGreenButton>
      </Grid>
    </>
  );
};
export default TicketOrden;

const design = {
  ticket: {
    padding: 20,
  },
  title: {
    textAlign: "left",
    // fontWeight: "600",
    color: "#093046",
    fontSize: 17,
  },
};
