import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const TableIntakeMedicines = ({ array }) => {
  return (
    <Grid item xs={3} md={3} xl={3} lg={3}>
      <TableContainer style={design.container}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} style={design.TableLeft}>
                Ingesta
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array.map((doc, index) => (
              <TableRow key={index} >
                <TableCell style={design.row}>
                  <p style={design.txt}>{doc}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const design = {
  container: {
    border: "2px solid rgba(171, 215, 255, 1)",
    borderRight: "2px solid rgba(171, 215, 255, 1)",
    borderRadius: 20,
    maxHeight: 350,
    margin:10
  },
  TableLeft: {
    backgroundColor: "rgba(171, 215, 255, 1)",
    fontFamily: "Lexend",
    textAlign: "center",
    fontWeight: "500",
  },
  row: {
    justifyContent: "center",
    borderBottom: "2px dashed rgba(171, 215, 255, 1)",
    fontFamily: "Lexend",
  },
  txt: {
    color: "rgba(9, 48, 70, 1)",
    fontSize: 14,
    textAlign: "center",
  },
};

export default TableIntakeMedicines;
