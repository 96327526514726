// Importación de la configuración del servidor desde api.config
import { servidor } from "../servidor";

/**
 * Valida el token y el UID antes de realizar la solicitud al servidor.
 *
 * @param {string} _ - No se utiliza, solo para demostración.
 * @param {string} uid - ID de usuario.
 * @returns {Promise<any>} - Promesa que se resuelve con los datos de la respuesta del servidor o se rechaza con un error.
 */
export const validateToken = async (_, uid) => {
  let attempts = 0;

  /**
   * Función interna que realiza la validación del token y UID.
   * @returns {Promise<any>} - Promesa que se resuelve con los datos de la respuesta del servidor o se rechaza con un error.
   */
  const validate = async () => {
    try {
      // Obtiene el token almacenado en el localStorage
      const token = await localStorage.getItem("token");

      // Verifica la existencia de token y UID
      if (!token || !uid) {
        throw new Error("No se encontró Token ni UID");
      }

      const response = await servidor.get("/movilabs/validateCredentials", {
        headers: { token, uid },
      });

      // Valida si la respuesta del servidor no es 200
      if (response?.status !== 200) {
        throw new Error("Error en la respuesta del servidor");
      }

      return response.data;
    } catch (error) {
      // Manejo de errores centralizado
      handleServerError(error);

      // Reintentar hasta 3 veces
      if (attempts < 3) {
        attempts++;
        console.log(`Reintentando validación. Intento ${attempts}`);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return validate();
      } else {
        throw new Error(`Se agotaron los intentos. ${error.message}`);
      }
    }
  };

  // Invoca la función interna de validación y retorna la promesa resultante
  return validate();
};

/**
 * Función para manejar los diferentes tipos de errores de la validación del token.
 * @param {Error} error - Error a manejar.
 */
const handleServerError = (error) => {
  if (error.response) {
    const statusCode = error.response.status;

    if (statusCode === 401) {
      throw new Error("Token no válido. Inicia sesión nuevamente.");
    } else {
      throw new Error(`Error en la solicitud: ${statusCode}`);
    }
  } else if (error.request) {
    throw new Error(`No se recibió respuesta del servidor. ${error.request.message}`);
  } else {
    throw new Error(`Error desconocido al validar el token. ${error.message}`);
  }
};
