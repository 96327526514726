import firebase from './firebase';
import AuthContext from '../Context/Auth/authContext';

/**
 * Exporta el contexto de autenticación.
 * @type {Object}
 */
export { AuthContext };

/**
 * Exporta el módulo de Firebase por defecto.
 * @type {Object}
 */
export default firebase;
