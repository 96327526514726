import React, { useState } from "react";
import { Alert, Grid, InputAdornment } from "@mui/material";
import CustomTextField from "../CustomTextField";
import SearchArticles from "./SearchArticles";
// import CustomTextField from "../../../CustomTextField";
// import SearchArticles from "./SearchArticles";

const CustomStudies = ({ activity }) => {
  const [form, setForm] = useState({});
  const [refresh, setRefresh] = useState(true);

  return (
    <div id="SECTION-2" style={design.margin}>
      <Grid container>
        <Grid xs={12} md={3} xl={3}>
          <p style={design.title}>Estudios</p>
        </Grid>
        <Grid xs={12} md={9} xl={9}>
          <Alert severity="info" variant="outlined" style={design.alert}>
            Recuerda que el descuento máximo es de 30%
          </Alert>
        </Grid>
      </Grid>
      <Grid container style={design.border}>
        <SearchArticles />
        <Item
          form={form}
          setForm={setForm}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      </Grid>
    </div>
  );
};

const Item = ({ form, setForm, setRefresh, refresh }) => {
  return (
    <>
      <CustomTextField
        label="Cantidad"
        type="number"
        name="amount"
        value={form.counter}
        grid={6}
        required={false}
        onChange={(e) => {
          if (e.target.value < 0) return 0;
        }}
        onKeyDown={(e) => {
          if (e.code.toUpperCase().includes("ENTER")) {
          }
        }}
      />
      <CustomTextField
        label="Precio"
        type="number"
        name="price"
        disabled={true}
        value={form?.price} // valor del producto predeterminado
        grid={6}
        required={false}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
      <CustomTextField
        label="Descuento sobre artículo"
        type="number"
        name="discount"
        InputLabelProps={{ shrink: true }}
        value={form.discount}
        grid={6}
        onChange={(e) => {
          if (e.target.value < 0) return 0;
          if (e.target.value > 30) return 0;
        }}
        required={false}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        onKeyPress={(e) => {
          if (e.code.toUpperCase().includes("ENTER")) {
          }
        }}
      />
      <CustomTextField
        label="Subtotal"
        type="number"
        name="subtotal"
        disabled={true}
        grid={6}
        value={form.subtotalProduct}
        required={false}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </>
  );
};

export default CustomStudies;

const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 28,
    lineHeight: "20px",
  },
  border: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  alert: {
    width: "87%",
    fontSize: 12,
    backgroundColor: "transparent",
    marginTop: "2%",
    marginBottom: "2%",
    borderRadius: 15,
  },
  margin: { marginBottom: "20%" },
};
