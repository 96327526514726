/* eslint-disable no-new-object */
/**
 * Valida los horarios seleccionados.
 * @param {Object} Horarios - Objeto que contiene los horarios seleccionados.
 * @throws {Error} Se lanzará un error si no se selecciona ningún horario.
 * @throws {Error} Se lanzará un error si algún horario seleccionado no es un objeto.
 */
export const validateHorarios = (Horarios = {}) => {
  const o = new Object(Horarios);

  if (o.hasOwnProperty("laboratorio")) {
    if (typeof Horarios["laboratorio"] != "object") {
      throw new Error("Selecciona un horario de laboratorio");
    }
  }
  if (o.hasOwnProperty("radiologia")) {
    if (typeof Horarios["radiologia"] != "object") {
      throw new Error("Selecciona un horario de radiología");
    }
  }
  if (o.hasOwnProperty("operador")) {
    if (typeof Horarios["operador"] != "object") {
      throw new Error("Selecciona un horario de servicio");
    }
  }
  if (!Horarios?.radiologia && !Horarios?.laboratorio && !Horarios?.operador) {
    throw new Error("Selecciona uno o más horarios.");
  }
};

/**
 * Valida la dirección proporcionada.
 * @param {Object} Direccion - Objeto que contiene la dirección.
 * @throws {Error} Se lanzará un error si no se proporciona un código postal.
 * @throws {Error} Se lanzará un error si no se proporciona una ciudad.
 * @throws {Error} Se lanzará un error si no se proporciona una calle.
 * @throws {Error} Se lanzará un error si el código postal no tiene 5 dígitos.
 * @throws {Error} Se lanzará un error si no se proporciona un número exterior.
 * @throws {Error} Se lanzará un error si no se proporciona una referencia.
 */
export const validateDireccion = (Direccion = {}) => {
  if (!Direccion.cp) {
    throw new Error("Código postal no válido");
  }
  if (!Direccion?.ciudad) {
    throw new Error("Selecciona una colonia");
  }
  if (!Direccion?.calle) {
    throw new Error("Especifica una calle.");
  }
  if (Direccion?.cp?.length !== 5) {
    throw new Error("Verifica el código postal");
  }
  if (!Direccion?.numeroExt) {
    throw new Error("Selecciona un número exterior");
  }
  if (!Direccion?.referencia) {
    throw new Error("Inserte una referencia");
  }
};

/**
 * Valida los datos del usuario.
 * @param {Object} usuario - Objeto que contiene los datos del usuario.
 * @throws {Error} Se lanzará un error si no se proporciona un nombre de usuario.
 * @throws {Error} Se lanzará un error si no se proporciona un número de teléfono.
 * @throws {Error} Se lanzará un error si el número de teléfono no tiene 10 dígitos.
 */
export const validateUsuario = (usuario) => {
  const regex =
    // eslint-disable-next-line
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!usuario?.nombre) {
    throw new Error(
      "El nombre del cliente es importante, ¡No olvides agregarlo!."
    );
  }
  if (!usuario?.telefono) {
    throw new Error("El teléfono es obligatorio");
  }

  if (usuario?.telefono?.length !== 10) {
    throw new Error("El número de telefono debe contener 10 dígitos");
  }
  if (!usuario?.correo) {
    throw new Error("Recuerda ingresar el correo electrónico");
  }
  if (!usuario?.correo.match(regex)) {
    throw new Error("El de correo electrónico no es válido");
  }
};

/**
 * Valida los artículos de una orden.
 * @param {Array} Orden - Array que contiene los artículos de la orden.
 * @throws {Error} Se lanzará un error si no se agregan artículos a la orden.
 */
export const validateOrder = (Orden = []) => {
  if (!Orden.length) {
    throw new Error(" ¡Olvidas agregar articulos!, selecciona alguno");
  }
};

/**
 * Valida el método de pago seleccionado.
 * @param {string} value - Método de pago seleccionado.
 * @throws {Error} Se lanzará un error si no se especifica un método de pago.
 */
export const validatePayment = (value) => {
  if (!value) {
    throw new Error("Especifica un método de pago");
  }
};
