/* eslint-disable import/no-anonymous-default-export */
/**
 * Objeto que proporciona un método para obtener imágenes de artículos.
 * @namespace
 */
export default {
  /**
   * Obtiene un array de objetos que contienen el nombre y la URL de la imagen de diferentes artículos.
   * @returns {Object[]} Un array de objetos que representan los artículos con sus nombres e imágenes.
   */
  getImagesArticles: () => {
    return [
      {
        name: "Electrocardiograma",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Electrocardiograma.png",
      },
      {
        name: "Prueba rápida",
        img: "https://movicaremx.com/IMG//app_mobile/FormatPNG/Prueba_Rapida_COVID.png",
      },
      {
        name: "Prueba PCR",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/PCR.png",
      },
      {
        name: "Tubo azul",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Tubo_Azul.png",
      },
      {
        name: "Tubo rojo",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Tubo_Dorado_Rojo.png",
      },
      {
        name: "Tubo gris",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Tubo_Gris.png",
      },
      {
        name: "Tubo morado",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Tubo_Morado.png",
      },
      {
        name: "Tubo verde",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Tubo_Verde.png",
      },
      {
        name: "Radiografía de cabeza",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Cabeza.png",
      },
      {
        name: "Radiografía de cadera",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Cadera.png",
      },
      {
        name: "Radiografía de codo",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Codo.png",
      },
      {
        name: "Radiografía de falange",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Falanges.png",
      },
      {
        name: "Radiografía de mano",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Mano.png",
      },
      {
        name: "Radiografía de pie",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Pie.png",
      },
      {
        name: "Radiografía de rodilla",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Rodilla.png",
      },
      {
        name: "Radiografía de torso",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Torso.png",
      },
      {
        name: "Vaso recolector",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Vaso_recolector.png",
      },
      {
        name: "Ambulancia",
        img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
      },
    ];
  },
};
