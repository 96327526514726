import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const CustomMenu = ({ menuItems }) => {
  const navigate = useNavigate();

  const [anchorNav, setAnchorNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNav(null);
  };

  const open = Boolean(anchorNav);

  return (
    <>
      <IconButton
        aria-expanded={open ? "long-menu" : undefined}
        aria-controls={open ? true : undefined}
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon style={design.icon} />
      </IconButton>
      <Menu
        anchorEl={anchorNav}
        open={open}
        onClose={handleCloseNavMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
            borderRadius: 15,
          },
        }}
      >
        <MenuList dense>
          {menuItems.map((menuItem, index) => (
            <>
              <h1 style={design.titleMenu}>{menuItem.title}</h1>
              {menuItem.links.map((link, linkIndex) => (
                <>
                  <MenuItem
                    title={link.label}
                    key={index}
                    onClick={() => {
                      navigate(link.to);
                      setAnchorNav(!anchorNav);
                    }}
                    style={design.txt}
                  >
                    <Typography variant="body1">
                      &#8227; {link.label}
                    </Typography>
                  </MenuItem>
                </>
              ))}
            </>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};
export default CustomMenu;
const design = {
  titleMenu: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    fontSize: 17,
    marginLeft: "5%",
  },
  icon: {
    color: "rgba(0, 82, 140, 1)",
    fontSize: 27,
  },
  txt: { paddingLeft: 30 },
};
