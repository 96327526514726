/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, CircularProgress } from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebasev2";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { validateToken } from "../Apis/validateToken";
import CardInfoPed from "../Components/CardInfoPed";
import Podiums from "../Components/Podiums";
import WaitServices from "./Wait";
import { useDimensions } from "../hooks/useDimensions";
import { castBranche } from "../Utils/castBranche";
import { castStore } from "../Utils/castStore";

/**
 * Componente que muestra el dashboard del usuario.
 * @returns {JSX.Element} Elemento JSX que representa el componente Dashboard.
 */
const Dashboard = () => {
  // Estado para almacenar los pedidos
  const [pedidos, setPedididos] = useState([]);
  // Estado para verificar si está cargando
  const [loading, setLoading] = useState(true);
  // Estado para almacenar la franquicia
  const [store, setStore] = useState(true);
  // Estado para almacenar la sucursal
  const [branch, setBranch] = useState(true);
  // Variable para almacenar la franquicia
  let franquicia = null;
  // Dimensiones de la ventana
  const dimensionWidth = useDimensions("width");
  const dimensionHeight = useDimensions("height");
  // Contexto de autenticación
  const { token, user } = useAuth();

  // Función para obtener los pedidos al cargar el componente o al cambiar el token o usuario
  useEffect(() => {
    getPedidos();
  }, [user, token]);

  // Función para obtener los pedidos desde Firebase
  const getPedidos = async () => {
    // Si no hay token o usuario, no hace nada
    if (!token || !user.uid) return null;

    // Validar el token
    const response = await validateToken(token, user.uid);
    // Si la respuesta no es un objeto, muestra el error
    if (typeof response !== "object") {
      console.log(response);
      return;
    }

    // Almacenar la franquicia y la sucursal
    franquicia = response.store;
    setBranch(response.branch);
    setStore(response.store);

    setLoading(true);
    // Consultas para cada tipo de pedido
    const laboratorioQuery = queryFunction("laboratorio");
    const radiologiaQuery = queryFunction("radiologia");
    const operadorQuery = queryFunction("operador");

    // Construir los datos de los pedidos
    const dataLab = await constructData(laboratorioQuery);
    const dataRad = await constructData(radiologiaQuery);
    const dataOpe = await constructData(operadorQuery);
    let fixPedidos = [...dataLab, ...dataRad, ...dataOpe];

    // Filtrar pedidos que no estén completos
    const pedidos = fixPedidos.filter(
      (e) =>
        e.status.radiologo < 110 ||
        e.status.administrador < 110 ||
        e.status.laboratorista < 110 ||
        e.status.operador < 110
    );

    // Filtrar pedidos duplicados
    const orders = pedidos.filter((obj, index) => {
      return index === pedidos.findIndex((o) => obj.np === o.np);
    });

    // Ordenar pedidos por número de pedido
    setPedididos(orders.sort((a, b) => a.np - b.np));
  };

  // Función para construir una consulta de Firebase
  const queryFunction = (data) => {
    // Verificar si data es undefined
    if (data === undefined) {
      return null;
    }
    const ref = collection(db, "PedidosAux");
    const initialDay = new Date();
    const fechaSinMinutos = new Date(
      initialDay.getFullYear(),
      initialDay.getMonth(),
      initialDay.getDate(),
      0,
      0,
      0
    );
    const endDate = new Date(
      initialDay.getFullYear(),
      initialDay.getMonth(),
      initialDay.getDate(),
      23,
      59,
      59
    );

    return query(
      ref,
      where("services_Dates." + data, ">=", fechaSinMinutos),
      where("services_Dates." + data, "<=", endDate),
      where("franquicia", "==", franquicia)
    );
  };

  // Función para construir datos a partir de la consulta de Firebase
  const constructData = async (query) => {
    const array = await getDocs(query);
    setLoading(false);
    const order = array.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      };
    });
    return Promise.all(order);
  };

  // Renderizado condicional
  if (loading && !pedidos.length) {
    return (
      <>
        <Stack direction="column" justifyContent="center" alignItems="center" mt={25}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        {/* Información del usuario */}
        <Grid item xs={12} md={5} xl={5}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={0}>
            <h1 style={design.title}>Bienvenido &nbsp;</h1>
            <Box
              width={dimensionWidth < 1000 ? "40%" : "15%"}
              height={dimensionWidth < 1000 ? "20%" : "15%"}
              component="img"
              src="https://movicaremx.com/IMG/MoviLabs/movilabs_300.png"
              alt={"Franquicia_name"}
            />
          </Stack>
          <h2 style={design.subtitle}>
            {user?.displayName} <br />{" "}
            {`${castStore()[store]} ${castBranche()[branch]}`}
          </h2>
        </Grid>
        {/* Podios */}
        <Grid item xs={12} md={7} xl={7}>
          <Podiums store={store} />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
        <Grid
          container
          spacing={2}
          style={{
            height: dimensionHeight - 420,
            ...design.cards,
          }}
        >
          {/* Renderizar componentes dependiendo de los pedidos */}
          {!pedidos.length ? (
            <WaitServices />
          ) : (
            <>
              {pedidos.map((doc, idx) => (
                <Grid
                  item
                  spacing={2}
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={idx}
                >
                  <CardInfoPed loading={false} data={doc} index={idx} />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Stack>
    </>
  );
};

export default Dashboard;

// Estilos del componente
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    letterSpacing: "0em",
    textAlign: "center",
    marginTop: "-5%",
    marginRight: "5%",
  },
  cards: {
    marginLeft: "10%",
    marginRight: "10%",
    maxWidth: "85%",
    overflow: "auto",
    marginTop: 10,
  },
};
