import React, { useState, useEffect } from "react";
import { Card, CardContent, CardActionArea, Skeleton } from "@mui/material";
import StatusXStaff from "./Ordenes/StatusXStaff";
import Dates from "./Ordenes/Dates";
import { useNavigate } from "react-router-dom";
import Origin from "./Ordenes/Origin";

const CardInfoPed = (props) => {
  const { loading = false, data, index } = props;
  const navigate = useNavigate();
  //::::::::::::::Tamaño Imagenes::::::::::::::::::::.
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [widthIMG, setWidthIMG] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWidthIMG(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Card
        key={index}
        sx={{
          ...design.card,
          boxShadow: loading
            ? "0px 1px 25px 0px #BDE0FE inset"
            : "0px 4px 10px 0px #0930464F",
          border: loading ? null : "2px solid #00518C",
        }}
        onClick={() => (data.id ? navigate("/order-details/" + data.id) : null)}
      >
        <CardActionArea>
          <CardContent>
            {loading ? (
              <>
                <div style={{ marginLeft: "20%" }}>
                  <Skeleton animation="wave" height={30} width="80%" />
                  <Skeleton animation="wave" height={25} width="80%" />
                  <Skeleton animation="wave" height={20} width="80%" />
                  <Skeleton animation="wave" height={15} width="80%" />
                  <Skeleton animation="wave" height={10} width="80%" />
                  <Skeleton animation="wave" height={10} width="80%" />
                  <Skeleton animation="wave" height={10} width="80%" />
                  <Skeleton animation="wave" height={10} width="80%" />
                </div>
              </>
            ) : (
              <>
                <h1 style={design.title}>PED-{data?.np}</h1>
                <Origin
                  franquicia={data?.franquicia}
                  sucursal={data?.sucursal}
                  origen={data?.origen}
                />
                <h2 style={design.subtitle}>
                  {data?.DatosCliente?.nombre} <br />
                  {data?.DatosCliente?.telefono}{" "}
                </h2>
                <h3 style={design.subtitle}>
                  {data?.Direccion?.ciudad}, {data?.Direccion?.delegacion},{" "}
                  {data?.Direccion?.estado} <br />
                  {data?.total.toLocaleString("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    currencyDisplay: "symbol",
                  })}{" "}
                  en {data?.metodoDePago}
                </h3>
                <h4 style={design.subtitle}>
                  <Dates
                    widthIMG={widthIMG.width < 1000 ? "10%" : "5%"}
                    fecha={data?.services_Dates}
                    fechaServicio={data?.fechaServicio}
                  />
                  <p style={{ textAlign: "center" }}>Estatus actual</p>
                  {!data?.status && (
                    <>
                      <StatusXStaff estatus={data.estatus} width={30} />
                    </>
                  )}
                  {data?.status && (
                    <>
                      <StatusXStaff status={data.status} width={25} />
                    </>
                  )}
                </h4>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default CardInfoPed;

const design = {
  card: {
    maxWidth: 345,
    height: 300,
    overflow: "auto",
    backgroundColor: "#D9EDFF59",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 5px 0px 5px",
    /* arriba | derecha | abajo | izquierda */
  },
  skeletons: {
    color: "#4496D20F",
    margin: "5%",
  },
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    fontSize: "1.5em",
    lineHeight: "100%",
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 400,
    fontSize: "1em",
    textAlign: "left",
  },
};
