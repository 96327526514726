/* eslint-disable import/no-anonymous-default-export */
export default (date = null) => {
  if (!date) return null;

  let fecha;

  try {
    fecha = date.toDate();
  } catch (error) {
    fecha = date;
  }
  try {
    const dia = fecha.getDate();
    const month = fecha.getMonth() + 1;
    const year = fecha.getFullYear();
    const hours = fecha.getHours();
    const minutes = fecha.getMinutes();
    const maxHours = fecha.getHours() + 1;

    return `${dia < 10 ? "0" + dia : dia}/${
      month < 10 ? "0" + month : month
    }/${year} entre ${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } - ${maxHours + ":00"} `;
  } catch (error) {
    return "_n";
  }
};
