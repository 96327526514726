/**
 * Componente estilizado para un botón verde.
 * @module GreenButton
 * @param {Object} props - Propiedades del componente.
 * @param {string} [props.width="60%"] - Ancho del botón.
 * @returns {JSX.Element} Elemento JSX que representa el componente GreenButton.
 */
import { Button, styled } from "@mui/material";

export const GreenButton = styled(Button)(({ width, height }) => ({
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "15px 25px",
  width: width || "60%",
  fontFamily: "Lexend",
  textTransform: "none",
  fontWeight: 500,
  height: height ? height : "66px",
  margin: "2%",
  fontSize: 19,
  border: " 5px solid #65B231",
  color: "#65B231",
  borderRadius: "32px",
  textOverflow: "clip",
  whiteSpace: "normal",
  overflow: "hidden",
  lineHeight: 1,
}));
