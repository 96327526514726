/* eslint-disable import/no-anonymous-default-export */
import { AUTH_TOKEN, LOGIN } from "./types";

/**
 * Reducer para gestionar el estado relacionado con la autenticación del usuario.
 * @function
 * @param {Object} state - Estado actual.
 * @param {Object} action - Objeto de acción que describe la operación a realizar.
 * @param {string} action.type - Tipo de acción que indica la operación.
 * @param {any} action.payload - Datos asociados con la acción.
 * @returns {Object} Nuevo estado después de aplicar la acción.
 * @example
 * // Uso típico en un componente funcional con useReducer
 * const [state, dispatch] = useReducer(authReducer, initialState);
 * dispatch({ type: LOGIN, payload: userData });
 */
export default (state, action) => {
  switch (action.type) {
    // Caso para manejar el inicio de sesión del usuario
    case LOGIN:
      return { ...state, user: action.payload };
    // Caso para manejar la actualización del token de autenticación
    case AUTH_TOKEN:
      return { ...state, token: action.payload };
    // Caso por defecto para manejar acciones desconocidas
    default:
      return state;
  }
};
