import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectRoutes from "./Views/ProtectRoutes";
import Menu from "./Components/Menu";
import AuthState from "./Context/Auth_v2/Auth.context";
import OrderState from "./Context/Ordenes_v2/Order.context";
import ComponentsStyle from "./Views/ComponentsStyle";
import Dashboard from "./Views/Dashboard";
import CreateOrder from "./Views/Ordenes/CreateOrder";
import OrderDetails from "./Views/Ordenes/OrderDetails";
import ViewOrders from "./Views/Ordenes/ViewOrders";
import Archive from "./Views/Ordenes/Archive";
import Users from "./Views/Users/Users";
import ViewCatalog from "./Views/Catalogo/ViewCatalog";
import ServiceAreas from "./Views/ServiceAreas";
import ImportCSV from "./Components/Catalogo/ImportCSV";
import Crear from "./Views/Catalogo/Crear";
import ReceiverPage from "./Views/SegundaPantalla/ReceiverPage";
import { useDimensions } from "./hooks/useDimensions";
import Login from "./Views/Login";
import Error404 from "./Views/404";
import Modify from "./Views/Ordenes/Modify";
import CreateQuote from "./Views/Cotizacion/CreateQuote";
import ViewQuote from "./Views/Cotizacion/ViewQuote";

const App = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const Phone = dimensionWidth < 1000;
  const rutaActual = window.location.pathname;

  return (
    <Router>
      <AuthState>
        <Menu />
        <div
          style={
            Phone
              ? null
              : {
                  height:
                    rutaActual === "/login"
                      ? null
                      : dimensionWidth < 1500
                      ? dimensionHeight - 80
                      : dimensionHeight - 90,
                  overflow: "auto",
                }
          }
        >
          <Routes>
            <Route path="/componentsStyle" element={<ComponentsStyle />} />
            <Route path="/ImportCSVProducts" element={<ImportCSV />} />
            <Route path="/login" element={<Login />} />
            <Route path="/404" element={<Error404 />} />

            <Route element={<ProtectRoutes />}>
              <Route path="/" element={<Dashboard />} />
              <Route
                path="/create-order"
                element={
                  <OrderState>
                    <CreateOrder />
                  </OrderState>
                }
              />
              <Route path="/order-details/id/Modify" element={<Modify />} />

              <Route path="/view-orders" element={<ViewOrders />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/add-study" element={<Crear />} />
              <Route path="/view-catalog" element={<ViewCatalog />} />
              <Route path="/create-quote" element={<CreateQuote />} />
              <Route path="/view-quotes" element={<ViewQuote />} />
              <Route path="/service-areas" element={<ServiceAreas />} />
              <Route path="/profile" element={<h1>Profile</h1>} />
              <Route path="/Users" element={<Users />} />
              <Route path="/order-details/:id" element={<OrderDetails />} />
              <Route
                path="/receiver-page"
                element={
                  <OrderState>
                    <ReceiverPage />
                  </OrderState>
                }
              />
            </Route>
          </Routes>
        </div>
      </AuthState>
    </Router>
  );
};

export default App;
