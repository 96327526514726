import {
    collection,
    query,
    where,
  } from "firebase/firestore";
  import { db } from "../../../firebase/firebasev2";

  /**
 * Retorna una consulta Firestore para obtener pedidos específicos.
 * @param {string} type - El tipo de estado del pedido.
 * @param {string} franquicia - El nombre de la franquicia.
 * @param {string} sucursal - El nombre de la sucursal.
 * @returns {object} Una consulta Firestore para obtener pedidos específicos.
 */
const getQuery = (type,franquicia, sucursal) => {
  const ref = collection(db, "PedidosAux");
  return query(
    ref,
    where("status." + type, ">=", 10),
    where("franquicia", "==", franquicia),
    where("sucursal", "==", sucursal)
  );
};

export default getQuery;
