import React, { useState } from "react";
import Study from "../../Components/Catalogo/Study";
import { Stack, RadioGroup, Radio, FormControlLabel } from "@mui/material";

const Crear = () => {
  const classes = useStyles();
  const [value, setValue] = useState("estudio");

  const handleChange = (event) => {
    setValue(event.target.value);
    console.log(value);
  };

  return (
    <Stack flex={1} direction="column" className={classes.root}>
      <h2 style={classes.titulo}>Crear artículo</h2>
      <RadioGroup
        value={value}
        onChange={handleChange}
        className={classes.radios}
      >
        <FormControlLabel
          value="estudio"
          control={<Radio style={{ color: "#65b32e", marginLeft: "49%" }} />}
          label="Estudio"
        />
      </RadioGroup>
      {/* ********** Crear estudio ******** */}
      {value === "estudio" && <Study />}
    </Stack>
  );
};
export default Crear;
const useStyles = () => ({
  root: {
    flex: 1,
    margin: 20,
    marginTop: "2%",
    justifyContent: "center",
    alignSelf: "center",
  },
  titulo: {
    color: "#00518c",
    textAlign: "center",
    fontSize: 25,
  },
  radios: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
  },
});
