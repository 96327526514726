/* eslint-disable array-callback-return */
import firebase from "../../../firebase/firebase";

export const getDepartaments = async (store, branch) => {
  // console.log(store);
  try {
    const response = await firebase.db
      .collection("Franquicias")
      .doc(store)
      .collection("Sucursales")
      .doc(branch)
      .collection("Products")
      .orderBy("departament", "asc")
      .get();

    const departamentSet = new Set();

    response.docs.map((doc) => {
      const departamentosData = doc.data().departament;
      departamentSet.add(departamentosData);
    });
    const departamentos = Array.from(departamentSet);
    // console.log(departamentos)

    return departamentos;
  } catch (error) {
    console.log(error);
  }
};

export const getSubDepartaments = async (store, branch) => {
  try {
    const response = await firebase.db
      .collection("Franquicias")
      .doc(store)
      .collection("Sucursales")
      .doc(branch)
      .collection("Products")
      .orderBy("subdepartament","asc")
      .get();
    const subdepartamentosSet = new Set();

    response.docs.map((doc) => {
      const subdepartamentData = doc.data().subdepartament;
      subdepartamentosSet.add(subdepartamentData);
    });
    const subdepartamentos = Array.from(subdepartamentosSet);
    // console.log(subdepartamentos)
    return subdepartamentos;
  } catch (error) {
    console.log(error);
  }
};
