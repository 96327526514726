/**
 * Componente de campo de texto personalizado con estilos CSS.
 * @module CssTextField
 * @param {object} props - Propiedades del componente.
 * @returns {JSX.Element} Elemento del campo de texto personalizado.
 */
import { TextField, styled } from "@mui/material";

/**
 * Componente de campo de texto personalizado con estilos CSS.
 * @param {object} props - Propiedades del componente.
 * @returns {JSX.Element} Elemento del campo de texto personalizado.
 */
export const CssTextField = styled(TextField)({
  width: "97%",
  margin: 10,
  backgroundColor: "#FCFCFC",
  "& label.Mui-focused": {
    color: "green",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
    borderWidth: 3,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C4C4C4",
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderWidth: 3,
    },
    "&:hover fieldset": {
      borderColor: "yellow",
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderWidth: 3,
    },
    "&.Mui-focused fieldset": {
      borderColor: "green",
      borderBottomLeftRadius: 10,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      borderWidth: 3,
    },
  },
});
