import React from "react";
import { alpha, styled } from "@mui/material/styles";
import {
  DataGrid,
  esES,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridPagination,
} from "@mui/x-data-grid";
import firebase from "../firebase";
import { USERS_DISPONIBILITY } from "../Controllers/usersDisponibility";

// Función para generar estilos dinámicamente
const generateStyles = (
  evenBackgroundColor,
  oddBackgroundColor,
  oddOpacity
) => ({
  "& .even": {
    backgroundColor: evenBackgroundColor,
    "&:hover": {
      backgroundColor: alpha(evenBackgroundColor, oddOpacity),
    },
    "&.Mui-selected": {
      backgroundColor: alpha(evenBackgroundColor, oddOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(evenBackgroundColor, oddOpacity + 0.1),
      },
    },
  },
  "& .odd": {
    backgroundColor: oddBackgroundColor,
    "&:hover": {
      backgroundColor: alpha(oddBackgroundColor, oddOpacity),
    },
    "&.Mui-selected": {
      backgroundColor: alpha(oddBackgroundColor, oddOpacity),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(oddBackgroundColor, oddOpacity + 0.1),
      },
    },
  },
});

// Componente DataGrid personalizado con estilos rayados
const StripedDataGrid = styled(DataGrid)(
  ({ evenBackgroundColor, oddBackgroundColor, oddOpacity }) =>
    generateStyles(evenBackgroundColor, oddBackgroundColor, oddOpacity)
);

// Componente de paginación personalizado
const CustomPagination = (props) => {
  const { pagination, ...other } = props;
  return <GridPagination style={design.filtros} {...other} />;
};

// Componente principal de la tabla rayada
const StripedGrid = ({
  loading = false,
  columns,
  rows,
  onRowClick,
  oddBackgroundColor,
  evenBackgroundColor,
  setPageSize,
  pageSize,
  w = "75%",
  filter,
}) => {
  /**
   * Current user ID from Firebase authentication.
   * @type {string|null}
   */
  const uid = firebase.auth?.currentUser?.uid;

  // Componente de botones para la barra de herramientas
  const botones = (userDisponibility) => (
    <>
      <GridToolbarColumnsButton style={design.filtros} />
      <GridToolbarFilterButton style={design.filtros} />
      {USERS_DISPONIBILITY.find((user) => user.uid === uid) && (
        <>
          <GridToolbarExport style={design.filtros} />
        </>
      )}
    </>
  );
  return (
    <div style={{ ...design.root, width: w }}>
      <StripedDataGrid
        density="comfortable"
        autoHeight
        loading={loading}
        columns={columns}
        rows={rows}
        onRowClick={onRowClick}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 30, 50]}
        localeText={esES.props.MuiDataGrid.localeText}
        getRowId={(params) => params.id} // Utiliza la propiedad id como identificador de fila
        getRowClassName={(params) =>
          // console.log(params.row)
          params?.row?.available
            ? params.row.available === true
              ? "even"
              : "odd"
            : params?.id % 2 === 0
            ? "even"
            : "odd"
        }
        getRowHeight={({ densityFactor }) => {
          return 100 * densityFactor; // Altura para filas impares
        }}
        experimentalFeatures={{
          lazyLoading: true,
        }}
        components={{
          Toolbar: filter ? botones : null,
          Pagination: CustomPagination,
        }}
        oddBackgroundColor={oddBackgroundColor}
        evenBackgroundColor={evenBackgroundColor}
      />
    </div>
  );
};

// Estilos y diseño
const design = {
  root: {
    marginLeft: "3%",
    border: "2px solid #00528C",
    borderRadius: 5,
    boxShadow: "5px 5px 15px #b4b4b4",
  },
  filtros: {
    color: "#00518c",
    marginLeft: "1%",
    padding: "1% 2%",
    fontSize: 20,
    fontWeight: 500,
    fontFamily: "Lexend",
    textTransform: "none",
  },
};

export default StripedGrid;
