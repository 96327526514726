export const getHoursOrders =  (date = null) => {
    if (!date) return null;
  
    let fecha;
  
    try {
      fecha = date.toDate();
    } catch (error) {
      fecha = date;
    }
    try {
      const hours = fecha.getHours();
      const minutes = fecha.getMinutes();
   
  
      return `${hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }`;
    } catch (error) {
      return "_n";
    }
  };