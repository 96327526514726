import React from "react";
import { Grid } from "@mui/material";
import { useDimensions } from "../../hooks/useDimensions";
import CustomData from "../../Components/Cotizaciones/CustomData";
import CustomStudies from "../../Components/Cotizaciones/CustomStudies";
import TickeQuote from "../../Components/Cotizaciones/TicketQuote";
import CustomAddress from "../../Components/Cotizaciones/CustomAddress";

const CreateQuote = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const widthTablet = dimensionWidth > 768 && dimensionWidth < 1023;
  const widthTelefono = dimensionWidth > 320 && dimensionWidth < 767;
  const Ticket = widthTablet > widthTelefono;

  const style = {
    height: `${dimensionHeight}` - 155,
    overflow: "auto",
    paddingLeft: 15,
  };

  return (
    <>
      <Grid container backgroundColor="#D9EDFF" className="C4">
        <Grid xs={12} md={6} xl={7} lg={7} style={design.ColorFondo}>
          <p style={design.title}>Crea una cotización</p>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={style}>
            <CustomData />
            <CustomAddress />
            <CustomStudies />
          </Grid>
        </Grid>
        <Grid xs={Ticket ? 5 : 12} sm={12} md={6} lg={5} xl={5}>
          <TickeQuote />
        </Grid>
      </Grid>
    </>
  );
};
export default CreateQuote;
const design = {
  title: {
    textAlign: "center",
    fontWeight: "500",
    color: "#00518c",
    fontSize: 27,
    marginBottom: 2,
  },
  textEdit: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  textCreate: {
    fontSize: 17,
    textAlign: "center",
    marginRight: "2%",
    marginTop: "-0.5%",
    fontWeight: "600",
    color: "#4496D2",
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  Edit: {
    fontSize: 17,
    color: "#4496D2",
    marginTop: 5,
  },
  ColorFondo: {
    padding: "0px 5px",
    backgroundColor: "#FFF",
    borderTopRightRadius: 40,
  },
};
