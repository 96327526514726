import React, { useEffect, useState } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  CircularProgress,
  Grid,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
// import useOrderCounter from "../hooks/useOrderCounter";
import useOrderCounterMonthly from "../hooks/useOrderCounterMonthly";
import useOrderCounterResponsible from "../hooks/useOrderCounterResponsible";
import useOrderCounterMonthlyBefore from "../hooks/useOrderCounterMonthlyBefore";
import { castMonth } from "../Utils/castMonth";

/**
 * Componente Podiums.
 * Muestra una lista de pedidos agendados por sucursal al mes.
 * El componente muestra un podio con las sucursales que realizaron más pedidos.
 * @param {Object} store - Objeto que contiene la información de la tienda.
 * @returns {JSX.Element} Componente de React.
 */
const Podiums = (store) => {
  //   const pedidosPorSucursal = useOrderCounter(store.store);
  // Ordenar datos de pedidos por sucursal a partir del 13/04/24
  //   const sortedPedidosPorSucursal = pedidosPorSucursal
  //     ? Object.entries(pedidosPorSucursal)
  //         .sort(([, cantidadA], [, cantidadB]) => cantidadB - cantidadA)
  //         .map(([sucursalId, cantidad]) => ({ sucursalId, cantidad }))
  //     : [];

  const pedidosXStorelMonthly = useOrderCounterMonthly(store.store);
  const pedidosXStorelMonthlyAfter = useOrderCounterMonthlyBefore(store.store);
  const pedidosXStoreResponsible = useOrderCounterResponsible(store.store);
  // Ordenar datos de pedidos por sucursal mensualmente
  const sortedPedidosPorSucursalMonthly = pedidosXStorelMonthly
    ? Object.entries(pedidosXStorelMonthly)
        .sort(([, cantidadA], [, cantidadB]) => cantidadB - cantidadA)
        .map(([sucursalId, cantidad]) => ({ sucursalId, cantidad }))
    : [];
  // Ordenar datos de pedidos por sucursal mes anterior
  const sortedPedidosPorSucursalMonthlyAfter = pedidosXStorelMonthlyAfter
    ? Object.entries(pedidosXStorelMonthlyAfter)
        .sort(([, cantidadA], [, cantidadB]) => cantidadB - cantidadA)
        .map(([sucursalId, cantidad]) => ({ sucursalId, cantidad }))
    : [];

  // Colores para el podium
  const colors = ["#FFD700", "#C0C0C0", "#CD7F32"]; // Oro, Plata, Bronce

  const generateListItems = (pedidosXStoreResponsible, colors) => {
    // Crear un objeto para almacenar las sumas de cantidades por nombre y sucursal
    const sumasPorNombreYSucursal = {};

    // Iterar sobre el objeto de pedidosXStoreResponsible para sumar las cantidades por nombre y sucursal
    Object.entries(pedidosXStoreResponsible).forEach(
      ([sucursalResponsable, cantidad]) => {
        const [sucursal, responsable] = sucursalResponsable.split("-");
        if (!sumasPorNombreYSucursal[responsable]) {
          sumasPorNombreYSucursal[responsable] = {
            sucursales: [sucursal],
            cantidad: cantidad,
          };
        } else {
          sumasPorNombreYSucursal[responsable].sucursales.push(sucursal);
          sumasPorNombreYSucursal[responsable].cantidad += cantidad;
        }
      }
    );

    // Convertir el objeto de sumas a un array de arrays
    const sumasArray = Object.entries(sumasPorNombreYSucursal);

    // Ordenar el array de sumas por la cantidad de pedidos en orden descendente
    sumasArray.sort((a, b) => b[1].cantidad - a[1].cantidad);

    if (!sumasArray.length) {
      return null;
    }

    // Mapear los elementos ordenados
    return sumasArray.map(([responsable, { sucursales, cantidad }], index) => (
      <ListItem disablePadding key={responsable}>
        <ListItemButton>
          <ListItemIcon>
            <StarIcon
              style={{
                color:
                  index < 3
                    ? colors[index]
                    : `rgb(255, 218, 20 ${1 - index / 10})`,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${cantidad} pedidos por ${responsable}`}
            secondary={`Sucursales: ${sucursales.join(", ")}`}
          />
        </ListItemButton>
      </ListItem>
    ));
  };

  // Estado para controlar la visibilidad del CircularProgress
  const [showProgress, setShowProgress] = useState(true);

  // Efecto para ocultar el CircularProgress después de cierto tiempo
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProgress(false);
    }, 3000); // Ocultar después de 3 segundos
    return () => clearTimeout(timer);
  }, []);

  const date = new Date();
  const monthBefore = date.getMonth() - 1;
  const month = date.getMonth();

  return (
    <Stack direction="row">
      <List
        sx={design.listRoot}
        aria-label="Podium sucursales"
        dense={true}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Pedidos agendados durante {castMonth[monthBefore]}
          </ListSubheader>
        }
      >
        {showProgress ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {sortedPedidosPorSucursalMonthlyAfter.map(
              ({ sucursalId, cantidad }, index) => (
                <ListItem disablePadding key={sucursalId}>
                  <ListItemButton>
                    <ListItemIcon>
                      <StarIcon
                        style={{
                          color:
                            index < 3
                              ? colors[index]
                              : `rgb(255,218,20 ${1 - index / 10})`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${sucursalId}: ${cantidad} pedidos`}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </>
        )}
      </List>
      <List
        sx={design.listRoot}
        aria-label="Podium sucursales"
        dense={true}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Pedidos agendados durante {castMonth[month]}
          </ListSubheader>
        }
      >
        {showProgress ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {sortedPedidosPorSucursalMonthly.map(
              ({ sucursalId, cantidad }, index) => (
                <ListItem disablePadding key={sucursalId}>
                  <ListItemButton>
                    <ListItemIcon>
                      <StarIcon
                        style={{
                          color:
                            index < 3
                              ? colors[index]
                              : `rgb(255,218,20 ${1 - index / 10})`,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${sucursalId}: ${cantidad} pedidos`}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </>
        )}
      </List>
      <List
        sx={design.listRoot}
        aria-label="Podium responsables"
        dense={true}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Pedidos por atención al cliente {castMonth[month]}
          </ListSubheader>
        }
      >
        {showProgress ? (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <>{generateListItems(pedidosXStoreResponsible, colors)}</>
        )}
      </List>
    </Stack>
  );
};

export default Podiums;

const design = {
  listRoot: {
    width: "100%",
    maxWidth: 360,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
    bgcolor: "background.paper",
  },
};
