import React from "react";
import { Grid } from "@mui/material";
import Item from "../Item";

const TableArticle = ({ name, price, quantity, subtotal }) => {

  return (
    <>
      <Grid item xs={5} md={6} xl={6} style={design.BoxAlign}>
        <Item.Article
          label={name}
          unitprice={price}
          requirements={"doc.requisitos"}
        />
      </Grid>
      <Grid item xs={4} md={3} xl={3} style={design.row}>
        <Item.Quantity quantity={quantity} botomDisable={true} />
      </Grid>
      <Grid item xs={2} md={2} xl={2} style={design.BoxAlign}>
        <Item.Subtotal subtotal={subtotal} />
      </Grid>
    </>
  );
};
export default TableArticle;

const design = {
  titleTable: {
    textAlign: "center",
    fontWeight: "600",
    color: "#093046",
    fontSize: 15,
  },
  row: {
    borderLeft: "2px dashed #00518c",
    borderRight: "2px dashed #00518c",
  },
  BoxAlign: {
    alignContent: "baseline",
  },
  Scroll: {
    height: "10%",
    overflow: "auto",
  },
  TableTitle: {
    backgroundColor: "#D9EDFF",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  box: {
    borderBottom: "2px dashed #00518c",
    borderTop: "2px dashed #00518c",
    height: "33%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "5%",
  },
  sub: {
    fontWeight: "700",
    color: "#000",
    fontSize: 13,
    textAlign: "right",
  },
  totals: {
    fontWeight: "500",
    color: "#000",
    fontSize: 13,
    marginLeft: 20,
  },
};
// const Study = [
//   { name: "Biometria Hemarica", price: 100, quantity: 0, subtotal: 0 },
// ];
