import React from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const ResultsTable = ({ Estudios, motivo, observaciones }) => {
  return (
    <>
      <Grid item xs={12} md={12} xl={9} lg={9} container>
        <TableContainer style={design.container}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} style={design.TableLeft}>
                  Estudios
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Estudios?.map((doc, index) => (
                <TableRow key={index}>
                  <TableCell colSpan={2} style={design.row}>
                    <p style={design.txt}>{doc.nombre}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {motivo || observaciones ? (
          <TableContainer style={design.container}>
            <Table stickyHeader size="small">
              <TableBody>
                {motivo && (
                  <>
                    <TableRow>
                      <TableCell style={{ ...design.row, ...design.TableLeft }}>
                        Motivo
                      </TableCell>
                      <TableCell style={design.row}>
                        <p style={design.txt}>{motivo}</p>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                {observaciones && (
                  <>
                    <TableRow>
                      <TableCell style={{ ...design.row, ...design.TableLeft }}>
                        Observaciones
                      </TableCell>
                      <TableCell style={design.row}>
                        <p style={design.txt}>{observaciones}</p>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Grid>
    </>
  );
};

const design = {
  TableLeft: {
    backgroundColor: "rgba(171, 215, 255, 1)",
    fontFamily: "Lexend",
    textAlign: "center",
    fontWeight: "500",
  },
  row: {
    justifyContent: "center",
    borderBottom: "2px dashed rgba(171, 215, 255, 1)",
    fontFamily: "Lexend",
  },
  txt: {
    color: "rgba(9, 48, 70, 1)",
    fontSize: 14,
    textAlign: "center",
  },
  container: {
    border: "2px solid rgba(171, 215, 255, 1)",
    borderRight: "2px solid rgba(171, 215, 255, 1)",
    borderRadius: 20,
    maxHeight: 350,
    margin: 10,
  },
};

export default ResultsTable;
