import React from "react";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { Navigate, Outlet } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
const ProtectRoutes = () => {
  // Utiliza el contexto de autenticación para obtener el usuario y el estado de carga
  const { user, loading } = useAuth();

  // Obtiene el token del almacenamiento local
  const token = localStorage.getItem("token");

  // Si la carga está en curso, muestra un mensaje de carga
  if (loading)
    return (
      <>
        <Grid
          container
          mt="20%"
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          alignSelf="center"
        >
          <CircularProgress size="large" />
        </Grid>
      </>
    );

  // Si hay un usuario y un token, renderiza el contenido protegido
  // de lo contrario, redirige al usuario a la página de inicio de sesión
  return user && token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectRoutes;
